import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import PurchaseOrderReportListScreen from "../purchase_order_list_report_screen";

const purchase_order_report_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_po_report,
    component: PurchaseOrderReportListScreen,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default purchase_order_report_routes;
