import { FunctionComponent, useEffect, useState } from "react";
import {
  accountTxnType,
  LedgerEntryModel,
  voucherEntryType,
  voucherType,
} from "../../models/common_model";
import {
  defaultPurchaseValue,
  ledgerEntryDefaultValue,
  purchaseItemDefaultValue,
  PurchaseVoucherItem,
  PurchaseVoucherModel,
} from "../../models/purchase_model";
import ItemSearchField from "../../../inventory/items/item_search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import moment from "moment";
import { ItemModel } from "../../../inventory/models/item_model";
import {
  itemMovementDefaultValue,
  ItemMovementModel,
} from "../../../inventory/models/inventory_voucher";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import ItemRow from "./components/item_row";
import LedgerSearchField from "../../ledgers/ledger_search_field";
import {
  account_group,
  account_sub_group,
} from "../../models/ledger_group_model";
import Icon from "@mdi/react";
import {
  mdiAttachment,
  mdiChevronRight,
  mdiInformationOutline,
  mdiPlus,
  mdiRefresh,
} from "@mdi/js";
import {
  ledgerDefaultValue,
  LedgerModel,
  LedgerTaxDetail,
  tax_category,
} from "../../models/ledger_model";
import TaxEntryRow, { getItemTaxAmount } from "./components/tax_entry_row";
import GeneralEntryRow from "./components/general_entry_row";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../service/api_client";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { numberRegEx } from "../../../../utils/constant";
import { useLocation, useParams } from "react-router-dom";
import PartySearchField from "../../parties/party_search_field";
import ModalWidget from "../../../../context_providers/modal/modal_widget";
import DrawerWidget from "../../../../context_providers/modal/drawer_widget";
import {
  AttachmentModel,
  getFileType,
  PurchaseOrderModel,
} from "../../../purchase_order/model/purchase_order_model";
import { formToObject } from "../../../../utils/formDataToJson";
import axios from "axios";
import { file_dir } from "../../../../utils/enums";
import AttachmentSection from "../../../purchase_order/purchase_order_create/components/attachment";
import DoneWidget from "../../../../ui/new_components/common/done";
import { currencyFormat } from "../../../../utils/orders_utils";
import { currencies } from "../../models/party_model";
import { urls } from "../../../../utils/urls";
import { ProcurementProjectModel } from "../../../purchase_order/project/model/project_model";

interface PurchaseVoucherProps {
  location?: any;
}

const PurchaseVoucher: FunctionComponent<PurchaseVoucherProps> = (props) => {
  const { id } = useParams<{ id: string }>();
  const query = new URLSearchParams(props.location.search);
  const po_no = query.get("po_no");
  const [showOrderDetail, setShowOrderDetail] = useState(false);
  const { showModal } = useModal();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/purchase`;
  const po_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order`;
  const party_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const [taxCreateId, setTaxCreateId] = useState(
    (Math.random() * 100).toString()
  );
  const [showSuccess, setShowSucess] = useState(false);
  const [project, setProject] = useState<ProcurementProjectModel>();
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [addNewItemId, setAddNewItemId] = useState(Math.random() * 1000);
  const [voucherData, setVoucherData] =
    useState<PurchaseVoucherModel>(defaultPurchaseValue);

  const submit = async () => {
    if (!voucherData.purchase_ledger_entry?.ledger_id)
      return showToast({
        type: ToastType.error,
        text: "Purchase voucher required !",
      });
    if (!voucherData.party_ledger_entry.ledger_id)
      return showToast({
        type: ToastType.error,
        text: "party ledger required !",
      });
    if (voucherData.entry_type === "Invoice") {
      if (!voucherData.items || voucherData.items.length === 0) {
        return showToast({
          type: ToastType.error,
          text: "Items required for Invoice type entry !",
        });
      }
    }
    voucherData.party_ledger_entry.amount = voucherData.total;
    voucherData.purchase_ledger_entry.amount = voucherData.sub_total;

    if (!voucherData.party_detail || !voucherData.order_detail) {
    }

    setLoading(true);
    const formData = new FormData();
    formData.append("data", JSON.stringify(voucherData));
    const res = voucherData.id
      ? await fetchPut(url + "/" + voucherData.id, formData, {
          "Content-Type": "multipart/form-data",
        })
      : await fetchPost(url, formData, {
          "Content-Type": "multipart/form-data",
        });
    if (res.success) {
      // console.log({ data: res.data });

      showToast({ type: ToastType.success, text: res.message });
      if (!voucherData.id) {
        // setVoucherData(defaultPurchaseValue);
        setShowSucess(true);
      }
      setVoucherData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  useEffect(() => {
    setVoucherData(props.location.state || defaultPurchaseValue);
    if (id) {
      getVoucher(id);
    } else if (po_no) {
      getPoData(po_no);
    }
  }, []);
  const getVoucher = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id, voucherData);
    if (res.success) {
      setVoucherData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const [poData, setPoData] = useState<PurchaseOrderModel>();
  // const [poNo, setPoNo] = useState<string>("");

  // const getProjectData = async (id: string) => {
  //   setLoading(true);

  //   const res = await fetchGet(
  //     `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
  //   );
  //   if (res.success) {
  //     setProject({ ...res.data });
  //   } else showToast({ type: ToastType.error, text: res.error });
  //   setLoading(false);
  // };
  console.log(voucherData.project, "project name1");
  // const getPoData = async (id: string) => {
  //   setLoading(true);
  //   const res = await fetchGet(po_url + "/" + id);
  //   if (res.success) {
  //     await getProjectData(res.data.project_id);
  //     setVoucherData((prevData) => ({
  //       ...prevData,
  //       project_id: project?.id,
  //       project: project?.name,
  //     }));
  //     // console.log(project?.name,"project name");
  //     if (!res.data.vendor_currency) {
  //       res.data.vendor_currency = currencies.rupee;
  //       res.data.vendor_currency_to_inr = 1;
  //     }

  //     getLedgerByPartyId(res.data.party_id || res.data.party.id);
  //     setPoData(res.data);
  //   } else showToast({ type: ToastType.error, text: res.error });
  //   setLoading(false);
  // };

  const getProjectData = async (id: string) => {
    setLoading(true);
    try {
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
      );
      if (res.success) {
        setProject(res.data);
        console.log("Project data fetched:", res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "An error occurred while fetching project data.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (project) {
      setVoucherData((prevData) => ({
        ...prevData,
        project_id: project.id,
        project: project.name,
      }));
    }
  }, [project]); // This effect runs whenever `project` changes

  // Fetch PO data and project data
  const getPoData = async (id: string) => {
    setLoading(true);
    try {
      const res = await fetchGet(po_url + "/" + id);
      if (res.success) {
        await getProjectData(res.data.project_id);
        if (!res.data.vendor_currency) {
          res.data.vendor_currency = currencies.rupee;
          res.data.vendor_currency_to_inr = 1;
        }
        await getLedgerByPartyId(res.data.party_id || res.data.party.id);
        setPoData(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "An error occurred while fetching PO data.",
      });
    } finally {
      setLoading(false);
    }
  };

  const getLedgerByPartyId = async (party_id: string): Promise<void> => {
    const res = await fetchGet(party_url + "/" + party_id + "/ledger");
    let ledger: LedgerModel = {
      name: "",
      is_default: false,
      group: {
        main_group: "",
        name: "",
      },
      opening_balance: {
        date: new Date(),
        txn_type: "",
        amount: 0,
      },
    };
    if (res.success) {
      ledger = res.data;
    }
    setVoucherData((o) => ({
      ...o,
      party_ledger_entry: {
        ...o.party_ledger_entry,
        ledger,
        ledger_id: ledger.id,
      },
    }));
  };

  useEffect(() => {
    if (poData) {
      // const party = poData.party;
      const warehouse = poData.warehouse;
      const items = poData.items.map((f) => ({
        ...f,
        price: poData.vendor_currency_to_inr * f.price,
      }));

      let sub_total = items.reduce(
        (pv, val) => pv + val.price * val.bill_unit_no,
        0
      );

      const taxes = poData.taxes.map((f) => ({
        ...f,
        amount: f.amount * poData.vendor_currency_to_inr,
      }));
      const extras = poData.extras.map((f) => ({
        ...f,
        amount: f.amount * poData.vendor_currency_to_inr,
      }));
      const discounts = poData.discounts.map((f) => ({
        ...f,
        amount: f.amount * poData.vendor_currency_to_inr,
      }));

      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
      let discount = discounts.reduce((pv, val) => pv + val.amount, 0);
      let extra = extras.reduce((pv, val) => pv + val.amount, 0);

      //get ledger by party id
      const party_ledger_entry: LedgerEntryModel = {
        voucher_id: "",
        voucher_type: voucherType.purchase,
        date: new Date(),
        type: accountTxnType.creditor,
        amount: sub_total,
      };
      setVoucherData((o) => ({
        ...o,
        sub_total,
        party_ledger_entry: {
          ...o.party_ledger_entry,
          ...party_ledger_entry,
        },
        total: sub_total + tax + (extra || 0) - (discount || 0),
        order_detail: { ...o.order_detail!, po_no: poData.po_no },
        party_id: poData.party.id,
        party_detail: poData.party,
        taxes,
        discounts,
        extras,
        tax,
        discount,
        extra,
        items: items.map((v) => {
          const movement: ItemMovementModel = { ...itemMovementDefaultValue };
          movement.warehouse = warehouse;
          movement.warehouse_id = warehouse.id;
          movement.bill_unit_no = v.bill_unit_no;
          movement.unit_no = v.unit_no;
          movement.item = v.item;
          movement.item_id = v.item.id;
          movement.price = v.price;
          return {
            id: v.id,
            bill_unit_no: v.bill_unit_no,
            price: v.price,
            unit_no: v.unit_no,
            item: v.item,
            item_id: v.item.id,
            item_split: [movement],
          };
        }),
      }));
    }
  }, [poData]);

  const [showAttachment, setShowAttachment] = useState(false);
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const uploadFile = async ({
    file,
    category,
    removeSelectedFile,
    setShowUpload,
  }: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => {
    try {
      if (!file || !category) {
        showToast({
          type: ToastType.warning,
          text: "Please select file and category",
        });
        return;
      }
      setLoading(true);
      const { name, type } = file;
      const create_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/credit-note/${voucherData.id}/attachment`;
      const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir.pocuremnt_attachments,
      };
      const res = await fetchGet(get_signed_urls + toQueryString(query));
      if (res.success) {
        const { signed_url, url } = res.data;
        const aws_res = await axios.put(signed_url, file, {
          headers: {
            "Content-Type": type,
          },
        });
        if (aws_res.status === 200) {
          const attachment: AttachmentModel = {
            id: "",
            category,
            type: getFileType(type),
            url,
          };
          const create_res = await fetchPost(create_url, attachment);
          // console.log({ create_res });
          if (create_res.success) {
            showToast({
              type: ToastType.success,
              text: create_res.message,
            });
            setVoucherData((o) => ({
              ...o,
              attachments: [...(o.attachments || []), create_res.data],
            }));

            removeSelectedFile();
            setShowUpload(false);
            setAttachmentKey(Math.random() * 1000);
          }
        }
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };
  const deleteFile = async (att: AttachmentModel) => {
    try {
      if (!window.confirm("Are your sure to delete ?")) return;
      if (!att) {
        showToast({
          type: ToastType.warning,
          text: "Please select file",
        });
        return;
      }
      setLoading(true);
      const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/voucher/debit-note/${voucherData.id}/attachment/${att.id}`;

      const res = await fetchDelete(url);

      if (res.success) {
        showToast({
          type: ToastType.success,
          text: res.message,
        });
        setVoucherData((o) => ({
          ...o,
          attachments: [
            ...(o.attachments || []).filter((f) => f.id !== att.id),
          ],
        }));
        setAttachmentKey(Math.random() * 1000);
      }
      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };

  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };

  useEffect(() => {
    voucherData.sub_total = 0;
    voucherData?.items?.forEach((item) => {
      voucherData.sub_total += item.bill_unit_no * item.price;
    });

    const taxes = voucherData.taxes.map((t, i) => {
      // if (voucherData.entry_type === voucherEntryType.Invoice)
      const tax = getItemTaxAmount({
        entry_type: "Invoice",
        items: voucherData.items || [],
        ledgerTaxDetail: t.ledger!.tax_detail!,
        extras: voucherData.extras,
      });
      if (typeof tax == "number") {
        t.amount = tax;
      }

      return { ...t };
    });
    let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
    voucherData.tax = tax;
    voucherData.taxes = taxes;
    voucherData.total =
      voucherData.sub_total +
      (tax ?? 0) +
      (voucherData.extra ?? 0) -
      (voucherData.discount ?? 0);

    setVoucherData({ ...voucherData });
  }, [voucherData.items, voucherData.extras]);
  if (showSuccess)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-80 h-80 p-0">
          <DoneWidget />
        </div>
        <div className="flex flex-col gap-2 justify-start items-center">
          <h1 className="text-3xl font-semibold text-gray-400 ">
            Created successfully
          </h1>
          <span className="text-black italic font-semibold">
            # {voucherData.id}
          </span>
          <div className="flex gap-5">
            <button
              onClick={() => setShowSucess(false)}
              className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            >
              Continue
            </button>
            <button
              onClick={() => {
                setVoucherData(defaultPurchaseValue);
                setShowSucess(false);
              }}
              className="border rounded-3xl px-4 py-2 bg-green-400 text-white  "
            >
              Create new
            </button>
          </div>
        </div>
      </div>
    );
  return (
    <>
      {showAttachment && voucherData.id && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attchments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <AttachmentSection
                key={attachmentKey}
                attachments={voucherData.attachments}
                onSubmit={uploadFile}
                onDelete={deleteFile}
                onSelect={onSelectAttchment}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(false);
          }}
        ></DrawerWidget>
      )}
      {id && (
        <div className="fixed bottom-3 right-3">
          <button
            title="Attchments"
            // to={`${window.document.location.pathname}/comment`}
            onClick={() => setShowAttachment(true)}
            className="  rounded-full p-2  hover:scale-105 transform duration-150 bg-green-400 text-white   shadow cursor-pointer flex items-center justify-center"
          >
            <Icon
              path={mdiAttachment}
              size={1}
              className="hover:scale-105 transform -rotate-45"
            ></Icon>
          </button>
        </div>
      )}
      {showOrderDetail && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Order detail",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <>
                <div className="p-5 text-xs">
                  <div className="flex flex-col items-start  w-full">
                    <h2 className="text-sm font-semibold">Party</h2>
                    <PartySearchField
                      show_add={true}
                      value={voucherData.party_detail}
                      onSelect={(d) => {
                        setVoucherData((o) => ({
                          ...o,
                          party_detail: d,
                        }));
                      }}
                    />
                  </div>

                  <div className="grid grid-cols-2  my-2 gap-1">
                    <div className=" font-semibold text-gray-500">Party</div>
                    <div className="">: {voucherData.party_detail?.name}</div>
                    <div className=" font-semibold text-gray-500">Address</div>
                    <div className="">
                      : {voucherData.party_detail?.mail_detail.address.city},
                      {voucherData.party_detail?.mail_detail.address.state},
                      {voucherData.party_detail?.mail_detail.address.country}-
                      {voucherData.party_detail?.mail_detail.address.pincode},
                    </div>
                    <div className=" font-semibold text-gray-500">
                      Contact name
                    </div>
                    <div className="">
                      : {voucherData.party_detail?.mail_detail.contact_name}
                    </div>
                    <div className=" font-semibold text-gray-500">
                      Contact mobile
                    </div>
                    <div className="">
                      : {voucherData.party_detail?.mail_detail.contact_mobile}
                    </div>
                    <div className=" font-semibold text-gray-500">
                      Contact email
                    </div>
                    <div className="">
                      : {voucherData.party_detail?.mail_detail.contact_email}
                    </div>
                  </div>

                  <hr />
                  <h2 className="text-sm font-semibold">Order details</h2>
                  <div className="grid grid-cols-1  my-2 gap-1">
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Order ref
                      </label>{" "}
                      <input
                        type="text"
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.order_detail?.order_id}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            order_detail: {
                              ...o.order_detail!,
                              order_id: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Delivery terms
                      </label>{" "}
                      <textarea
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.order_detail?.delivery_terms}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            order_detail: {
                              ...o.order_detail!,
                              delivery_terms: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                    <div className="grid grid-cols-4 items-center">
                      <label
                        htmlFor=""
                        className=" font-semibold text-gray-500"
                      >
                        Payment terms
                      </label>{" "}
                      <textarea
                        className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                        value={voucherData.order_detail?.payment_terms}
                        onChange={(e) => {
                          setVoucherData((o) => ({
                            ...o,
                            order_detail: {
                              ...o.order_detail!,
                              payment_terms: e.target.value,
                            },
                          }));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ),
          }}
          onClose={function (): void {
            setShowOrderDetail(false);
          }}
        ></DrawerWidget>
      )}
      <div className=" mt-2 px-6">
        <div className="flex flex-wrap items-center justify-between mb-2">
          <div className="font-bold text-2xl text-gray-500 border-l-4 border-myPrimaryColor pl-2">
            Purchase voucher
          </div>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>{" "}
            <input
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={
                voucherData.date
                  ? moment(voucherData.date).format("YYYY-MM-DD")
                  : ""
              }
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setVoucherData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg p-3">
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-2 gap-x-12 items-center justify-start">
            {/* <div className="grid grid-cols-1 items-center">
              <label
                htmlFor=""
                className="text-sm font-semibold text-gray-500 "
              >
                Entry Mode
              </label>
              <select
                disabled={id !== undefined}
                name=""
                id=""
                className="col-span-3 focus:outline-none focus:ring-1 ring-blue-400 border border-gray-300 rounded px-2 py-1 cursor-pointer mt-1 text-sm font-semibold w-full"
                value={voucherData.entry_type}
                onChange={(e) =>
                  setVoucherData((o: any) => ({
                    ...o,
                    entry_type: e.target.value!,
                  }))
                }
              >
                {Object.values(voucherEntryType).map((el, i) => {
                  return <option value={el}>{el}</option>;
                })}
              </select>
            </div> */}
            {voucherData.entry_type === voucherEntryType.Invoice && (
              <>
                <div className="grid grid-cols-1 items-center">
                  <label
                    htmlFor="invoice_no"
                    className="text-sm font-semibold text-gray-500"
                  >
                    Invoice no.
                  </label>

                  <input
                    value={voucherData.invoice_no}
                    onChange={(e) => {
                      setVoucherData((o) => ({
                        ...o,
                        invoice_no: e.target.value,
                      }));
                    }}
                    type="text"
                    className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                  />
                </div>
                <div className="grid grid-cols-1 items-center">
                  <label
                    htmlFor=""
                    className="text-sm font-semibold text-gray-500"
                  >
                    Invoice date
                  </label>{" "}
                  <input
                    type="date"
                    className="col-span-3 focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                    value={
                      voucherData.invoice_date
                        ? moment(voucherData.invoice_date).format("YYYY-MM-DD")
                        : ""
                    }
                    onChange={(e) => {
                      const date = UTCToLocalDate(e.target.value)!;
                      setVoucherData((o) => ({
                        ...o,
                        invoice_date: date,
                      }));
                    }}
                  />
                </div>
              </>
            )}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const d = formToObject(new FormData(e.currentTarget));
                if (d["po_no"]) {
                  if (voucherData.items!.length > 0) {
                    const confirm = window.confirm(
                      "Are you sure to refresh po data?"
                    );
                    if (!confirm) {
                      return;
                    }
                  }
                  getPoData(d["po_no"]);
                }
              }}
              className="grid grid-cols-1 items-center"
            >
              <label
                htmlFor="invoice_no"
                className="text-sm font-semibold text-gray-500"
              >
                Po number
              </label>

              <div className="flex gap-1 col-span-3 items-center">
                <input
                  value={voucherData.order_detail?.po_no}
                  onChange={(e) => {
                    setVoucherData((o) => ({
                      ...o,
                      order_detail: {
                        ...o.order_detail!,
                        po_no: e.target.value,
                      },
                    }));
                  }}
                  required
                  placeholder="Po number"
                  name="po_no"
                  type="text"
                  className="focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full border"
                />
                <button
                  title="Fetch po details"
                  className="rounded-full p-1 border"
                >
                  <Icon
                    path={mdiRefresh}
                    size={1}
                    className="hover:scale-110 transform"
                  ></Icon>
                </button>
              </div>
            </form>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-x-12 mt-3 items-center justify-start">
            <div className="grid grid-cols-1 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Party ledger
              </label>
              <div className="col-span-3">
                <LedgerSearchField
                  key={Math.random() * 10000}
                  value={
                    voucherData.party_ledger_entry
                      ? voucherData.party_ledger_entry.ledger
                      : undefined
                  }
                  onSelect={(d) => {
                    const party =
                      d.group.name ===
                        account_sub_group.sundry_creditors.name ||
                      d.group.name === account_sub_group.sundry_creditors.name
                        ? d.party
                        : undefined;
                    setVoucherData((o) => ({
                      ...o,
                      party_ledger_entry: {
                        ...(o.party_ledger_entry || ledgerEntryDefaultValue),
                        ledger: d,
                        ledger_id: d.id,
                      },
                      party_detail: party,
                      credit_period_day: party ? party.credit_period_day : 0,
                    }));
                  }}
                />
              </div>
            </div>

            {voucherData.entry_type === voucherEntryType.Invoice && (
              <div className="grid grid-cols-1 items-center">
                <label
                  htmlFor=""
                  className="text-sm font-semibold text-gray-500"
                >
                  Purchase ledger
                </label>
                <div className="col-span-3">
                  <LedgerSearchField
                    get_default={true}
                    key={Math.random() * 10000}
                    value={
                      voucherData.purchase_ledger_entry
                        ? voucherData.purchase_ledger_entry.ledger
                        : undefined
                    }
                    onSelect={(d) =>
                      setVoucherData((o) => ({
                        ...o,

                        purchase_ledger_entry: {
                          ...(o.purchase_ledger_entry ||
                            ledgerEntryDefaultValue),
                          ledger: d,

                          ledger_id: d.id,
                        },
                      }))
                    }
                    group={account_group.purchase_ac.name}
                  />
                </div>
              </div>
            )}
            {voucherData.party_ledger_entry.ledger &&
              (voucherData.party_ledger_entry.ledger.group.name ===
                account_sub_group.sundry_creditors.name ||
                voucherData.party_ledger_entry.ledger.group.name ===
                  account_sub_group.sundry_creditors.name) && (
                <div className="grid grid-cols-1 items-center">
                  <label
                    htmlFor=""
                    className="text-sm font-semibold text-gray-500"
                  >
                    Credit period
                  </label>
                  <div className="col-span-3">
                    <input
                      value={voucherData.credit_period_day}
                      onChange={(e) => {
                        const val = e.target.value.trim();
                        if (!val || numberRegEx.test(val)) {
                          const value = Number(val || 0);
                          setVoucherData((ol) => ({
                            ...ol,
                            credit_period_day: value,
                          }));
                        }
                      }}
                      placeholder="In days"
                      type="text"
                      className="text-right  p-1 focus:outline-none border rounded  w-full"
                    />
                  </div>
                </div>
              )}
          </div>{" "}
          <div className="flex mt-2 justify-end">
            <div
              onClick={() => {
                setShowOrderDetail(true);
              }}
              className="flex gap-5 border rounded items-center px-2 py-1  text-sm cursor-pointer"
            >
              <p>Order details</p>
              <Icon path={mdiChevronRight} className="w-4 h-4" />
            </div>
          </div>
        </div>
        {voucherData.entry_type === voucherEntryType.Invoice ? (
          <div className="relative">
            <div className="mt-3 mb-3 border-b border-gray-300 text-sm font-bold">
              Item details
            </div>

            {/* Table grid */}
            <div className="flex flex-col gap-1 bg-white  overflow-auto h-72 rounded">
              <div className="grid grid-cols-8 bg-myPrimaryColor text-white font-semibold text-sm text-center rounded-t">
                <div className="py-1 px-2 col-span-4">Item</div>
                <div className="py-1 px-2 col-span-1">Qty</div>
                <div className="py-1 px-2 col-span-1 text-right">
                  Price ({"\u20b9"})
                </div>
                <div className="py-1 px-2 col-span-1 text-right">
                  Amount ({"\u20b9"})
                </div>
                <div className="py-1 px-2 col-span-1">Actions</div>
              </div>

              {voucherData.items?.map((item, i) => (
                <ItemRow
                  max_qty={
                    voucherData.order_detail?.po_no ? item.unit_no : undefined
                  }
                  price={
                    voucherData.order_detail?.po_no ? item.price : undefined
                  }
                  index={i + 1}
                  key={item.id}
                  onDelete={(d) => {
                    setVoucherData((o) => {
                      const items = [...(o.items || [])].filter(
                        (i) => i.id !== d.id
                      );
                      let sub_total = items.reduce(
                        (pv, val) => pv + val.price * val.bill_unit_no,
                        0
                      );
                      const taxes = o.taxes.map((t, i) => {
                        t.amount = getItemTaxAmount({
                          entry_type: voucherData.entry_type!,

                          items,
                          ledgerTaxDetail: t.ledger!.tax_detail!,
                          extras: voucherData.extras,
                        });

                        return t;
                      });
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total = sub_total + (tax ?? 0) - (o.discount ?? 0);
                      return { ...o, items, taxes, tax, sub_total, total };
                    });
                  }}
                  onSubmit={function (data: PurchaseVoucherItem): void {
                    setVoucherData((o) => {
                      const items: PurchaseVoucherItem[] = [
                        ...(o.items || []).map((it) => {
                          if (it.id === data.id) return data;
                          return it;
                        }),
                      ];

                      // let sub_total = items.reduce(
                      //   (pv, val) => pv + val.price * val.bill_unit_no,
                      //   0
                      // );
                      // const taxes = o.taxes.map((t, i) => {
                      //   t.amount = getItemTaxAmount({
                      //     entry_type: voucherData.entry_type!,

                      //     items,
                      //     ledgerTaxDetail: t.ledger!.tax_detail!,
                      //     extras: voucherData.extras,
                      //   });

                      //   return t;
                      // });
                      // let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      // const total = sub_total + (tax ?? 0) - (o.discount ?? 0);
                      // return { ...o, items, taxes, tax, sub_total, total };
                      return { ...o, items };
                    });
                  }}
                  item={item}
                  edit={false}
                  disabled={false}
                />
              ))}
              {!voucherData.order_detail?.po_no && (
                <ItemRow
                  index={(voucherData.items ?? []).length + 1}
                  key={addNewItemId}
                  onSubmit={function (data: PurchaseVoucherItem): void {
                    data.id = (Math.random() * 1000).toString();

                    setVoucherData((o) => {
                      const items = [...(o.items || []), data];
                      let sub_total = items.reduce(
                        (pv, val) => pv + val.price * val.bill_unit_no,
                        0
                      );
                      // const taxes = o.taxes.map((t, i) => {
                      //   t.amount = getItemTaxAmount({
                      //     entry_type: voucherData.entry_type!,

                      //     items,
                      //     ledgerTaxDetail: t.ledger!.tax_detail!,
                      //     extras: voucherData.extras,
                      //   });

                      //   return t;
                      // });
                      // let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      // const total = sub_total + (tax ?? 0) - (o.discount ?? 0);
                      // return {
                      //   ...o,
                      //   items,
                      //   taxes,
                      //   sub_total,
                      //   tax,
                      //   total,
                      // };

                      return { ...o, items };
                    });
                    setAddNewItemId(Math.random() * 1000);
                  }}
                  edit={true}
                  disabled={false}
                />
              )}
            </div>
            <div className="flex text-xs text-gray-400 items-center gap-2">
              <Icon path={mdiInformationOutline} size={0.8}>
                {" "}
              </Icon>{" "}
              Note : To Edit the qty or price reselect the item
            </div>
          </div>
        ) : (
          <div className="">
            <h3 className="text-sm font-bold">Purchase</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    value={
                      voucherData.purchase_ledger_entry
                        ? voucherData.purchase_ledger_entry.ledger
                        : undefined
                    }
                    onSelect={(d) =>
                      setVoucherData((o) => ({
                        ...o,
                        purchase_ledger_entry: {
                          ...(o.purchase_ledger_entry ||
                            ledgerEntryDefaultValue),
                          ledger: d,
                          ledger_id: d.id,
                        },
                      }))
                    }
                    group={account_group.purchase_ac.name}
                  />
                </div>

                <input
                  disabled={!voucherData.purchase_ledger_entry}
                  value={voucherData.purchase_ledger_entry?.amount}
                  onChange={(e) => {
                    const val = e.target.value.trim();
                    if (!val || numberRegEx.test(val)) {
                      const value = Number(val || 0);
                      setVoucherData((o) => ({
                        ...o,
                        purchase_ledger_entry: {
                          ...(o.purchase_ledger_entry ||
                            ledgerEntryDefaultValue),
                          amount: value,
                          type: accountTxnType.debitor,
                        },
                        sub_total: value,
                        total: value + (o.tax || 0) - (o.discount || 0),
                      }));
                    }
                  }}
                  placeholder=""
                  type="text"
                  className="text-right  p-1 focus:outline-none border rounded  w-full"
                />
              </div>
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-2">
          <div className="">
            <h3 className="text-sm font-bold">Taxes</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-6 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-4">Particular</div>
                <div className="text-center">Amount({"\u20b9"})</div>
                <div className=""></div>
              </div>
              {voucherData.taxes.map((tax) => (
                <TaxEntryRow
                  key={tax.amount + tax.id}
                  entry={tax}
                  items={[...(voucherData.items || [])]}
                  onDelete={(d) => {
                    setVoucherData((o) => {
                      const taxes = [...(o.taxes || [])].filter(
                        (i) => i.id !== d.id
                      );
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total =
                        o.sub_total + (tax ?? 0) - (o.discount ?? 0);
                      return {
                        ...o,
                        taxes,
                        tax,
                        total,
                      };
                    });
                  }}
                  onSubmit={function (data: LedgerEntryModel): void {
                    setVoucherData((o) => {
                      const taxes = [
                        ...(o.taxes || [[]]).map((t) => {
                          if (t.id === data.id) {
                            return { ...data };
                          }
                          return { ...t };
                        }),
                      ].map((t, i) => {
                        if (voucherData.entry_type === voucherEntryType.Invoice)
                          t.amount = getItemTaxAmount({
                            entry_type: voucherData.entry_type!,
                            items: voucherData.items || [],
                            ledgerTaxDetail: t.ledger!.tax_detail!,
                            extras: voucherData.extras,
                          });

                        return { ...t };
                      });
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total =
                        o.sub_total + (tax ?? 0) - (o.discount ?? 0);
                      return {
                        ...o,
                        taxes,
                        tax,
                        total,
                      };
                    });
                    // setAddNewTaxId(Math.random() * 1000);
                  }}
                  edit={false}
                  disabled={false}
                />
              ))}
              {!voucherData.order_detail?.po_no && (
                <TaxEntryRow
                  key={taxCreateId}
                  items={[...(voucherData.items || [])]}
                  onSubmit={function (data: LedgerEntryModel): void {
                    data.id = (Math.random() * 1000).toString();
                    setVoucherData((o) => {
                      const taxes = [...(o.taxes || [[]]), data].map((t, i) => {
                        if (voucherData.entry_type === voucherEntryType.Invoice)
                          t.amount = getItemTaxAmount({
                            entry_type: voucherData.entry_type!,
                            items: voucherData.items || [],
                            ledgerTaxDetail: t.ledger!.tax_detail!,
                            extras: voucherData.extras,
                          });

                        return { ...t };
                      });
                      let tax = taxes.reduce((pv, val) => pv + val.amount, 0);
                      const total =
                        o.sub_total + (tax ?? 0) - (o.discount ?? 0);
                      return {
                        ...o,
                        taxes,
                        tax,
                        total,
                      };
                    });
                    setTaxCreateId((Math.random() * 100).toString());
                  }}
                  edit={true}
                  disabled={false}
                />
              )}
            </div>
          </div>
          <div className="">
            <h3 className="text-sm font-bold">Discounts</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount ({"\u20b9"})</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    value={
                      voucherData.discounts[0]
                        ? voucherData.discounts[0].ledger
                        : undefined
                    }
                    onSelect={function (data: LedgerModel): void {
                      setVoucherData((ol) => {
                        return {
                          ...ol,

                          discounts: [
                            {
                              ...(ol.discounts[0] || ledgerEntryDefaultValue),
                              ledger: data,
                              ledger_id: data.id,
                              type: accountTxnType.creditor,
                            },
                          ],
                        };
                      });
                    }}
                  />
                </div>
                <div className="text-center">
                  <input
                    disabled={!voucherData.discounts[0]}
                    value={voucherData.discounts[0]?.amount}
                    onChange={(e) => {
                      const val = e.target.value.trim();
                      if (!val || numberRegEx.test(val)) {
                        const value = Number(val || 0);
                        setVoucherData((ol) => ({
                          ...ol,
                          discounts: [
                            {
                              ...(ol.discounts[0] || ledgerEntryDefaultValue),
                              amount: value,
                              type: accountTxnType.creditor,
                            },
                          ],
                          discount: value,
                          total: ol.sub_total + (ol.tax || 0) - value,
                        }));
                      }
                    }}
                    placeholder=""
                    type="text"
                    className="text-right  p-1 focus:outline-none border rounded  w-full"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <h3 className="text-sm font-bold">Extra charges</h3>
            <div className="bg-white rounded flex flex-col gap-1">
              <div className="grid grid-cols-3 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
                <div className="col-span-2">Particular</div>
                <div className="text-center">Amount({"\u20b9"})</div>
              </div>
              <div className="grid grid-cols-3 gap-5 p-1  ">
                <div className="col-span-2">
                  <LedgerSearchField
                    value={
                      voucherData.extras[0]
                        ? voucherData.extras[0].ledger
                        : undefined
                    }
                    onSelect={function (data: LedgerModel): void {
                      setVoucherData((ol) => {
                        return {
                          ...ol,

                          extras: [
                            {
                              ...(ol.extras[0] || ledgerEntryDefaultValue),
                              ledger: data,
                              ledger_id: data.id,
                              type: accountTxnType.debitor,
                            },
                          ],
                        };
                      });
                    }}
                  />
                </div>
                <div className="text-center">
                  <input
                    disabled={!voucherData.extras[0]}
                    value={voucherData.extras[0]?.amount}
                    onChange={(e) => {
                      const val = e.target.value.trim();

                      const value = Number(val || 0);
                      setVoucherData((ol) => ({
                        ...ol,
                        extras: [
                          {
                            ...(ol.extras[0] || ledgerEntryDefaultValue),
                            amount: value,
                            type: accountTxnType.debitor,
                          },
                        ],
                        extra: value,
                        total:
                          ol.sub_total +
                          (ol.tax || 0) +
                          value -
                          (ol.discount || 0),
                      }));
                    }}
                    placeholder=""
                    type="number"
                    className="text-right  p-1 focus:outline-none border rounded  w-full no-arrows"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <div className="text-sm grid grid-cols-2 my-3 gap-5">
          <div className="grid grid-cols-1  md:grid-cols-4  items-start gap-1 ">
            <label htmlFor="reamrk">Remark</label>
            <textarea
              rows={5}
              value={voucherData.remark}
              onChange={(e) => {
                setVoucherData((o) => ({
                  ...o,
                  remark: e.target.value,
                }));
              }}
              name=""
              id="reamrk"
              className="p-1 focus:outline-none border rounded w-full  col-span-3"
            ></textarea>
          </div>

          <div className="flex justify-end">
            <div className="flex flex-col gap-2">
              <div className=" flex gap-5 justify-between">
                <div className="">Sub total:</div>
                <div className="">
                  {currencyFormat(voucherData.sub_total || 0)}
                </div>
              </div>
              <div className=" flex gap-5 justify-between">
                <div className="">Tax:</div>
                <div className="">{currencyFormat(voucherData.tax || 0)}</div>
              </div>
              <div className=" flex gap-5 justify-between">
                <div className="">Discount:</div>
                <div className="">
                  {currencyFormat(voucherData.discount || 0)}
                </div>
              </div>
              <div className=" flex gap-5 justify-between">
                <div className="">Extra:</div>
                <div className="">{currencyFormat(voucherData.extra || 0)}</div>
              </div>
              <div className=" flex gap-5 justify-between font-bold">
                <div className="">Total:</div>
                <div className="">{currencyFormat(voucherData.total || 0)}</div>
              </div>
            </div>
          </div>
        </div>
        {/* {voucherData.entry_type === voucherEntryType.General && (
          <div className=" bg-white rounded-lg mt-2">
            <div className="grid grid-cols-10  bg-myPrimaryColor text-white  text-sm rounded-t-md p-1">
              <div className="">Type</div>
              <div className="col-span-6">Particulars</div>
              <div className="">Debit</div>
              <div className="">Credit</div>
            </div>
            <div
              className="flex flex-col gap-2 p-1 overflow-auto"
              style={{ height: "64vh" }}
            >
              {(voucherData.txns || []).map((txn) => (
                <GeneralEntryRow
                  key={txn.id}
                  value={txn}
                  onSubmit={function (data: LedgerEntryModel): void {
                    setVoucherData((o) => ({
                      ...o,
                      txns: [...(o.txns || [])].map((tx) => {
                        if (tx.id === data.id) return data;
                        return tx;
                      }),
                    }));
                  }}
                  edit={false}
                  disabled={false}
                />
              ))}
              <GeneralEntryRow
                key={(Math.random() * 1000).toString()}
                onSubmit={function (data: LedgerEntryModel): void {
                  data.id = (Math.random() * 1000).toString();
                  setVoucherData((o) => ({
                    ...o,
                    txns: [...(o.txns || []), data],
                  }));
                }}
                edit={true}
                disabled={false}
              />
            </div>
          </div>
        )} */}
      </div>
      <div className=" flex justify-end px-6 py-2">
        <button
          type="button"
          onClick={submit}
          className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm "
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default PurchaseVoucher;

// onChange={(e) =>
//   setVoucherData((o) => ({
//     ...o,
//     last_name: e.target.value,
//   }))
// }

// const handleChange = (e: any) => {
//   const { value, name } = e.target;
//   if (name.includes(".")) {
//     const fields = name.split(".");

//     if (fields.length === 3) {
//       setVoucherData((prev: any) => {
//         const tempFormData = prev;
//         tempFormData[fields[0]][fields[1]][fields[2]] = value;
//         return { ...tempFormData };
//       });
//     } else if (fields.length === 2) {
//       setVoucherData((prev: any) => {
//         const tempFormData = prev;
//         tempFormData[fields[0]][fields[1]] = value;
//         return { ...tempFormData };
//       });
//     }
//   } else setVoucherData((prev) => ({ ...prev, [name]: value }));
// };
