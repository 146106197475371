import { RouteParam } from "../../../routes";
import { MODULE, ACTION } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import ManufacturingProjection from "../journals/projection/product_projection";
import StockItemListScreen from "../stock_items/stock_item_list";
import StockItemMovementScreen from "../stock_items/stock_item_movement";
import items_routes from "./items_routes";
import item_unit_routes from "./items_unit_routes";
import item_group_routes from "./item_groups";
import manufacturing_routes from "./item_manufacturing_routes";
import item_transfer_routes from "./item_transfer_routes";
import inventory_bucket_routes from "./warehouse_groups";
import cost_center_routes from "./cost_center_routes";
import project_routes from "../../purchase_order/routers/project_routes";

const procurement_inventory_routes: RouteParam[] = [
  ...item_group_routes,
  ...items_routes,
  ...project_routes,
  ...inventory_bucket_routes,
  ...item_unit_routes,
  ...cost_center_routes,
  ...manufacturing_routes,
  ...item_transfer_routes,
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_items,
    component: StockItemListScreen,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.stock_items + "/:id",
    component: StockItemMovementScreen,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.inventory_manufacturing_projection,
    component: ManufacturingProjection,
    module: MODULE.inventory_journals,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default procurement_inventory_routes;
