import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  CostCenter,
  costCenterDefaultValue,
  MasterCostCenterStatus,
} from "../models/cost_center_model";
import { urls } from "../../../utils/urls";
import { useAuth } from "../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { ACTION, MODULE } from "../../../utils/enums";
import LoadingWidget from "../../../context_providers/modal/loader";
import moment from "moment";
import { fetchGet, fetchPost, fetchPut } from "../../../service/api_client";
import ModalDialog from "../../../ui/new_components/common/modal";
import { Project, projectDefaultValue } from "../models/project_model";
import CostCenterSearchField from "../cost_center/components/costCenterSearch";

interface ProjectCreateScreenProps {
  data?: Project;
  onClose: (id: Project) => void;
}

const ProjectCreateUpdateScreen: FunctionComponent<ProjectCreateScreenProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/project`;
  const is_update_page = useRouteMatch(
    urls.procurement_project + "/update/:id"
  );
const[availAmount,setAvailAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [checker, setChecker] = useState(false);
  const [maker, setMaker] = useState(false);

  const { showToast } = useToast();
  const [data, setData] = useState<Project>({
    ...(props.data || { ...projectDefaultValue }),
  });

  useEffect(() => {
    if (id && is_update_page) getData(id);
  }, [id]);
  
  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };
  
  useEffect(() => {
    if (data?.cost_center_id) {
      console.log("cost center id:", data.cost_center_id);
      getBudget(data.cost_center_id);
    }
  }, [data?.cost_center_id]); // Runs when cost_center_id updates
  
  const getBudget = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/getBudget/" + id);
    if (res.success) {
      console.log("Avail Amount:", res.data);
      setAvailAmount(res.data);
    } else {
      showToast({ type: ToastType.error, text: res.error });
    }
    setLoading(false);
  };  

  const onSubmit = async () => {
    try {
      if (!data.name || !data.budget) {
        showToast({
          type: ToastType.error,
          text: "Please fill all the required details",
        });
        return;
      }

      setLoading(true);

      const res = data.id
        ? await fetchPut(`${url}/${data.id}`, data)
        : await fetchPost(url, data);
      console.log({ res });
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!data.id) {
          setData(projectDefaultValue);
          // setShowSucess(true);
        }
        setData((prevData) => ({
          ...prevData,
          ...res.data, // Update with the response data
        }));
        if (props.onClose) props.onClose(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  //   useEffect(() => {
  //     // TODO: check if user has permission to update
  //     // if maker we should be ab
  //     const maker_update_result = isAuthorised({
  //       module: MODULE.cost_center_maker,
  //       action: [ACTION.UPDATE],
  //     });
  //     const checker_update_result = isAuthorised({
  //       module: MODULE.cost_center_checker,
  //       action: [ACTION.UPDATE],
  //     });

  //   }, [id, data.budget]);

  useEffect(() => {
    const total = data.budget + (data.addAdditionalBudget || 0);
    setData((prevData) => ({
      ...prevData,
      totalBudget: total,
    }));
  }, [data.budget, data.addAdditionalBudget]); // Only trigger when these two change


  const availableAmount = (e: any) => {
    const value = Number(e.target.value); 
    const total = data.budget + value;
    console.log("Avail amount:", availAmount, " ", total);
    if ((availAmount ?? 0) < total) {
      showToast({ type: ToastType.warning, text: "Exceed Budget!!" });
      return;
    }
    setData({
        ...data,
        addAdditionalBudget: value,
        duplicateAdditionalBudget: value, // Ensure both values are synced
      });
    setData((prevData) => ({
      ...prevData,
      totalBudget: total,
    }));
  };

  const budgetAvailability = (e: any) => {
    const newValue = e.target.value === "" ? 0 : Number(e.target.value);

    if (!data.cost_center_category) {
      showToast({ type: ToastType.warning, text: "Select cost center first!" });
      return;
    }
    if ((availAmount ?? 0) < newValue + (data.addAdditionalBudget ?? 0)) {
      console.log("Avail amount in budget:", availAmount, " ", newValue + (data.addAdditionalBudget ?? 0), " ", (data.addAdditionalBudget ?? 0));
      showToast({ type: ToastType.warning, text: "Exceed Budget!!" });
      return;
    }
    // Correctly update the budget field in the data state
    setData((prevData) => ({
      ...prevData,
      budget: newValue, // Update budget correctly
    }));
  };
  return (
    <>
      <div className="flex justify-between items-end my-1">
        <h1 className="flex text-xl font-bold border-l-4 border-myPrimaryColor mx-5 pl-2">
          Project {id ? "Update" : "Create"}
        </h1>
        <div className="flex justify-end   text-sm items-center gap-1 ">
          {" "}
          <div className="bg-white rounded w-52 flex gap-1"></div>
        </div>
      </div>
      <div className="mb-10">
        <div className="p-5 m-5 flex flex-col gap-5 w-3/4 shadow bg-white rounded-lg">
          <div className="flex flex-row gap-5 w-full">
            <div className="flex flex-col w-1/2">
              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Project Name*
                </label>
                <input
                  placeholder="Enter Name"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="name"
                  name="name"
                  value={data.name || ""}
                  required
                  type="text"
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                />
              </div>

              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="desc">
                  Description
                </label>
                <textarea
                  placeholder="Short description"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="description"
                  name="description"
                  value={data.description || ""}
                  onChange={(e) =>
                    setData((d) => ({ ...d, description: e.target.value }))
                  }
                ></textarea>
              </div>
              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Batch Size
                </label>
                <input
                  placeholder=""
                  className="p-1 rounded focus:outline-none w-full border"
                  id="id"
                  name="id"
                  value={data.batchSize || ""}
                  required
                  type="number"
                  onChange={(e) =>
                    setData({ ...data, batchSize: Number(e.target.value) })
                  }
                />
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Cost Center*
                </label>
                <CostCenterSearchField
                  //   disabled={!editable}/
                  placeholder="cost center"
                  value={data.cost_center_category}
                  onSelect={(d) => {
                    setData((prevData) => ({
                      ...prevData,
                      cost_center_category: d.name,
                      cost_center_id: d.id,
                      avail_budget: d.lastApprovedBudget,
                    }));
                  }}
                />
              </div>

              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Cost Center ID*
                </label>
                <input
                  placeholder=""
                  className="p-1 rounded focus:outline-none w-full border"
                  id="id"
                  name="id"
                  value={data.cost_center_id || ""}
                  required
                  type="text"
                />
              </div>
              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Batch Name
                </label>
                <input
                  placeholder=""
                  className="p-1 rounded focus:outline-none w-full border"
                  id="id"
                  name="id"
                  value={data.batchName || ""}
                  required
                  type="text"
                  onChange={(e) =>
                    setData({ ...data, batchName: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start text-sm">
            <label className="font-semibold w-full" htmlFor="name">
              Budget*
            </label>
            <input
              placeholder="Enter budget"
              className="p-1 rounded focus:outline-none w-1/4 border"
              id="budget"
              name="budget"
              value={data.budget || ""} // Correctly bind the value to data.budget
              required
              type="number"
              onChange={budgetAvailability}
            />
          </div>
          <span className="text-sm">Available Budget: {availAmount}</span>
          <div className="flex flex-row gap-4">
            {id && (
              <div className="flex flex-row gap-4">
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Additional Budget*
                  </label>
                  <input
                    placeholder="budget"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="add_budget"
                    name="add_budget"
                    value={data.addAdditionalBudget || ""}
                    required
                    type="number"
                    // onChange={(e) => {
                    //   const value = Number(e.target.value);
                    //   setData({
                    //     ...data,
                    //     addAdditionalBudget: value,
                    //     duplicateAdditionalBudget: value, // Ensure both values are synced
                    //   });
                    // }}
                    onChange={availableAmount}
                  />
                  <span className="mt-2">
                    <label className="text-green-500 w-full">
                      Add Amounts:
                    </label>
                    <p className="text-sm">
                      {data.additionalFunds
                        ?.map((fund, index) => fund.fund_amount)
                        .join(", ")}
                    </p>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col items-start text-sm">
            <label className="font-semibold w-full" htmlFor="name">
              Total Budget*
            </label>
            <input
              placeholder="total budget"
              className="p-1 rounded focus:outline-none w-1/4 border"
              id="tot_budget"
              name="tot_budget"
              value={data.totalBudget || ""}
              required
              type="number"
              readOnly
            />
          </div>
          <div className="flex flex-row gap-5 w-full">
            <div className="w-1/2">
              <div>
                <label className="text-sm font-bold">
                  Balance is calculated based on the last approved budget.
                </label>
              </div>
              <div className="bg-gray-100 p-5 rounded-md">
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Spent
                  </label>
                  <input
                    placeholder=""
                    className="p-1 rounded focus:outline-none w-full border"
                    id="spent"
                    name="spent"
                    value={data.spent || ""}
                    required
                    type="number"
                    readOnly
                  />
                </div>
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Balance{" "}
                  </label>
                  <input
                    placeholder=""
                    className="p-1 rounded focus:outline-none w-full border"
                    id="balance"
                    name="balance"
                    value={data.totalBudget - data.spent || ""}
                    required
                    type="number"
                    readOnly
                  />
                </div>
              </div>
            </div>
            {/* <div className="flex flex-col items-start text-sm w-1/2">
              <label className="font-semibold w-full" htmlFor="desc">
                Notes/Remarks
              </label>
              <textarea
                placeholder="remarks"
                className="p-1 mt-1 rounded focus:outline-none w-full border"
                id="remarks"
                name="remarks"
                rows={6}
                value={data.remarks || ""}
                onChange={(e) =>
                  setData((d) => ({ ...d, remarks: e.target.value }))
                }
              ></textarea>
            </div> */}
          </div>
        </div>
        <div className="mb-10">
          {/* {maker && ( */}
          <div className="flex justify-center gap-5 mt-5 mb-2">
            <button
              onClick={onSubmit}
              type="button"
              className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
            >
              Submit
            </button>
            <button
              onClick={() => {
                navaigation.push(urls.procurement_project);
              }}
              type="button"
              className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
            >
              Quit
            </button>
          </div>
          {/* )} */}
        </div>
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};
export default ProjectCreateUpdateScreen;
