import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import LedgerGroupScreen from "../ledger_group/list_screen";
import VendorCreateScreen from "../parties/vendor_create_screen";
import PartyListScreen from "../parties/parties_list_screen";
import VendorDetailScreen from "../parties/vendor_detail_screen";
import VendorUpdateScreen from "../parties/vendor_update_screen";
import VendorApproveScreen from "../parties/vendor_approve_screen";

const party_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_party,
    component: PartyListScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_party + "/create",
    component: VendorCreateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_party + "/create/:id",
    component: VendorCreateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_party + "/view-details/:id",
    component: VendorDetailScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_party + "/approve-reject/:id",
    component: VendorApproveScreen,
    module: MODULE.master_vendor_checker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_party + "/update/:id",
    component: VendorUpdateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default party_routes;
