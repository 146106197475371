import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../../../../context_providers/toast/toast_ctx";
import FileDropZone from "../../../../../../../new_components/common/file_drop_zone";
import { AttachmentModel } from "../../../../../../../../service/models/attachment_modal";

interface EVAttachmentSectionProps {
  attachment: AttachmentModel | null;
  category: string;
  setFile: (file: File | null) => void;
  file: File | null;
  onDelete: (data: AttachmentModel) => void;
}

const EVAttachmentSection: FunctionComponent<EVAttachmentSectionProps> = (
  props
) => {
  const { showToast } = useToast();
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    props.attachment?.url || null
  );

  useEffect(() => {
    setPreviewUrl(props.attachment?.url || null);
  }, [props.attachment]);

  const removeSelectedFile = () => {
    props.setFile(null);
    setPreviewUrl(null);
  };

  return (
    <div className="p-5 select-none">
      {!props.file && !props.attachment ? (
        <div className="p-5 relative">
          <FileDropZone
            onDrop={(files: File[]) => {
              if (!files[0]) return;

              const acceptedTypes = ["image/jpeg", "image/png"];
              if (!acceptedTypes.includes(files[0].type)) {
                showToast({
                  type: ToastType.error,
                  text: "Only JPEG and PNG files are allowed.",
                });
                return;
              }

              props.setFile(files[0]);
              const objectUrl = URL.createObjectURL(files[0]);
              setPreviewUrl(objectUrl);
            }}
          />
        </div>
      ) : (
        <div className="flex flex-col items-center">
          {previewUrl && (
            <img
              src={previewUrl}
              alt="Preview"
              className="w-32 h-32 object-cover border rounded-md"
            />
          )}
          <button
            type="button"
            onClick={() => {
              if (props.attachment) {
                props.onDelete(props.attachment);
              }
              removeSelectedFile();
            }}
            className="p-2 bg-red-500 text-white rounded-full mt-2"
          >
            <Icon path={mdiDelete} size={0.8} />
          </button>
        </div>
      )}
    </div>
  );
};

export default EVAttachmentSection;
