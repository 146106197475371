import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  CostCenter,
  costCenterDefaultValue,
  MasterCostCenterStatus,
} from "../models/cost_center_model";
import { urls } from "../../../utils/urls";
import { useAuth } from "../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { ACTION, MODULE } from "../../../utils/enums";
import LoadingWidget from "../../../context_providers/modal/loader";
import moment from "moment";
import { fetchGet, fetchPost, fetchPut } from "../../../service/api_client";
import ModalDialog from "../../../ui/new_components/common/modal";
import {
  Project,
  ProjectCloseStatus,
  projectDefaultValue,
} from "../models/project_model";
import CostCenterSearchField from "../cost_center/components/costCenterSearch";

interface ProjectViewScreenProps {
  data?: Project;
  onClose: (id: Project) => void;
}

const ProjectViewScreen: FunctionComponent<ProjectViewScreenProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/project`;
  const is_view_page = useRouteMatch(
    urls.procurement_project + "/view-project/:id"
  );
  const [close, setClose] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [data, setData] = useState<Project>({
    ...(props.data || { ...projectDefaultValue }),
  });

  useEffect(() => {
    if (id && is_view_page) getData(id);
  }, [id]);

  const ProjectClosedStatus = async (status: string) => {
    // console.log("Sending status:", status);
    try {
      await fetchPut(`${url}/${data.id}/close`, data);
      showToast({
        type: ToastType.success,
        text: `Project Status: ${status}`,
      });
      navaigation.push(urls.procurement_project);
      // Your existing success/error logic...
    } catch (error) {
      console.error("Error while updating project status:", error);
      showToast({
        type: ToastType.error,
        text: "Failed to update status. Try again later.",
      });
    }
  };

  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    const total = data.budget + (data.addAdditionalBudget || 0);
    setData((prevData) => ({
      ...prevData,
      totalBudget: total,
    }));
  }, [data.budget, data.addAdditionalBudget]); // Only trigger when these two change

  return (
    <>
      {close && (
        <ModalDialog
          show={close}
          title={"Cost Center Status: Closed"}
          onClose={() => {
            setClose(false);
            setData({
              ...data,
              projectStatus: ProjectCloseStatus.open,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to close the cost center?
            </h2>

            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setClose(false);
                  setData({
                    ...data,
                    projectStatus: ProjectCloseStatus.open,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => {
                  ProjectClosedStatus(ProjectCloseStatus.closed);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      <div className="flex justify-between items-end my-1">
        <h1 className="flex text-xl font-bold border-l-4 border-myPrimaryColor mx-5 pl-2">
          Project View
        </h1>
        <div className="flex justify-end   text-sm items-center gap-1 ">
          {" "}
          <div className="bg-white rounded w-52 flex gap-1"></div>
        </div>
      </div>
      <div className="mb-10">
        <div className="p-5 m-5 flex flex-col gap-5 w-3/4 shadow bg-white rounded-lg">
          <div className="flex flex-row gap-5 w-full">
            <div className="flex flex-col w-1/2">
              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Project Name*
                </label>
                <input
                  placeholder="Enter Name"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="name"
                  name="name"
                  value={data.name || ""}
                  required
                  type="text"
                  readOnly
                />
              </div>

              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="desc">
                  Description
                </label>
                <textarea
                  placeholder="Short description"
                  className="p-1 rounded focus:outline-none w-full border"
                  id="description"
                  name="description"
                  value={data.description || ""}
                  readOnly
                ></textarea>
              </div>
              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Batch Size
                </label>
                <input
                  placeholder=""
                  className="p-1 rounded focus:outline-none w-full border"
                  id="id"
                  name="id"
                  value={data.batchSize || ""}
                  required
                  type="number"
                  readOnly
                />
              </div>
            </div>
            <div className="flex flex-col w-1/2">
              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Cost Center*
                </label>
                <CostCenterSearchField
                  disabled={true}
                  placeholder="cost center"
                  value={data.cost_center_category}
                  onSelect={(d) => {
                    setData((prevData) => ({
                      ...prevData,
                      cost_center_category: d.name,
                      cost_center_id: d.id,
                    }));
                  }}
                />
              </div>

              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Cost Center ID*
                </label>
                <input
                  placeholder=""
                  className="p-1 rounded focus:outline-none w-full border"
                  id="id"
                  name="id"
                  value={data.cost_center_id || ""}
                  required
                  type="text"
                  readOnly
                />
              </div>
              <div className="flex flex-col items-start text-sm">
                <label className="font-semibold w-full" htmlFor="name">
                  Batch Name
                </label>
                <input
                  placeholder=""
                  className="p-1 rounded focus:outline-none w-full border"
                  id="id"
                  name="id"
                  value={data.batchName || ""}
                  required
                  type="text"
                  readOnly
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start text-sm">
            <label className="font-semibold w-full" htmlFor="name">
              Budget*
            </label>
            <input
              placeholder="Enter budget"
              className="p-1 rounded focus:outline-none w-1/4 border"
              id="budget"
              name="budget"
              value={data.budget || ""} // Correctly bind the value to data.budget
              required
              type="number"
              readOnly
            />
          </div>
          <div className="flex flex-row gap-4">
            {id && (
              <div className="flex flex-row gap-4">
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Additional Budget*
                  </label>
                  <input
                    placeholder="budget"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="add_budget"
                    name="add_budget"
                    value={data.addAdditionalBudget || ""}
                    required
                    type="number"
                    readOnly
                  />
                  <span className="mt-2">
                    <label className="text-green-500 w-full">
                      Add Amounts:
                    </label>
                    <p className="text-sm">
                      {data.additionalFunds
                        ?.map((fund, index) => fund.fund_amount)
                        .join(", ")}
                    </p>
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col items-start text-sm">
            <label className="font-semibold w-full" htmlFor="name">
              Total Budget*
            </label>
            <input
              placeholder="total budget"
              className="p-1 rounded focus:outline-none w-1/4 border"
              id="tot_budget"
              name="tot_budget"
              value={data.totalBudget || ""}
              required
              type="number"
              readOnly
            />
          </div>
          <div className="flex flex-row gap-5 w-full">
            <div className="w-1/2">
              <div>
                <label className="text-sm font-bold">
                  Balance is calculated based on the last approved budget.
                </label>
              </div>
              <div className="bg-gray-100 p-5 rounded-md">
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Spent
                  </label>
                  <input
                    placeholder=""
                    className="p-1 rounded focus:outline-none w-full border"
                    id="spent"
                    name="spent"
                    value={data.spent || ""}
                    required
                    type="number"
                    readOnly
                  />
                </div>
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Balance{" "}
                  </label>
                  <input
                    placeholder=""
                    className="p-1 rounded focus:outline-none w-full border"
                    id="balance"
                    name="balance"
                    value={data.totalBudget - data.spent || ""}
                    required
                    type="number"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-10">
          {data.projectStatus === ProjectCloseStatus.open && (
            <div className="flex justify-center gap-5 mt-5 mb-2">
              <button
                onClick={() => {
                  setClose(true);
                  setData({
                    ...data,
                    projectStatus: ProjectCloseStatus.closed,
                  });
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
              >
                Close
              </button>
              <button
                onClick={() => {
                  navaigation.push(urls.procurement_project);
                }}
                type="button"
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
              >
                Quit
              </button>
            </div>
          )}
        </div>
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};
export default ProjectViewScreen;
