import { mdiHistory, mdiPlus, mdiRefresh } from "@mdi/js";
import Icon from "@mdi/react";
import { useHistory } from "react-router-dom";
import { urls } from "../../../utils/urls";
import {
  defaultKazamGSTFilter,
  KazamGST,
  KazamGSTFilter,
} from "../models/gst_model";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { GSTGetRepo } from "./repo/gst_repo";
import LoadingWidget from "../../../context_providers/modal/loader";
import { defaultFilter } from "../../../ui/pages/complaint/listing";
import FilterDropDown from "../../../ui/new_components/common/filter_drop_down";
import { indianStates } from "../../../utils/enums";
import moment from "moment";
import FuzzySearchField from "../../../ui/new_components/fuzzy_search_field";
import { AttachmentModel } from "../../purchase_order/model/purchase_order_model";
import AttachmentSection from "../parties/components/attachment";
import {
  ModalAlignment,
  ModalType,
} from "../../../context_providers/modal/modal_context";
import DrawerWidget from "../../../context_providers/modal/drawer_widget";
import GstAttachmentSection from "./components/gst_attachment";
import GSTAttachmentModalSection from "./components/gst_attachment_modal";

const GstListScreen = () => {
  const navigation = useHistory();
  const [selectedItem, setSelectedItem] = useState<KazamGST | null>(null); // Store only one selected item
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [showAttachment, setShowAttachment] = useState<KazamGST>();

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<KazamGST[]>([]);
  const [filter, setFilter] = useState<KazamGSTFilter>(defaultKazamGSTFilter);

  const handleCheckboxChange = (item: KazamGST) => {
    setSelectedItem((prev) => (prev?.id === item.id ? null : item)); // Toggle selection
  };

  const handleDetailScreen = (item: KazamGST) => {
    navigation.push(urls.procurement_masters_gst + "/detail-screen/" + item.id);
  };

  const onSelect = () => {
    if (!selectedItem) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else {
      navigation.push(
        urls.procurement_masters_gst + "/update/" + selectedItem!.id
      );
    }
  };

  const fetchData = async (prop: KazamGSTFilter) => {
    setLoading(true);
    try {
      const res = await GSTGetRepo(prop);
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((prop: KazamGSTFilter) => {
      if (prop) fetchData(prop);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedHandler(filter);
  }, [page, filter, debouncedHandler]);

  const onSelectAttchment = (d: AttachmentModel) => {
    window.open(d.url, "_blank");
  };

  return (
    <>
      {showAttachment && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attachments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <GSTAttachmentModalSection
                // key={attachmentKey}
                attachments={showAttachment.attachments}
                onSubmit={() => {}}
                onDelete={() => {}}
                onSelect={onSelectAttchment}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(undefined);
          }}
        ></DrawerWidget>
      )}
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500border-l-4 border-myPrimaryColor pl-2 flex items-center gap-3">
            GST Master
            <button
              title="Refersh"
              onClick={() => {
                console.log("Refresh and fetch data");
              }}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end text-sm items-center gap-2 m-2">
            <div
              onClick={() => {
                navigation.push(urls.procurement_masters_gst + "/create");
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
            </div>
            <div
              onClick={onSelect}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Update
            </div>
          </div>
        </section>
        <div className="m-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-3/4">
          {/* GST Number */}
          <div className="m-4 flex flex-col">
            <label className="text-sm font-semibold mb-1">GST Number</label>
            <FuzzySearchField
              placeholder="Enter GST Number"
              onSubmit={(value) => {
                setFilter((prevData) => ({
                  ...prevData,
                  gst_number: value,
                }));
              }}
            />
          </div>

          {/* Name */}
          <div className="m-4 flex flex-col">
            <label className="text-sm font-semibold mb-1">Name</label>
            <FuzzySearchField
              placeholder="Enter Name"
              onSubmit={(value) => {
                setFilter((prevData) => ({
                  ...prevData,
                  name: value,
                }));
              }}
            />
          </div>

          {/* State Filter */}
          <div className="m-4 flex flex-col">
            <label className="text-sm font-semibold mb-1">Select State</label>
            <FilterDropDown
              placeholder="Select State"
              onClearAll={() =>
                setFilter({
                  ...filter,
                  state: [],
                })
              }
              onDelete={(state) => {
                setFilter({
                  ...filter,
                  state: filter.state?.filter((val) => val !== state),
                });
              }}
              onSelect={(state) => {
                setFilter({
                  ...filter,
                  state: [...(filter.state ?? []), state],
                });
              }}
              selectedOptions={filter.state}
              options={indianStates.map((t) => ({
                label: t,
                value: t,
              }))}
            />
          </div>
        </div>

        {/* Table Sections */}
        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full text-xs divide-y divide-gray-200 relative">
            <thead className="text-xs py-2 sticky -top-1 bg-white z-10">
              <tr className="border">
                <th className="px-2 py-2">Select</th>
                <th className="px-2 py-2">Actions</th>
                <th className="px-2 py-2 text-left">GST Number</th>
                <th className="px-2 py-2 text-left">Name</th>
                <th className="px-2 py-2 text-left">State</th>
                <th className="px-2 py-2 text-left">Address</th>
                <th className="px-2 py-2 text-left">Pincode</th>
                <th className="px-2 py-2 text-left">
                  Efffective Date of Registration
                </th>
                <th className="px-2 py-2 text-left">Attachments</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((item, i) => {
                  const isSelected = selectedItem === item;

                  return (
                    <tr key={i} className="border text-center">
                      <td>
                        <div className="p-2">
                          <input
                            type="checkbox"
                            checked={isSelected}
                            onChange={() => handleCheckboxChange(item)}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="flex gap-2 justify-center">
                          <div className="cursor-pointer">
                            <Icon path={mdiHistory} size="16"></Icon>
                          </div>
                        </div>
                      </td>
                      <td
                        className="px-3 py-2 text-left cursor-pointer text-blue-600 hover:underline"
                        // onClick={() => onSelect(item)}
                        onClick={() => handleDetailScreen(item)}
                      >
                        {item.gst_number ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {item.name ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {item.address?.state ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {item.address?.line1 ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {item.address?.pincode ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {moment(item.effective_date_of_reg).format(
                          "DD/MM/YYYY"
                        ) ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        <button
                          onClick={() => setShowAttachment(item)}
                          className="relative text-sm font-semibold text-blue-400 hover:text-blue-500 whitespace-nowrap sticky-field flex items-center gap-1 px-2 h-full w-full bg-transparent"
                        >
                          View
                          <div>({item?.attachments?.length})</div>
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {loading && <LoadingWidget />}
      </div>
    </>
  );
};

export default GstListScreen;
