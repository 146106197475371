import { debounce } from "lodash";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { UserModel } from "../../context_providers/auth/user_model";
import { ToastType, useToast } from "../../context_providers/toast/toast_ctx";
import { GetEmps } from "../../service/repos/users_repo";
import AutoCompleteField from "./common/autocomplete";

interface EmployeeSearchWidgetProps {
  onSelect: (data: UserModel) => void;
  onDelete: (uid: string) => void;
  selectedEmployees: UserModel[];
  placeholder?: string;
  disabled?: boolean;
  withBtn?: boolean;
}

const EmployeeMultiSearchWidget: FunctionComponent<
  EmployeeSearchWidgetProps
> = ({
  onSelect,
  onDelete,
  selectedEmployees,
  placeholder,
  disabled = false,
}) => {
  const { showToast } = useToast();
  const [search, setSearch] = useState("");
  const [emps, setEmps] = useState<UserModel[]>([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (edit) setSearch(""); // Clear search when entering edit mode
  }, [edit]);

  const debouncedHandler = useCallback(
    debounce((query) => {
      if (query) searchEmp(query);
    }, 500),
    []
  );

  useEffect(() => {
    if (search && edit) {
      debouncedHandler(search);
    }
  }, [search, edit, debouncedHandler]);

  const searchEmp = async (search: string) => {
    setLoading(true);
    const res = await GetEmps({ search, all: true });
    if (res.success) {
      setEmps(res.data.data!);
    } else {
      setEmps([]);
      showToast({ type: ToastType.error, text: res.error ?? "" });
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="flex gap-1 items-center">
        {edit ? (
          <AutoCompleteField
            loading={loading}
            value={search}
            placeholder={placeholder ?? "Employees"}
            onSelect={(val) => {
              setEdit(false);
              setSearch("");
              onSelect(val.value);
            }}
            suggestions={emps.map((e) => ({
              label: (
                <div className="text-xs">
                  <b>{e.first_name + " " + e.last_name}</b>
                  <span style={{ fontSize: "10px" }}>
                    {` (${e.emp_profile?.department}-${e.emp_profile?.sub_dep})`}
                  </span>
                </div>
              ),
              value: e,
            }))}
            onChange={(str) => {
              setSearch(str);
            }}
          />
        ) : (
          <div
            className="text-sm px-7 py-2 h-8 rounded text-left items-start bg-white focus:outline-none w-full border"
            onClick={() => {
              if (!disabled) {
                setEdit(true);
              }
            }}
          >
            {placeholder}
          </div>
        )}
      </div>

      {/* Render selected employees as pills */}
      <div className="flex flex-wrap gap-1 mt-2">
        {selectedEmployees.map((emp) => (
          <span
            key={emp.uid}
            className="bg-gray-200 text-xs py-1 px-2 rounded flex items-center"
          >
            {emp.first_name} {emp.last_name}
            <button
              onClick={() => onDelete(emp.uid)}
              className="ml-1 text-red-500"
            >
              x
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default EmployeeMultiSearchWidget;
