/* eslint-disable react-hooks/exhaustive-deps */
import {
  mdiArrowBottomLeft,
  mdiArrowTopRight,
  mdiClose,
  mdiRefresh,
} from "@mdi/js";
import Icon from "@mdi/react";
import debounce from "lodash.debounce";
import moment from "moment";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../../context_providers/toast/toast_ctx";
import { parse } from "papaparse";
import { FieldType } from "../../../../procurement/components/create_view/form_field";
import SearchFieldWidget from "../../../../ui/new_components/search_field";
import { UTCToLocalDate } from "../../../../utils/date_util";
import { currencyFormat } from "../../../../utils/orders_utils";
import {
  payment_status,
  PaymentAttachmentModel,
  PaymentModeOptions,
  PaymentRequestModel,
  po_payments_req_status,
  PRType,
} from "../../model/payment_request_model";
import { urls } from "../../../../utils/urls";
import {
  getPaymentList,
  postImportPaymentList,
} from "../../purchase_order_create/service/purchase_order_repository";
import FilterDropDown from "../../../../ui/new_components/common/filter_drop_down";
import NewTableView from "../../../../procurement/components/new_table_comp";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../../ui/new_components/common/drawer_modal";
import ActionHistoryAdvance from "../components/payment_request_advance_history_logs";
import ModalDialog from "../../../../ui/new_components/common/modal";
import ExportPaymentRequestAdvance from "../components/payment_request_advance_export";
import FileDropZone from "../../../../ui/new_components/common/file_drop_zone";
import DrawerWidget from "../../../../context_providers/modal/drawer_widget";
import {
  ModalAlignment,
  ModalType,
} from "../../../../context_providers/modal/modal_context";
import AttachmentBar from "../components/attachmentBar";
import { toQueryString } from "../../../../service/api_client";
import EmployeeMultiSearchWidget from "../../../../ui/new_components/emp_multi_search_widget";
import { UserModel } from "../../../../context_providers/auth/user_model";

export interface PaymentFilterModule {
  search?: string;
  page: number;
  count: number;
  all?: any;
  fromDate?: Date;
  toDate?: Date;
  sort_by?: string;
  sort_order?: string;
  filterData?: {
    [key: string]: any;
    poNo?: string;
    pr_id?: string;
    type?: string[];
    pi_no_Or_Inv_no?: string;
    emp_names?: string;
    created_by_name?: string[];
    payment_mode?: string[];
    due_date?: Date;
    created_date?: Date;
    pr_status?: string[];
    payment_status?: string[];
  };
}

export const defaultFilter: PaymentFilterModule = {
  page: 1,
  count: 100,
  sort_by: "",
  sort_order: "desc",
};

interface PaymentRequestListProps {}

const PaymentRequestListForAdvanceAndInvoice: FunctionComponent<
  PaymentRequestListProps
> = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [showImport, setShowImport] = useState(false);
  const [count] = useState(50);
  const tempFilter = localStorage.getItem("pr_filter");
  const [total, setTotal] = useState(0);
  const [showExport, setShowExport] = useState(false);
  const [showHistoryAdvance, setShowHistoryAdvance] = useState<any>();
  const [showHistoryAdvance1, setShowHistoryAdvance1] = useState<any>();
  const [filter, setFilter] = useState<PaymentFilterModule>(
    tempFilter ? JSON.parse(tempFilter) : defaultFilter
  );
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequestModel[]>(
    []
  );
  const [showAttachment, setShowAttachment] = useState<PaymentRequestModel>();
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);

  useEffect(() => {
    const now = new Date();
    let from = new Date(new Date(now).setHours(0, 0, 0, 0));
    from = new Date(from.setDate(1));
    let to = new Date(new Date(now).setHours(23, 59, 59, 999));
    to = new Date(to.setMonth(now.getMonth() + 1));
    to = new Date(to.setDate(0));
    setFromDate(from);
    setToDate(to);
  }, []);

  const getPRListData = async () => {
    try {
      // const data = await exportPaymentRequests(filter);
      // console.log("Data:",data)
      // Constructing the anchor element for download
      const a = document.createElement("a");
      a.target = "_blank";

      a.href = `${
        process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/erp/purchase-order/advance_payment_request/export${toQueryString(
        {
          query: JSON.stringify({
            ...filter,
          }),
        }
      )}`;

      // Delay the trigger to allow UI updates (if necessary)
      setTimeout(() => {
        setLoading(false);
        showToast({ type: ToastType.success, text: "Download started!" });
        a.click(); // Trigger the download
      }, 1000);
    } catch (error) {
      setLoading(false); // Make sure loading is set to false in case of an error
      showToast({ type: ToastType.error, text: "Failed to start download." });
      console.error("Download error:", error);
    }
  };

  // Function to fetch data based on the request type
  const fetchData = async (prop: {
    search?: string;
    page: number;
    count: number;
    all?: boolean;
    fromDate?: Date;
    toDate?: Date;
    filterData?: {
      poNo?: string;
      pr_id?: string;
      pi_no_Or_Inv_no?: string;
      created_by_name?: string[];
      emp_names?: string;
      type?: string[];
      payment_mode?: string[];
      due_date?: Date;
      created_date?: Date;
      pr_status?: string[];
      payment_status?: string[];
    };
  }) => {
    setLoading(true); // Start loading state
    try {
      const res = await getPaymentList(prop);
      console.log("Response for advance:", res.data.data[0]?.invoice_date);
      if (res.success) {
        setPaymentRequest(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error?.message || error.toString(),
      });
    }

    setLoading(false); // End loading state
  };

  const onSelectAttchment = (d: PaymentAttachmentModel) => {
    window.open(d.url, "_blank");
  };

  const [selectedEmployees, setSelectedEmployees] = useState<UserModel[]>([]);

  const handleSelect = (emp: UserModel) => {
    setSelectedEmployees((prev) => {
      // Check if the employee is already selected
      const isAlreadySelected = prev.some(
        (selectedEmp) => selectedEmp.uid === emp.uid
      );

      // If not already selected, add it
      if (!isAlreadySelected) {
        return [...prev, emp];
      }
      // If already selected, return the previous state
      return prev;
    });

    // Update the filter as needed, ensuring you're not adding duplicates
    setFilter((old) => ({
      ...old,
      filterData: {
        ...old.filterData,
        created_by_name: [
          ...new Set([...(old.filterData?.created_by_name || []), emp.uid!]),
        ],
        emp_names: [
          ...new Set([
            ...(old.filterData?.emp_names
              ? old.filterData.emp_names.split(", ")
              : []),
            emp.first_name + " " + emp.last_name,
          ]),
        ].join(", "),
      },
    }));
  };

  const handleDelete = (uid: string) => {
    setSelectedEmployees((prev) => {
      // Filter out the deleted employee
      const updatedEmployees = prev.filter((emp) => emp.uid !== uid);

      // Update the filter to remove the employee from created_by_name and emp_name
      setFilter((old) => ({
        ...old,
        filterData: {
          ...old.filterData,
          created_by_name: old.filterData?.created_by_name?.filter(
            (id) => id !== uid
          ),
          emp_names: old
            .filterData!.emp_names!.split(", ")
            .filter(
              (name: any) =>
                name !==
                `${prev.find((emp) => emp.uid === uid)?.first_name} ${
                  prev.find((emp) => emp.uid === uid)?.last_name
                }`
            )
            .join(", "),
        },
      }));

      return updatedEmployees;
    });
  };

  const handleClearAll = () => {
    setSelectedEmployees([]);
  };

  // Debounced handler
  const debouncedHandler = useCallback(
    debounce(
      (prop: {
        search?: string;
        page: number;
        count: number;
        all?: boolean;
        fromDate: Date;
        toDate: Date;
        filterData?: {
          poNo?: string;
          pr_id?: string;
          type?: string[];
          pi_no_Or_Inv_no?: string;
          created_by_name?: string[];
          emp_names?: string;
          payment_mode?: string[];
          due_date?: Date;
          created_date?: Date;
          pr_status?: string[];
          payment_status?: string[];
        };
      }) => {
        // Simply fetch data, the paymentRequestType logic is handled inside fetchData
        if (prop) {
          fetchData(prop);
        } else {
          setPaymentRequest([]);
        }
      },
      100 // Debounce delay (100ms)
    ),
    [] // Empty dependency array, meaning it will only be created once
  );

  // Trigger data fetch based on paymentRequestType
  useEffect(() => {
    // Whenever paymentRequestType changes, trigger the appropriate fetching
    debouncedHandler({
      search: filter.search,
      page: filter.page,
      count: filter.count,
      all: filter.all,
      fromDate,
      toDate,
      filterData: {
        poNo: filter.filterData?.poNo,
        pr_id: filter.filterData?.pr_id,
        pi_no_Or_Inv_no: filter.filterData?.pi_no_Or_Inv_no,
        created_by_name: filter.filterData?.created_by_name,
        emp_names: filter.filterData?.emp_names,
        type: filter.filterData?.type,
        payment_mode: filter.filterData?.payment_mode,
        due_date: filter.filterData?.due_date,
        created_date: filter.filterData?.created_date,
        pr_status: filter.filterData?.pr_status,
        payment_status: filter.filterData?.payment_status,
      },
    });
    localStorage.setItem("com_filter", JSON.stringify(filter));
  }, [page, count, search, fromDate, toDate, filter, filter.filterData]); // Trigger on change of paymentRequestType and other relevant properties

  const onImport = async (data: PaymentRequestModel[]) => {
    try {
      setLoading(true);
      const res = await postImportPaymentList(data);
      if (res.success) {
        await getPaymentList(filter);
      } else {
        // Handle error case
        showToast({
          type: ToastType.error,
          text: res.error || "An unexpected error occurred.",
        });
      }
    } catch (error) {
      showToast({
        type: ToastType.error,
        text: "Failed to import data.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showImport && (
        <ModalDialog
          show={showImport}
          onClose={() => setShowImport(false)}
          title="Import Payment Request"
        >
          <div className="text-xs w-96">
            <h2 className="font-semibold">Please note :</h2>
            <p>sheet should have header as</p>
            <p className="font-semibold text-purple-500">
              [PR ID # ,PO #, PO Date, Request Date, Vendor, Due Date, Payment
              Mode, PI NO, PI Date, PI Amount, Payment Amount, Project, Note, PO
              Approval, SPOC, UTR, Swift, Remarks, Payment Status, Payment
              Date,Approved At,Approved by,OnHold Reason,Reject Reason, PR
              Status, Invoice Number, Invoice Date, Invoice Amount ]
            </p>
            <p>In the first row of csv.</p>
          </div>
          <div className="h-96 w-96 mt-5">
            <FileDropZone
              accept=".csv"
              onDrop={(files) => {
                parse<any>(files[0], {
                  header: true,
                  skipEmptyLines: true,
                  complete: (data) => {
                    const res = data.data;
                    console.log("res:", res[0]);
                    if (res.length === 0) {
                      showToast({
                        type: ToastType.info,
                        text: "No record found !",
                      });
                      return;
                    }
                    const keys = Object.keys(res[0]);
                    if (
                      keys.indexOf("PR_ID") === -1 ||
                      keys.indexOf("PO") === -1
                    ) {
                      showToast({
                        type: ToastType.error,
                        text: "Required fields are [id,po_no] !",
                      });
                    }
                    const filteredData: any[] = [];
                    for (let index = 0; index < res.length; index++) {
                      const { PR_ID, PO, UTR, Swift, Payment_Status } =
                        res[index];
                      if (!PR_ID || !PO || !UTR || !Swift) {
                        showToast({
                          type: ToastType.error,
                          text: "Missing required data !",
                        });

                        return;
                      }

                      filteredData.push({
                        id: PR_ID,
                        po_no: PO,
                        utr: UTR,
                        swift: Swift,
                        payment_status: Payment_Status,
                      });
                    }

                    // Bulk upload
                    setShowImport(false);
                    console.log("filtered dta:", filteredData);
                    onImport(filteredData);
                  },
                });
              }}
            />
          </div>
        </ModalDialog>
      )}
      {/* {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <ExportPaymentRequestAdvance filter={filter} />
        </ModalDialog>
      )} */}

      {showHistoryAdvance && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={showHistoryAdvance !== undefined}
          onClose={() => setShowHistoryAdvance(undefined)}
          title="History"
        >
          <ActionHistoryAdvance
            pr_id={showHistoryAdvance}
            data={showHistoryAdvance1}
          />
        </DrawerModal>
      )}

      {showAttachment && (
        <DrawerWidget
          data={{
            id: 1,
            title: "Attchments",
            type: ModalType.drawer,
            alignment: ModalAlignment.right,
            container: (
              <AttachmentBar
                key={attachmentKey}
                attachments={showAttachment.attachments}
                // onSubmit={uploadFile}
                // onDelete={deleteFile}
                onSelect={onSelectAttchment}
              />
            ),
          }}
          onClose={function (): void {
            setShowAttachment(undefined);
          }}
        ></DrawerWidget>
      )}

      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-2xl text-gray-500 flex items-center gap-3">
              <p className=" font-bold border-l-4 border-myPrimaryColor pl-2">
                PO Payment Requests
              </p>
              <button
                title="Refersh"
                onClick={() =>
                  debouncedHandler({
                    search: filter.search,
                    page: filter.page,
                    count: filter.count,
                    all: filter.all,
                    fromDate,
                    toDate,
                    filterData: {
                      poNo: filter.filterData?.poNo,
                      pr_id: filter.filterData?.pr_id,
                      pi_no_Or_Inv_no: filter.filterData?.pi_no_Or_Inv_no,
                      type: filter.filterData?.type,
                      payment_mode: filter.filterData?.payment_mode,
                      due_date: filter.filterData?.due_date,
                      created_date: filter.filterData?.created_date,
                      pr_status: filter.filterData?.pr_status,
                      payment_status: filter.filterData?.payment_status,
                    },
                  })
                }
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex justify-end  px-5 text-sm items-center gap-1 ">
              <div className="text-sm flex items-center gap-2">
                <div className="flex items-center  gap-1">
                  <label htmlFor="fromDate" className="">
                    From
                  </label>
                  <input
                    type="date"
                    id="fromDate"
                    className="p-1 rounded"
                    value={
                      filter.filterData?.created_date
                        ? moment(filter.filterData?.created_date).format(
                            "YYYY-MM-DD"
                          )
                        : ""
                    }
                    onChange={(e) => {
                      const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                      const fromDate = selectedDate
                        ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                        : undefined; // Reset if no date is selected

                      // Update the filter
                      setFilter({
                        ...filter,
                        filterData: {
                          ...filter.filterData,
                          created_date: fromDate,
                        },
                      });
                    }}
                  />
                </div>
                <div className="flex items-center  gap-1">
                  <label htmlFor="toDate" className="">
                    To
                  </label>
                  <input
                    type="date"
                    name=""
                    id="toDate"
                    className="p-1 rounded "
                    value={
                      filter.filterData?.due_date
                        ? moment(filter.filterData?.due_date).format(
                            "YYYY-MM-DD"
                          )
                        : ""
                    }
                    onChange={(e) => {
                      const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                      const dueDate = selectedDate
                        ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                        : undefined; // Reset if no date is selected

                      // Update the filter
                      setFilter({
                        ...filter,
                        filterData: {
                          ...filter.filterData,
                          due_date: dueDate,
                        },
                      });
                    }}
                  />
                </div>
                {(filter.filterData?.created_date ||
                  filter.filterData?.due_date) && (
                  <button
                    title="Clear dates"
                    onClick={() =>
                      setFilter((o) => ({
                        ...o,
                        filterData: {
                          ...filter.filterData,
                          created_date: undefined,
                          due_date: undefined,
                        },
                      }))
                    }
                    className="flex gap-1 items-center cursor-pointer rounded p-1 border  r hover:scale-105 duration-200     transform"
                  >
                    <Icon path={mdiClose} size={0.7}></Icon>
                  </button>
                )}
              </div>
              {/* <button
                onClick={() => {
                  setShowImport(true);
                }}
                className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
              >
                <Icon path={mdiArrowBottomLeft} size="15"></Icon>
                Import
              </button> */}
              <button
                onClick={() => {
                  // setShowExport(true);
                  getPRListData();
                }}
                className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
              >
                <Icon path={mdiArrowTopRight} className="h-4 w-4"></Icon>
                Export PR
              </button>

              {/* <SearchFieldWidget
                defaulValue={search}
                onClear={() => {
                  setSearch("");
                  setPage(1);
                }}
                onSubmit={(val) => {
                  setPage(1);
                  setSearch(val);
                }}
              /> */}
            </div>
          </div>
          <div className="flex flex-col gap-4 p-3 w-full">
            <div className="flex flex-row gap-4 w-full">
              <div className="flex flex-col gap-1">
                <label className="text-md pt-1 font-semibold">PO No.</label>
                <SearchFieldWidget
                  defaulValue={filter.filterData?.poNo}
                  onClear={() => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        poNo: undefined,
                      },
                    });
                    setPage(1);
                  }}
                  onSubmit={(val) => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        poNo: val, // Use the entered value
                      },
                    });
                    setPage(1); // Reset pagination
                  }}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-md pt-1 font-semibold">PR ID</label>
                <SearchFieldWidget
                  defaulValue={filter.filterData?.pr_id}
                  onClear={() => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        pr_id: undefined,
                      },
                    });
                    setPage(1);
                  }}
                  onSubmit={(val) => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        pr_id: val, // Use the entered value
                      },
                    });
                    setPage(1); // Reset pagination
                  }}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-md pt-1 font-semibold">
                  PI/Invoice No.
                </label>
                <SearchFieldWidget
                  defaulValue={filter.filterData?.pi_no_Or_Inv_no}
                  onClear={() => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        pi_no_Or_Inv_no: undefined,
                      },
                    });
                    setPage(1);
                  }}
                  onSubmit={(val) => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        pi_no_Or_Inv_no: val, // Use the entered value
                      },
                    });
                    setPage(1); // Reset pagination
                  }}
                />
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-md pt-1 font-semibold">Created By</label>
                <div className="flex gap-1 items-center w-full">
                  {!loading && (
                    <div>
                      <EmployeeMultiSearchWidget
                        placeholder="Created By"
                        selectedEmployees={selectedEmployees}
                        onSelect={handleSelect}
                        onDelete={handleDelete}
                      />
                    </div>
                  )}
                  {filter.filterData && filter.filterData.emp_names && (
                    <button
                      className="text-xs"
                      onClick={() => {
                        setLoading(true);
                        setFilter((old) => ({
                          ...old,
                          filterData: {
                            ...old.filterData,
                            created_by_name: undefined,
                            emp_names: undefined,
                          },
                        }));
                        setLoading(false);
                      }}
                    >
                      <button
                        onClick={handleClearAll}
                        className="focus:outline-none"
                      >
                        <Icon path={mdiClose} className="h-4 w-4" />
                      </button>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-4 w-1/2">
             
              <div className="flex flex-col gap-1 w-full">
                <label className="text-md pt-1 font-semibold">
                  {" "}
                  Payment Status
                </label>
                <FilterDropDown
                  placeholder="Payment Status"
                  onClearAll={() =>
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        payment_status: undefined,
                      },
                    })
                  }
                  onDelete={(type) => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        payment_status:
                          filter.filterData?.payment_status?.filter(
                            (f: any) => f !== type
                          ),
                      },
                    });
                  }}
                  onSelect={(type) => {
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        payment_status: [
                          ...(old.filterData?.payment_status ?? []),
                          type,
                        ],
                      },
                    }));
                  }}
                  selectedOptions={filter.filterData?.payment_status ?? []}
                  options={Object.values(payment_status).map((t) => ({
                    label: t,
                    value: t,
                  }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <label className="text-md pt-1 font-semibold"> PR Status</label>
                <FilterDropDown
                  placeholder="PR Status"
                  onClearAll={() =>
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        pr_status: undefined,
                      },
                    })
                  }
                  onDelete={(type) => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        pr_status: filter.filterData?.pr_status?.filter(
                          (f: any) => f !== type
                        ),
                      },
                    });
                  }}
                  onSelect={(type) => {
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        pr_status: [...(old.filterData?.pr_status ?? []), type],
                      },
                    }));
                  }}
                  selectedOptions={filter.filterData?.pr_status ?? []}
                  options={Object.values(po_payments_req_status).map((t) => ({
                    label: t,
                    value: t,
                  }))}
                />
              </div>
              <div className="flex flex-col gap-1 w-full">
                <label className="text-md pt-1 font-semibold"> Type</label>
                <FilterDropDown
                  placeholder="Type"
                  onClearAll={() =>
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        type: undefined,
                      },
                    })
                  }
                  onDelete={(type) => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        type: filter.filterData?.type?.filter(
                          (f: any) => f !== type
                        ),
                      },
                    });
                  }}
                  onSelect={(type) => {
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        type: [...(old.filterData?.type ?? []), type],
                      },
                    }));
                  }}
                  selectedOptions={filter.filterData?.type ?? []}
                  options={Object.values(PRType).map((t) => ({
                    label: t,
                    value: t,
                  }))}
                />
              </div>

              <div className="flex flex-col gap-1 w-full">
                <label className="text-md pt-1 font-semibold">
                  {" "}
                  Payment Mode
                </label>
                <FilterDropDown
                  placeholder="select payment"
                  onClearAll={() =>
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        payment_mode: undefined,
                      },
                    })
                  }
                  onDelete={(type) => {
                    setFilter({
                      ...filter,
                      filterData: {
                        ...filter.filterData,
                        payment_mode: filter.filterData?.payment_mode?.filter(
                          (f: any) => f !== type
                        ),
                      },
                    });
                  }}
                  onSelect={(type) => {
                    setFilter((old) => ({
                      ...old,
                      filterData: {
                        ...old.filterData,
                        payment_mode: [
                          ...(old.filterData?.payment_mode ?? []),
                          type,
                        ],
                      },
                    }));
                  }}
                  // selectedOptions={filter.filterData?.ticket_type ?? [ComplaintTicketType.incident]}
                  selectedOptions={filter.filterData?.payment_mode ?? []}
                  options={Object.values(PaymentModeOptions).map((t) => ({
                    label: t,
                    value: t,
                  }))}
                />
              </div>
            </div>
          </div>
          {/* <div className="h-52 w-full bg-green-200 "></div> */}
        </section>
        <NewTableView
          stickyHeader={true}
          show_index={true}
          show_pagination={true}
          headers={[
            {
              key: "pay_req_id",
              value: "PR ID #",
              type: FieldType.string,
            },
            {
              key: "po_no",
              value: "PO #",
              type: FieldType.string,
            },
            {
              key: "type",
              value: "Type",
              type: FieldType.string,
            },
            {
              key: "po_date",
              value: "PO Date",
              type: FieldType.date,
            },
            {
              key: "request_date",
              value: "Created Date",
              type: FieldType.date,
            },
            {
              key: "created_by",
              value: "Created By",
              type: FieldType.string,
            },
            {
              key: "vendor_name",
              value: "Vendor",
              type: FieldType.string,
            },
            {
              key: "due_date",
              value: "Due Date",
              type: FieldType.date,
            },
            {
              key: "payment_mode",
              value: "Payment Mode",
              type: FieldType.string,
            },
            {
              key: "invoice_no",
              value: "Invoice Number",
              type: FieldType.string,
            },
            {
              key: "invoice_date",
              value: "Invoice Date",
              type: FieldType.date,
            },
            {
              key: "invoice_amount",
              value: "Invoice Amount",
              type: FieldType.currency,
            },
            {
              key: "location",
              value: "Customer Location",
              type: FieldType.string,
            },
            {
              key: "category_of_expense",
              value: "Category of Expense",
              type: FieldType.string,
            },
            {
              key: "no_of_installations",
              value: "No. of Installations",
              type: FieldType.string,
            },
            {
              key: "PI_no",
              value: "PI NO",
              type: FieldType.string,
            },
            {
              key: "pi_date",
              value: "PI Date",
              type: FieldType.date,
            },
            {
              key: "PI_amount",
              value: "PI Amount",
              type: FieldType.currency,
            },
            {
              key: "amount",
              value: "Payment Amount",
              type: FieldType.currency,
            },
            {
              key: "project_name",
              value: "Project",
              type: FieldType.string,
            },
            {
              // colspan: 4,
              key: "description",
              value: "Note",
              type: FieldType.long_string,
            },
            {
              key: "po_approval_status",
              value: "PO Approval",
              type: FieldType.string,
            },
            {
              key: "created_by",
              value: "SPOC",
              type: FieldType.string,
            },
            {
              key: "utr",
              value: "UTR",
              type: FieldType.string,
            },
            {
              key: "swift",
              value: "Swift",
              type: FieldType.string,
            },
            {
              key: "tds_amount",
              value: "TDS Amount",
              type: FieldType.number,
            },
            {
              key: "tds_percent",
              value: "TDS Percent",
              type: FieldType.number,
            },
            {
              key: "paid_amount",
              value: "Paid Amount",
              type: FieldType.number,
            },
            {
              key: "remarks",
              value: "Remarks",
              type: FieldType.string,
            },
            {
              key: "status",
              value: "Payment Status",
              type: FieldType.string,
            },
            {
              key: "pay_date",
              value: "Payment Date",
              type: FieldType.date,
            },
            {
              key: "approved_at",
              value: "Approved At",
              type: FieldType.date,
            },
            {
              key: "approved_by",
              value: "Approved by",
              type: FieldType.string,
            },
            {
              key: "onhold_reason",
              value: "OnHold Reason",
              type: FieldType.string,
            },
            {
              key: "reason",
              value: "Reject Reason",
              type: FieldType.string,
            },
            {
              key: "pr_status",
              value: "PR Status",
              type: FieldType.string,
            },
            {
              key: "",
              value: "Attachment",
              type: FieldType.string,
            },
          ]}
          rows={paymentRequest.map((data, i) => ({
            data: {
              ...data,
              amount: data.payment_amount,
              description: data.notes,
              status: data.payment_status,
            },
            cells: [
              {
                builder: () => {
                  return data?.type === "ADVANCE" ? (
                    <a
                      href={`${urls.po_advance_payment_request}/updateAdvance/${data?.id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      {data.id}
                    </a>
                  ) : (
                    <a
                      href={`${urls.po_advance_payment_request}/updateInvoice/${data?.id}`}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      {data?.id}
                    </a>
                  );
                },
              },
              {
                builder: () => (
                  <a
                    href={`${urls.purchase_order}/update/${data?.po_id}`}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    {data.po_no}
                  </a>
                ),
              },
              { value: data.type },
              { value: data.po_date },
              { value: data.created_at },
              { value: data.created_by_name },
              { value: data.vendor },
              { value: data?.due_date },
              { value: data?.payment_mode },
              { value: data?.invoice_no },
              { value: data?.invoice_date ? data?.invoice_date : "--" },
              // {value:data?.invoice_date ?
              //   (new Date(data.invoice_date).toString().trim() === 'Invalid date' ? '' : new Date(data.invoice_date))
              //   : ''},
              { value: data?.invoice_amount },
              { value: data?.customer_location },
              { value: data?.category_of_expence },
              { value: data?.no_of_intallations },
              { value: data?.pi_no },
              { value: data?.pi_date },
              { value: data?.pi_amount },
              { value: currencyFormat(data.payment_amount) },
              { value: data.project },
              { value: data.notes || "--" },
              { value: data.po_approval },
              { value: data.created_by_name },
              { value: data?.utr },
              { value: data?.swift },
              { value: data?.tds_amount},
              { value: data?.tds_percent},
              { value: data?.amount_paid},
              { value: data?.paid_remarks ?? data?.failed_remarks ?? "" },
              { value: data.payment_status },
              // { value: data?.payment_date ? data?.payment_date : "--" },
              { value: data.payment_date },
              { value: data.approved_at },
              { value: data.approved_by_name },
              { value: data.onhold_reason },
              { value: data.reject_reason },
              { value: data.pr_status },
              {
                builder: () => (
                  <button
                    onClick={() => setShowAttachment(data)}
                    className="relative text-sm font-semibold text-blue-400 hover:text-blue-500 whitespace-nowrap sticky-field  flex items-center gap-1 px-2 h-full w-full"
                  >
                    View
                    <div>({data?.attachments?.length})</div>
                  </button>
                ),
              },
            ],
          }))}
          page={page}
          total={total}
          count={count}
          itemCount={paymentRequest.length}
          onPageChange={setPage}
          onShowHistory={(data) => {
            setShowHistoryAdvance(data.id);
            setShowHistoryAdvance1(data);
          }}
          // onClick={onSelect}
        />
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PaymentRequestListForAdvanceAndInvoice;
