import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import WarehouseViewScreen from "../inventory_basket/components/warehouse_view";
import InventoryBasketCreateScreen from "../inventory_basket/warehouse_create";
import InventoryBasketListScreen from "../inventory_basket/warehouse_list_screen";

const inventory_bucket_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_warehouse,
    component: InventoryBasketListScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_warehouse + "/create",
    component: InventoryBasketCreateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_warehouse + "/update/:id",
    component: InventoryBasketCreateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.UPDATE],
    operator: "or",
  },

  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_warehouse + "/view/:id",
    component: WarehouseViewScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.READ],
    operator: "or",
  },

];

export default inventory_bucket_routes;
