import { RouteParam } from "../../../routes";
import { MODULE, ACTION } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import PaymentRequestAdvanceApprovalScreen from "../payment_request/payment_request_approval_view/advance_payment_request_approval_view";
import PaymentRequestInvoiceApprovalScreen from "../payment_request/payment_request_approval_view/invoice_payment_request_approval_view";
import PaymentRequestAdvanceScreen from "../payment_request/payment_request_view/advance_payment_request_view";
import PaymentRequestInvoiceScreen from "../payment_request/payment_request_view/invoice_payment_request_view";
import PaymentRequestListForAdvanceAndInvoice from "../payment_request/payment_requests_list";
import PaymentRequestApprovalListForAdvanceAndInvoice from "../payment_request/payment_request_approvals_list";

const payment_request_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_advance_payment_request,
    component: PaymentRequestListForAdvanceAndInvoice,
    module: MODULE.payment_request_maker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request_approval,
    component: PaymentRequestApprovalListForAdvanceAndInvoice,
    module: MODULE.payment_request_checker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_advance_payment_request + "/updateAdvance/:id",
    component: PaymentRequestAdvanceScreen,
    module: MODULE.payment_request_maker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_advance_payment_request + "/updateInvoice/:id",
    component: PaymentRequestInvoiceScreen,
    module: MODULE.payment_request_maker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request_approval + "/approveAdvance/:id",
    component: PaymentRequestAdvanceApprovalScreen,
    module: MODULE.payment_request_checker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request_approval + "/approveInvoice/:id",
    component: PaymentRequestInvoiceApprovalScreen,
    module: MODULE.payment_request_checker,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default payment_request_routes;
