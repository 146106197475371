import { mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import { ItemMovementModel } from "../../../../../erp/inventory/models/inventory_voucher";
import ItemSearchField from "../../../../../erp/inventory/items/item_search_field";
import {
  WAREHOUSE_TYPE,
  WarehouseModel,
} from "../../../../../erp/inventory/models/warehouse_model";
import WarehouseSearchField from "../../../../../erp/inventory/warehouse/warehouse_search_field";
const defaultItem = () => {
  const x: ItemMovementModel = {
    date: new Date(),
    item_id: "",
    bill_unit_no: 0,
    unit_no: 0,
    received_unit_no: 0,
    lost_unit_no: 0,
    price: 0,
    amount: 0,
    movement_type: "",
    voucher_type: "",
    voucher_id: "",
    voucher_no: "",
    status: "",
    projectFrom: [],
    projectTo: {
      name: "",
      description: "",
      amount: 0,
      spent: 0,
      batch_name: "",
      batch_size: 0,
      category: "",
    },
    warehouse_id: "",
    warehouse: {
      name: "",
      type: "internal",
      // group_id: "",
      spoc: {
        uid: "",
        contact_name: "",
        contact_email: "",
        contact_mobile: "",
      },
      address: {
        id: "",
        type: "",
        line1: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      },
      // tax_detail: {
      //   pan: "",
      //   reg_type: "Unkown",
      //   gst_no: "",
      // },
    },
    // project: {
    //   name: "",
    //   description: "",
    //   amount: 0,
    //   spent: 0,
    //   batch_name: "",
    //   batch_size: 0,
    //   category: "",
    // },
  };
  return x;
};
interface ExtraRowWidgetProps {
  extra?: ItemMovementModel;
  onSubmit: (data: ItemMovementModel) => void;
  edit: boolean;
  onDelete?: (i: ItemMovementModel) => void;
  // form_id?: string;
}

const ExtraRowWidget: FunctionComponent<ExtraRowWidgetProps> = (props) => {
  const { showToast } = useToast();

  const { edit, onSubmit } = props;
  const [extra, setExtra] = useState<ItemMovementModel>({
    ...(props.extra ?? defaultItem()),
  });
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
  }, [edit]);
  const onDone = () => {
    if (!extra.item_id || extra.unit_no < 1) {
      showToast({
        type: ToastType.warning,
        text: "Please select warehouse, item and qty",
      });
      return;
    }
    if (!extra.price) {
      extra.price = 0;
    }
    onSubmit(extra);
  };
  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white rounded-md shadow p-5 "
      >
        <div className="grid grid-cols-9 gap-3">
          <div className="col-span-2">
            <WarehouseSearchField
              // type={WAREHOUSE_TYPE.internal}
              placeholder="Select warehouse"
              value={extra.warehouse}
              onSelect={function (warehouse: WarehouseModel): void {
                setExtra((o) => ({
                  ...o,
                  warehouse_id: warehouse.id,
                  warehouse,
                }));
              }}
            />
          </div>
          <div className="col-span-2">
            <ItemSearchField
              placeholder="Select warehouse"
              value={extra.item}
              onSelect={(item) => {
                setExtra((o) => ({
                  ...o,
                  item_id: item.id,
                  item,
                }));
              }}
            />
          </div>

          <input
            value={extra?.amount}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setExtra((ol) => ({ ...ol, amount: v }));
            }}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={extra?.price}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setExtra((ol) => ({ ...ol, price: v }));
            }}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={extra?.unit_no}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setExtra((ol) => ({ ...ol, unit_no: v }));
            }}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <div className="text-right  p-1 rounded bg-gray-100">
            {extra.unit_no * extra.price}
          </div>

          <div className="flex gap-2 items-center justify-end">
            {props.extra ? (
              <>
                <div className="flex justify-center gap-2">
                  {editable ? (
                    <>
                      <div
                        onClick={() => {
                          if (!window.confirm("Are you sure !")) return;
                          onDone();
                          setEditable(false);
                        }}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                      </div>
                      <div
                        onClick={() => {
                          setExtra(props.extra!);
                          setEditable(false);
                        }}
                        className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </>
                  ) : (
                    <div
                      onClick={() => setEditable(true)}
                      className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                    >
                      <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                    </div>
                  )}
                </div>
                <div
                  onClick={() => {
                    if (!window.confirm("Are you sure !")) return;
                    if (props.onDelete) props.onDelete(extra);
                  }}
                  className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                >
                  <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                </div>
              </>
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1 hover:scale-110 transform duration-300"
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default ExtraRowWidget;
