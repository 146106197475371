import { AttachmentModel } from "../../../../../../../../service/models/attachment_modal";
import { BaseModel } from "../../../../../../../../service/models/base";

export interface EVehicle extends BaseModel {
  type: string;
  category: string;
  brand: string;
  model: string;
  battery_capacity?: string;
  charging_standard?: string; // Optional if it may not be present
  connector_type?: string;
  connector_type2?: string;
  range?: string;
  status: boolean;
  logo_url?: AttachmentModel; // Optional if not always provided
  image_url?: AttachmentModel;
  link?: string;
}

export const DEFAULT_EV_VEHICLE: EVehicle = {
  id: 0, // Assuming BaseModel has an id
  type: "",
  category: "",
  brand: "",
  model: "",
  status: true, // Defaulting to active
};

export interface EVehicleListFilter {
  page: number; // Pagination page number
  count: number; // Number of results per page
  search?: string; // General search query
  status?: boolean;
}
