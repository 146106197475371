import {
  fetchGet,
  getHeaders,
  toQueryString,
} from "../../../service/api_client";
import { POReportFilterModel } from "../models/filter_model";

export const exportPOReportData = async (prop: POReportFilterModel) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/po-report`;
  // const res = await fetchGet(url + `/export` + toQueryString(prop));
  // return res;
  const res = await fetch(url + "/export", {
    method: "post",
    body: JSON.stringify(prop),
    headers: { ...getHeaders(), "Content-Type": "application/json" },
    credentials: "include",
  });
  return res;
};

export const getPoReportData = async (prop: POReportFilterModel) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/po-report`;
  const res = await fetchGet(url + toQueryString(prop));
  return res;
};
