import {
  mdiCheck,
  mdiCheckboxMarkedCircleOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiCloseCircleOutline,
  mdiInformationOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import moment from "moment";
import StepperWidget from "../../../../ui/new_components/common/stepper_widget";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../../utils/enums";
import {
  approval_level,
  approval_threshold,
  CostCenter,
  getCostCenterApprovedCounts,
  levelModule,
  MasterCostCenterStatus,
} from "../../models/cost_center_model";
import ApprovalData from "../../../purchase_order/purchase_order_approval/components/approval_data_sheet";

interface ApprovalBottomSheetWidgetProps {
  data: CostCenter;
  onSubmit: (data: { approval_status: string; level: string }) => void;
}

const ApprovalBottomSheetWidget: FunctionComponent<
  ApprovalBottomSheetWidgetProps
> = (props) => {
  const { user, isAuthorised } = useAuth();
  const { showModal } = useModal();
  const [expanded, setExpanded] = useState(false);
  const [showActionBtn, setshowActionBtn] = useState(false);
  const [approvalCounts, setapprovalCounts] = useState<{
    [key: string]: number;
  }>({});
  const [approvalLevels, setapprovalLevels] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const counts = getCostCenterApprovedCounts({
      approvals: props.data.approvals,
    });
    setapprovalCounts(counts);

    const rule =
      approval_threshold[props.data.approval_level || approval_level.level_1];

    const t = rule.approvals_required.reduce(
      (
        pv: {
          [key: string]: number;
        },
        v: { level: string; count: number }
      ) => ({ ...pv, [v.level]: v.count }),
      {}
    );

    setapprovalLevels(t);
  }, [props.data]);

  useEffect(() => {
    setshowActionBtn(
      (props.data.current_approval_required_count ?? 0) >
        (props.data.current_approval_count ?? 0) &&
        isAuthorised({
          action: ACTION.READ,
          module: levelModule[props.data.current_approval_level!],
        })
    );
    console.log("props:",approvalLevels, " ", props.data.current_approval_required_count, " ", props.data.current_approval_count, " ",showActionBtn)
    
  }, [props.data]);
  const levels = Object.keys(approvalLevels);
  return (
    <div className="">
      {props.data.approval_level !== approval_level.level_1 && (
        <div className="mt-2 border rounded-md p-2 bg-gray-50 mb-2">
          <div className="pb-5">
            <StepperWidget
              steps={levels.map((level, i) => ({
                label: level,
                validated:
                  i < levels.indexOf(props.data.current_approval_level!) ||
                  (props.data.approval_level ==
                    props.data.current_approval_level &&
                    (props.data.current_approval_count ?? 0) >=
                      (props.data.current_approval_required_count ?? 0)),
                required: false,
              }))}
              activeStep={levels.indexOf(props.data.current_approval_level!)}
              onChange={function (step: number): void {
                showModal({
                  container: (i) => (
                    <div className="p-5">
                      <ApprovalData
                        approvals={
                          props.data.approvals?.filter(
                            (f) => f.level == levels[step]
                          ) ?? []
                        }
                        approved_count={approvalCounts[levels[step]] ?? 0}
                        approval_count_required={approvalLevels[levels[step]]}
                        approval_level={levels[step]}
                      />
                    </div>
                  ),
                  title: `${levels[step]} Approval Detail`,
                  type: ModalType.drawer,
                  alignment: ModalAlignment.right,
                });
              }}
            />
          </div>
        </div>
      )}
      <div className="flex justify-between ">
        <div className="">
          <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
            <Icon path={mdiInformationOutline} size={0.8}></Icon>
            This Cost center requires {props.data.approval_level} approval
          </div>
        </div>
        {showActionBtn && (
          <div className="flex items-end gap-5 text-sm">
            <button
              onClick={() => {
                props.onSubmit({
                  approval_status: MasterCostCenterStatus.rejected,
                  level: props.data.current_approval_level!,
                });
              }}
              className={`${
                false
                  ? "bg-gray-400 cursor-not-allowed"
                  : "border-myPrimaryColor"
              } rounded-md border px-3 py-1 text-myPrimaryColor`}
            >
              Reject
            </button>
            <button
              onClick={() => {
                props.onSubmit({
                  approval_status: MasterCostCenterStatus.approved,
                  level: props.data.current_approval_level!,
                });
              }}
              className={`${
                false
                  ? "bg-gray-400 cursor-not-allowed"
                  : "bg-myPrimaryColor border border-myPrimaryColor"
              } rounded-md px-3 py-1 text-white`}
            >
              Approve
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ApprovalBottomSheetWidget;
