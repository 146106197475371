import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  CostCenter,
  costCenterDefaultValue,
  MasterCostCenterStatus,
} from "../models/cost_center_model";
import { urls } from "../../../utils/urls";
import { useAuth } from "../../../context_providers/auth/authProvider";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { ACTION, MODULE } from "../../../utils/enums";
import LoadingWidget from "../../../context_providers/modal/loader";
import moment from "moment";
import { fetchGet, fetchPost, fetchPut } from "../../../service/api_client";
import ModalDialog from "../../../ui/new_components/common/modal";

interface CostCenterCreateScreenProps {
  data?: CostCenter;
  onClose: (id: CostCenter) => void;
}

const CostCenterCreateScreen: FunctionComponent<CostCenterCreateScreenProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/cost_center`;
  const is_update_page = useRouteMatch(
    urls.procurement_masters_cost_center + "/update/:id"
  );

  const [loading, setLoading] = useState(false);
  const [checker, setChecker] = useState(false);
  const [maker, setMaker] = useState(false);

  const { user, isAuthorised } = useAuth();
  const [canEdit, setCanEdit] = useState(true);
  const [approval, setApproval] = useState(false);
  const [reject, setReject] = useState(false);
  const [isStatusDraft, setIsStatusDraft] = useState(false);

  const { showToast } = useToast();
  const [data, setData] = useState<CostCenter>({
    ...(props.data || { ...costCenterDefaultValue }),
  });

  useEffect(() => {
    if (id && is_update_page) getData(id);
  }, [id]);

  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const calculatePeriodInMonths = (fromDate: Date, toDate: Date) => {
    // Ensure the dates are valid
    if (!fromDate || !toDate) return 0;

    const from = moment(fromDate);
    const to = moment(toDate);

    // Calculate the difference in months
    return to.diff(from, "months");
  };

  const periodInMonths =
    data.fromMonth && data.toMonth
      ? calculatePeriodInMonths(data.fromMonth, data.toMonth)
      : 0;

  const CostCenterApprovalStatus = async (status: string) => {
    if (status === MasterCostCenterStatus.rejected && !data.remarks) {
      showToast({
        type: ToastType.error,
        text: "Need Cost Centr Reject Remarks.",
      });
      return;
    } else {
      // console.log("Sending status:", status);
      try {
        const res = await fetchPut(`${url}/${data.id}/approval`, data);

        console.log("API response:", res); // Log the full response for debugging
        showToast({
          type: ToastType.success,
          text: `Cost Center Status: ${status}`,
        });
        navaigation.push(urls.procurement_masters_cost_center);
        // Your existing success/error logic...
      } catch (error) {
        console.error("Error while updating cost center status:", error);
        showToast({
          type: ToastType.error,
          text: "Failed to update status. Try again later.",
        });
      }
    }
  };

  const onSaveAsDraft = async () => {
    // if (selectedType === "Product") {
    //   if (is_update_page && !productData._id && serviceData._id) {
    //     productData._id = serviceData._id;
    //     productData.item_code = serviceData.item_code;
    //     productData.id = serviceData.id;
    //   }

    //   console.log("onSubmit", productData);
    //   productData.type = selectedType;
    //   productData.status = MasterItemStatus.SavedDraft;
    //   if (!productData.name) {
    //     showToast({
    //       type: ToastType.error,
    //       text: "Please fill required fields !",
    //     });
    //     return;
    //   }
    //   // TODO: Check if tax applicable is there or not
    //   setLoading(true);
    //   const res = await saveAsDraftRepo(productData);
    //   if (res.success) {
    //     if (!productData.id) {
    //       setProductData(ItemModelV2Default);
    //     }
    //     console.log("res", res);
    //     setProductData(res.data);
    //     // setData((prevData) => ({
    //     //   ...prevData,
    //     //   ...res.data,
    //     // }));
    //     history.goBack();
    //     showToast({ type: ToastType.success, text: res.message });
    //     if (props.onClose) props.onClose(res.data);
    //   } else showToast({ type: ToastType.error, text: res.error });
    // } else if (selectedType === "Service") {
    //   if (is_update_page && !serviceData._id && productData._id) {
    //     serviceData._id = productData._id;
    //     serviceData.item_code = productData.item_code;
    //     serviceData.id = productData.id;
    //   }
    //   console.log("onSubmit SERVICE DATA", serviceData);
    //   serviceData.type = selectedType;
    //   serviceData.status = MasterItemStatus.SavedDraft;
    //   if (!serviceData.name) {
    //     showToast({
    //       type: ToastType.error,
    //       text: "Please fill required fields !",
    //     });
    //     return;
    //   }
    //   setLoading(true);
    //   const res = await saveAsDraftRepo(serviceData);
    //   if (res.success) {
    //     if (!serviceData.id) {
    //       setServiceData(ItemModelV2Default);
    //     }
    //     console.log("res", res);
    //     setServiceData(res.data);
    //     // setData((prevData) => ({
    //     //   ...prevData,
    //     //   ...res.data,
    //     // }));
    //     history.goBack();
    //     showToast({ type: ToastType.success, text: res.message });
    //     if (props.onClose) props.onClose(res.data);
    //   } else showToast({ type: ToastType.error, text: res.error });
    // }
    setLoading(false);
  };

  const onSubmit = async () => {
    try {
      console.log("data:",data)
      // console.log("duplicateAdditionalBudget:",data.duplicateAdditionalBudget);
      if (!data.name || !data.budget) {
        showToast({
          type: ToastType.error,
          text: "Please fill all the required details",
        });
        return;
      }

      setLoading(true);

      const res = data.id
        ? await fetchPut(`${url}/${data.id}`, data)
        : await fetchPost(url, data);
      console.log({ res });
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!data.id) {
          setData(costCenterDefaultValue);
          // setShowSucess(true);
        }
        setData((prevData) => ({
          ...prevData,
          ...res.data, // Update with the response data
        }));
        if (props.onClose) props.onClose(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    // TODO: check if user has permission to update
    // if maker we should be ab
    const maker_update_result = isAuthorised({
      module: MODULE.cost_center_maker,
      action: [ACTION.UPDATE],
    });
    const checker_update_result = isAuthorised({
      module: MODULE.cost_center_checker,
      action: [ACTION.UPDATE],
    });
    if (maker_update_result && !checker_update_result) {
      setCanEdit(true);
      setMaker(true);
      setChecker(false);
    } else if (checker_update_result && !maker_update_result) {
      setCanEdit(false);
      setChecker(true);
      setMaker(false);
    } else if (maker_update_result && checker_update_result) {
      setCanEdit(true);
      setMaker(true);
      setChecker(true);
    } else {
      setCanEdit(false);
      setMaker(false);
      setChecker(false);
    }
  }, [id, data.budget]);

  useEffect(() => {
    const total = data.budget + (data.addAdditionalBudget || 0);
    setData((prevData) => ({
      ...prevData,
      totalBudget: total,
    }));
  }, [data.budget, data.addAdditionalBudget]); // Only trigger when these two change

  // Handle the budget input change
  // const handleBudgetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value === '' ? 0 : Number(e.target.value);
  //   // Update budget value directly in data state
  //   setData((prevData) => ({
  //     ...prevData,
  //     budget: value,
  //   }));
  // };

  return (
    <>
      {setReject && (
        <ModalDialog
          show={reject}
          title={"Cost Center Status: Reject"}
          onClose={() => {
            setReject(false);
            setData({
              ...data,
              approvalStatus: MasterCostCenterStatus.pendingForApproval,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to Reject?
            </h2>
            <div className="flex flex-col gap-1">
              <label className="text-sm text-gray-500">Reason *</label>
              <textarea
                value={data.remarks}
                onChange={(e) => {
                  setData({ ...data, remarks: e.target.value });
                }}
                className="w-full px-3 py-2 border rounded-md"
                rows={4}
              ></textarea>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setReject(false);
                  setData({
                    ...data,
                    approvalStatus: MasterCostCenterStatus.pendingForApproval,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => {
                  CostCenterApprovalStatus(MasterCostCenterStatus.rejected);
                  setData({
                    ...data,
                    approvalStatus: MasterCostCenterStatus.rejected,
                  });
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      {setApproval && (
        <ModalDialog
          show={approval}
          title={"Cost Center Status: Approve"}
          onClose={() => {
            setApproval(false);
            setData({
              ...data,
              approvalStatus: MasterCostCenterStatus.pendingForApproval,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to Approve?
            </h2>

            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setApproval(false);
                  setData({
                    ...data,
                    approvalStatus: MasterCostCenterStatus.pendingForApproval,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => {
                  CostCenterApprovalStatus(MasterCostCenterStatus.approved);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}

      <div className="flex justify-between items-end my-1">
        <h1 className="flex text-xl font-bold border-l-4 border-myPrimaryColor mx-5 pl-2">
          Cost Center {id ? "Update" : "Create"}
        </h1>
        <div className="flex justify-end   text-sm items-center gap-1 ">
          {" "}
          <div className="bg-white rounded w-52 flex gap-1"></div>
        </div>
      </div>
      <div className="mb-10">
        <div className="p-5 m-5 flex flex-col gap-5 w-3/4 shadow bg-white rounded-lg">
          <div className="flex flex-col items-start text-sm">
            <label className="font-semibold w-full" htmlFor="name">
              Cost Center Name*
            </label>
            <input
              placeholder="Enter Name"
              className="p-1 rounded focus:outline-none w-full border"
              id="name"
              name="name"
              value={data.name || ""}
              required
              type="text"
              onChange={(e) => setData({ ...data, name: e.target.value })}
            />
          </div>

          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="desc">
              Description
            </label>
            <textarea
              placeholder="Short description"
              className="p-1 rounded focus:outline-none w-full border"
              id="description"
              name="description"
              value={data.description || ""}
              onChange={(e) =>
                setData((d) => ({ ...d, description: e.target.value }))
              }
            ></textarea>
          </div>

          <div className="grid grid-cols-3 gap-5 items-end">
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="type">
                From Month*
              </label>
              <input
                type="date"
                className="border px-7 py-1 rounded-md w-full"
                value={
                  data?.fromMonth
                    ? moment(data?.fromMonth).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                  const date = selectedDate
                    ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                    : undefined; // Reset if no date is selected

                  // Update the paymentRequest state with the selected date
                  setData((pv) => ({
                    ...pv,
                    fromMonth: date!, // Save the selected date in state
                  }));
                }}
              />
            </div>
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="type">
                To Month*
              </label>
              <input
                type="date"
                className="border px-7 py-1 rounded-md w-full"
                value={
                  data?.toMonth
                    ? moment(data?.toMonth).format("YYYY-MM-DD")
                    : ""
                }
                onChange={(e) => {
                  const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                  const date = selectedDate
                    ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                    : undefined; // Reset if no date is selected

                  // Update the paymentRequest state with the selected date
                  setData((pv) => ({
                    ...pv,
                    toMonth: date!, // Save the selected date in state
                  }));
                }}
              />
            </div>
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="type">
                Period*
              </label>
              <input
                type="number"
                className="font-semibold w-full border-2 rounded-md p-1"
                value={periodInMonths}
                // onChange={(e) => setData({ ...data, period: periodInMonths})}
                readOnly
              />
            </div>
          </div>
          <div className="flex flex-col items-start text-sm">
            <label className="font-semibold w-full" htmlFor="name">
              Budget*
            </label>
            <input
              placeholder="Enter budget"
              className="p-1 rounded focus:outline-none w-1/4 border"
              id="budget"
              name="budget"
              value={data.budget || ""} // Correctly bind the value to data.budget
              required
              type="number"
              onChange={(e) => {
                // Get the new value from the input
                const newValue =
                  e.target.value === "" ? 0 : Number(e.target.value);

                // Correctly update the budget field in the data state
                setData((prevData) => ({
                  ...prevData,
                  budget: newValue, // Update budget correctly
                }));
              }}
            />
          </div>
          <div className="flex flex-row gap-4">
            {id && (
              <div className="flex flex-row gap-4">
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Additional Budget*
                  </label>
                  <input
                    placeholder="budget"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="add_budget"
                    name="add_budget"
                    value={data.addAdditionalBudget || ""}
                    required
                    type="number"
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      setData({
                        ...data,
                        addAdditionalBudget: value,
                        duplicateAdditionalBudget: value,  // Ensure both values are synced
                      });
                    }}
                  />
                  <span className="mt-2">
                    <label className="text-green-500 w-full">
                      Approved add Amounts:
                    </label>
                    <p className="text-sm">
                      {data.additionalFunds
                        ?.map((fund, index) => fund.fund_amount)
                        .join(", ")}
                    </p>
                  </span>
                </div>
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Last Approved Budget
                  </label>
                  <input
                    placeholder="budget"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="add_budget"
                    name="add_budget"
                    value={data.lastApprovedBudget || ""}
                    required
                    type="number"
                    onChange={(e) =>
                      setData({
                        ...data,
                        lastApprovedBudget: Number(e.target.value),
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex flex-col items-start text-sm">
            <label className="font-semibold w-full" htmlFor="name">
              Total Budget*
            </label>
            <input
              placeholder="total budget"
              className="p-1 rounded focus:outline-none w-1/4 border"
              id="tot_budget"
              name="tot_budget"
              value={data.totalBudget || ""}
              required
              type="number"
              readOnly
            />
          </div>
          <div className="flex flex-row gap-5 w-full">
            <div className="w-1/2">
              <div>
                <label className="text-sm font-bold">
                  Balance is calculated based on the last approved budget.
                </label>
              </div>
              <div className="bg-gray-100 p-5 rounded-md">
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Spent
                  </label>
                  <input
                    placeholder=""
                    className="p-1 rounded focus:outline-none w-full border"
                    id="spent"
                    name="spent"
                    value={data.spent || ""}
                    required
                    type="number"
                    readOnly
                  />
                </div>
                <div className="flex flex-col items-start text-sm">
                  <label className="font-semibold w-full" htmlFor="name">
                    Balance{" "}
                  </label>
                  <input
                    placeholder=""
                    className="p-1 rounded focus:outline-none w-full border"
                    id="balance"
                    name="balance"
                    value={data.lastApprovedBudget - data.spent || ""}
                    required
                    type="number"
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start text-sm w-1/2">
              <label className="font-semibold w-full" htmlFor="desc">
                Notes/Remarks
              </label>
              <textarea
                placeholder="remarks"
                className="p-1 mt-1 rounded focus:outline-none w-full border"
                id="remarks"
                name="remarks"
                rows={6}
                value={data.remarks || ""}
                onChange={(e) =>
                  setData((d) => ({ ...d, remarks: e.target.value }))
                }
              ></textarea>
            </div>
          </div>
        </div>
        <div className="mb-10">
          {maker && (
            <div className="flex justify-center gap-5 mt-5 mb-2">
              <button
                onClick={onSubmit}
                type="button"
                className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
              >
                Submit
              </button>
              <button
                onClick={() => {
                  navaigation.push(urls.procurement_masters_cost_center);
                }}
                type="button"
                className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
              >
                Quit
              </button>
            </div>
          )}

          {id &&
            checker &&
            data.approvalStatus !== MasterCostCenterStatus.approved && (
              <div className="flex gap-4 justify-center my-5">
                <button
                  onClick={() => {
                    setApproval(true);
                    setData({
                      ...data,
                      approvalStatus: MasterCostCenterStatus.approved,
                    });
                  }}
                  className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                >
                  Approve
                </button>
                <button
                  onClick={() => {
                    setReject(true);
                    setData({
                      ...data,
                      approvalStatus: MasterCostCenterStatus.rejected,
                    });
                  }}
                  className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                >
                  Reject
                </button>
                <button
                  onClick={() => {
                    navaigation.push(urls.procurement_masters_cost_center);
                  }}
                  className="bg-green-500 text-white justify-center px-4 py-2 rounded-md hover:bg-rgreened-600"
                >
                  Quit
                </button>
              </div>
            )}
        </div>
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};
export default CostCenterCreateScreen;
