import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  CostCenter,
  CostCenterCloseStatus,
  costCenterDefaultValue,
  MasterCostCenterStatus,
} from "../../models/cost_center_model";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPut } from "../../../../service/api_client";
import moment from "moment";
import LoadingWidget from "../../../../context_providers/modal/loader";
import { ACTION, MODULE } from "../../../../utils/enums";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import ModalDialog from "../../../../ui/new_components/common/modal";
import { urls } from "../../../../utils/urls";
import ApprovalBottomSheetWidget from "./approvalBottomSheet";

interface CostCenterCreateScreenProps {
  data?: CostCenter;
  onClose: (id: CostCenter) => void;
}

const CostCenterViewScreen: FunctionComponent<CostCenterCreateScreenProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const navaigation = useHistory();
  const [checker, setChecker] = useState(false);
  const [maker, setMaker] = useState(false);
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/cost_center`;
  const [loading, setLoading] = useState(false);
  const [approval, setApproval] = useState(false);
  const [reject, setReject] = useState(false);
  const [close, setClose] = useState(false);
  const { showToast } = useToast();
  const [data, setData] = useState<CostCenter>({
    ...(props.data || { ...costCenterDefaultValue }),
  });
  const { user, isAuthorised } = useAuth();

  useEffect(() => {
    if (id) getData(id);
  }, []);

  useEffect(() => {
    const maker_permission_result = isAuthorised({
      module: MODULE.cost_center_maker,
      action: ACTION.CREATE,
    });
    const checker_permission_result = isAuthorised({
      module: MODULE.cost_center_checker,
      action: ACTION.UPDATE,
    });
    setChecker(checker_permission_result);
    setMaker(maker_permission_result);
    console.log("Checker", checker_permission_result);
    console.log("Maker", maker_permission_result);
  }, [isAuthorised]); // Separate call for permission check

  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    const total = data.budget + (data.addAdditionalBudget || 0);
    setData((prevData) => ({
      ...prevData,
      totalBudget: total,
    }));
  }, [data.budget, data.addAdditionalBudget]); // Only trigger when these two change

  const CostCenterApprovalStatus = async (status: string) => {
    if (status === MasterCostCenterStatus.rejected && !data.remarks) {
      showToast({
        type: ToastType.error,
        text: "Need Cost Centr Reject Remarks.",
      });
      return;
    } else {
      // console.log("Sending status:", status);
      try {
        const res = await fetchPut(`${url}/${data.id}/approval`, data);
        showToast({
          type: ToastType.success,
          text: `Cost Center Status: ${status}`,
        });
        if (res.success) {
          getData(res.data);
        }
        navaigation.push(urls.procurement_masters_cost_center);
        // Your existing success/error logic...
      } catch (error) {
        console.error("Error while updating cost center status:", error);
        showToast({
          type: ToastType.error,
          text: "Failed to update status. Try again later.",
        });
      }
    }
  };

  const submit = async ({
    approval_status,
    level,
  }: {
    approval_status: string;
    level: string;
  }) => {
    let reason = "";
    if (
      approval_status === MasterCostCenterStatus.rejected ||
      approval_status === MasterCostCenterStatus.pendingForApproval
    ) {
      reason =
        window.prompt(
          approval_status === MasterCostCenterStatus.rejected
            ? "What's the reason for Rejection ?"
            : "What's the reason for Pending ?"
        ) || "";
      reason = reason.trim();
      if (!reason) return;
    }
    if (
      !window.confirm(
        `Are you sure to ${approval_status} the ${level} approval ?`
      )
    )
      return;

    setLoading(true);
    const res = await fetchPut(url + "/" + data.id + "/approval", {
      approval_status,
      level,
      id: data.id,
      reason,
      data,
    });

    if (res.success) {
      setData((o) => ({ ...o, ...res.data }));
      showToast({ type: ToastType.success, text: res.message });
      getData(id);
      navaigation.push(urls.procurement_masters_cost_center);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const CostCenterClosedStatus = async (status: string) => {
    // console.log("Sending status:", status);
    try {
      await fetchPut(`${url}/${data.id}/close`, data);
      showToast({
        type: ToastType.success,
        text: `Cost Center Status: ${status}`,
      });
      navaigation.push(urls.procurement_masters_cost_center);
      // Your existing success/error logic...
    } catch (error) {
      console.error("Error while updating cost center status:", error);
      showToast({
        type: ToastType.error,
        text: "Failed to update status. Try again later.",
      });
    }
  };

  return (
    <>
      {setReject && (
        <ModalDialog
          show={reject}
          title={"Cost Center Status: Reject"}
          onClose={() => {
            setReject(false);
            setData({
              ...data,
              approvalStatus: MasterCostCenterStatus.pendingForApproval,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to Reject?
            </h2>
            <div className="flex flex-col gap-1">
              <label className="text-sm text-gray-500">Reason *</label>
              <textarea
                value={data.remarks}
                onChange={(e) => {
                  setData({ ...data, remarks: e.target.value });
                }}
                className="w-full px-3 py-2 border rounded-md"
                rows={4}
              ></textarea>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setReject(false);
                  setData({
                    ...data,
                    approvalStatus: MasterCostCenterStatus.pendingForApproval,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => {
                  CostCenterApprovalStatus(MasterCostCenterStatus.rejected);
                  setData({
                    ...data,
                    approvalStatus: MasterCostCenterStatus.rejected,
                  });
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      {setApproval && (
        <ModalDialog
          show={approval}
          title={"Cost Center Status: Approve"}
          onClose={() => {
            setApproval(false);
            setData({
              ...data,
              approvalStatus: MasterCostCenterStatus.pendingForApproval,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to Approve?
            </h2>

            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setApproval(false);
                  setData({
                    ...data,
                    approvalStatus: MasterCostCenterStatus.pendingForApproval,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => {
                  CostCenterApprovalStatus(MasterCostCenterStatus.approved);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}

      {close && (
        <ModalDialog
          show={close}
          title={"Cost Center Status: Closed"}
          onClose={() => {
            setClose(false);
            setData({
              ...data,
              costCenterStatus: CostCenterCloseStatus.closed,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to close the cost center?
            </h2>

            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setClose(false);
                  setData({
                    ...data,
                    costCenterStatus: CostCenterCloseStatus.closed,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => {
                  CostCenterClosedStatus(CostCenterCloseStatus.closed);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}

      <div className="flex justify-between items-end my-1">
        <h1 className="flex text-xl font-bold border-l-4 border-myPrimaryColor mx-5 pl-2">
          Cost Center View
        </h1>
        <div className="flex justify-end   text-sm items-center gap-1 ">
          {" "}
          <div className="bg-white rounded w-52 flex gap-1"></div>
        </div>
      </div>
      <div className="p-5 m-5 flex flex-col gap-5 w-3/4 shadow bg-white rounded-lg">
        <div className="flex flex-col items-start text-sm">
          <label className="font-semibold w-full" htmlFor="name">
            Cost Center Name*
          </label>
          <input
            placeholder="Enter Name"
            className="p-1 rounded focus:outline-none w-1/2 border"
            id="name"
            name="name"
            value={data.name || ""}
            required
            type="text"
            readOnly
          />
        </div>

        <div className="flex flex-col items-start text-sm w-full">
          <label className="font-semibold w-full" htmlFor="desc">
            Description
          </label>
          <textarea
            placeholder="Short description"
            className="p-1 rounded focus:outline-none w-full border"
            id="description"
            name="description"
            value={data.description || ""}
            readOnly
          ></textarea>
        </div>

        <div className="grid grid-cols-3 gap-5 items-end">
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="type">
              From Month*
            </label>
            <input
              type="date"
              className="border px-7 py-1 rounded-md w-full"
              value={
                data?.fromMonth
                  ? moment(data?.fromMonth).format("YYYY-MM-DD")
                  : ""
              }
            />
          </div>
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="type">
              To Month*
            </label>
            <input
              type="date"
              className="border px-7 py-1 rounded-md w-full"
              value={
                data?.toMonth ? moment(data?.toMonth).format("YYYY-MM-DD") : ""
              }
            />
          </div>
          <div className="flex flex-col items-start text-sm w-full">
            <label className="font-semibold w-full" htmlFor="type">
              Period*
            </label>
            <input
              type="number"
              className="font-semibold w-full border-2 rounded-md p-1"
              value={data.period}
              // onChange={(e) => setData({ ...data, period: periodInMonths})}
              readOnly
            />
          </div>
        </div>
        <div className="flex flex-col items-start text-sm">
          <label className="font-semibold w-full" htmlFor="name">
            Budget*
          </label>
          <input
            placeholder="Enter budget"
            className="p-1 rounded focus:outline-none w-1/2 border"
            id="budget"
            name="budget"
            value={data.budget || ""}
            required
            type="number"
            readOnly
          />
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex flex-col items-start text-sm">
            <label className="font-semibold w-full" htmlFor="name">
              Additional Budget*
            </label>
            <input
              placeholder="budget"
              className="p-1 rounded focus:outline-none w-full border"
              id="add_budget"
              name="add_budget"
              value={data.addAdditionalBudget || ""}
              required
              type="number"
              readOnly
            />

            <span className="mt-2">
              <label className="text-green-500 w-full">
                Approved add Amounts:
              </label>
              <p className="text-sm">
                {data.additionalFunds
                  ?.map((fund, index) => fund.fund_amount)
                  .join(", ")}
              </p>
            </span>
          </div>
          <div className="flex flex-col items-start text-sm">
            <label className="font-semibold w-full" htmlFor="name">
              Last Approved Budget
            </label>
            <input
              placeholder="budget"
              className="p-1 rounded focus:outline-none w-full border"
              id="add_budget"
              name="add_budget"
              value={data.lastApprovedBudget || ""}
              required
              type="number"
              readOnly
            />
          </div>
        </div>
        <div className="flex flex-col items-start text-sm">
          <label className="font-semibold w-full" htmlFor="name">
            Total Budget*
          </label>
          <input
            placeholder="total budget"
            className="p-1 rounded focus:outline-none w-1/2 border"
            id="tot_budget"
            name="tot_budget"
            value={data.totalBudget || ""}
            required
            type="number"
            readOnly
          />
        </div>
        <div>
          <div>
            <label className="text-sm font-bold">
              Balance is calculated based on the last approved budget.
            </label>
          </div>
          <div className="bg-gray-100 w-1/2 p-5 rounded-md">
            <div className="flex flex-col items-start text-sm">
              <label className="font-semibold w-full" htmlFor="name">
                Spent
              </label>
              <input
                placeholder=""
                className="p-1 rounded focus:outline-none w-full border"
                id="spent"
                name="spent"
                value={data.spent || ""}
                required
                type="number"
                readOnly
              />
            </div>
            <div className="flex flex-col items-start text-sm">
              <label className="font-semibold w-full" htmlFor="name">
                Balance{" "}
              </label>
              <input
                placeholder=""
                className="p-1 rounded focus:outline-none w-full border"
                id="balance"
                name="balance"
                value={data.lastApprovedBudget - data.spent || ""}
                required
                type="number"
                readOnly
              />
            </div>
          </div>
        </div>
        {/* {checker && data.approvalStatus !== MasterCostCenterStatus.approved && (
          <div className="flex gap-4 justify-center">
            <button
              onClick={() => {
                setApproval(true);
                setData({
                  ...data,
                  approvalStatus: MasterCostCenterStatus.approved,
                });
              }}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              Approve
            </button>
            <button
              onClick={() => {
                setReject(true);
                setData({
                  ...data,
                  approvalStatus: MasterCostCenterStatus.rejected,
                });
              }}
              className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
            >
              Reject
            </button>
            <button
              onClick={() => {
                navaigation.push(urls.procurement_masters_cost_center);
              }}
              className="bg-green-500 text-white justify-center px-4 py-2 rounded-md hover:bg-rgreened-600"
            >
              Quit
            </button>
          </div>
        )} */}
        {checker && data.approvalStatus !== MasterCostCenterStatus.approved && (
          <div className="sticky bottom-0 right-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-5 border">
            {" "}
            <h3 className="text-sm font-semibold">Approval status</h3>
            <ApprovalBottomSheetWidget data={data} onSubmit={submit} />
          </div>
        )}
      </div>
      {checker &&
        data.approvalStatus === MasterCostCenterStatus.approved &&
        data.costCenterStatus !== CostCenterCloseStatus.closed && (
          <div className="flex gap-4 justify-center">
            <button
              onClick={() => {
                setClose(true);
                setData({
                  ...data,
                  costCenterStatus: CostCenterCloseStatus.closed,
                });
              }}
              className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
            >
              Close
            </button>

            <button
              onClick={() => {
                navaigation.push(urls.procurement_masters_cost_center);
              }}
              className="bg-green-500 text-white justify-center px-4 py-2 rounded-md hover:bg-rgreened-600"
            >
              Quit
            </button>
          </div>
        )}
      {maker && (
        <div className="flex justify-center gap-4">
          <button
            onClick={() => {
              navaigation.push(urls.procurement_masters_cost_center);
            }}
            className="bg-green-500 text-white justify-center px-4 py-2 rounded-md hover:bg-rgreened-600"
          >
            Quit
          </button>
        </div>
      )}
      {/* </div> */}

      {loading && <LoadingWidget />}
    </>
  );
};
export default CostCenterViewScreen;
