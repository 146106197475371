import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPost, fetchPut } from "../../../service/api_client";

import {
  WAREHOUSE_TYPE,
  warehouseDefaultValue,
  WarehouseModel,
} from "../models/warehouse_model";
import { urls } from "../../../utils/urls";
import EmployeeSearchWidget from "../../../ui/new_components/emp_search_widget";
import PartySearchField from "../../accounts/parties/party_search_field";
import { Country, State, City } from "country-state-city";
// Define types for state and city
type CountryType = {
  isoCode: string;
  name: string;
};

type StateType = {
  isoCode: string;
  name: string;
};

type CityType = {
  id: string;
  name: string;
};

interface InventoryBasketCreateScreenProps {
  edit: boolean;
  onClose: (id?: WarehouseModel) => void;
  data?: WarehouseModel;
}

const InventoryBasketCreateScreen: FunctionComponent<
  InventoryBasketCreateScreenProps
> = (props) => {
  const isUpdatePage = useRouteMatch(
    `${urls.procurement_masters_warehouse}/update/:id`
  );
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/warehouse_type`;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { edit } = props;
  const [editable, setEditable] = useState(false);
  const [data, setData] = useState<WarehouseModel>(
    props.data || ({} as WarehouseModel)
  );
  const navaigation = useHistory();
  const defaultWarehouseModel: WarehouseModel = warehouseDefaultValue;

  const handleOptionChange = (value: WarehouseModel["type"]) => {
    setData((pv) => ({ ...pv, type: value }));
  };

  // States for countries, states, and cities
  const [countries, setCountries] = useState<CountryType[]>([]);
  const [states, setStates] = useState<StateType[]>([]);
  const [cities, setCities] = useState<CityType[]>([]);

  // Selected values
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");
  console.log("selectedCountry:", selectedCountry);
  // Load countries on component mount
  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  // Load states when a country is selected
  useEffect(() => {
    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
      const allStates = State.getStatesOfCountry(selectedCountry);
      setStates(allStates);
      setSelectedState(""); // Reset state and city when country changes
      setSelectedCity("");
    }
  }, [selectedCountry]);

  // Load cities when a state is selected
  useEffect(() => {
    if (selectedState) {
      const allCities = City.getCitiesOfState(selectedCountry, selectedState);
      // Ensure each city has an 'id' field (e.g., use the city's name or other unique value)
      const citiesWithId = allCities.map((city) => ({
        id: city.name, // Use 'name' or any other property to create an 'id'
        name: city.name,
      }));
      setCities(citiesWithId);
      setSelectedCity("");
    }
  }, [selectedState, selectedCountry]);

  // Handle changes in country selection
  const handleCountryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const country = e.target.value;
    setSelectedCountry(country);
    setData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address!,
        country: country,
      },
    }));
  };

  // Handle changes in state selection
  const handleStateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const state = e.target.value;
    setSelectedState(state);
    setData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address!,
        state: state,
      },
    }));
  };

  // Handle changes in city selection
  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const city = e.target.value;
    setSelectedCity(city);
    setData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address!,
        city: city,
      },
    }));
  };

  const submit = async () => {
    try {
      console.log(data);
      if (!data.name || !data.type) {
        showToast({
          type: ToastType.error,
          text: "Please fill all the required details",
        });
        return;
      }

      setLoading(true);

      const res = data.id
        ? await fetchPut(`${url}/${data.id}`, data)
        : await fetchPost(url, data);
      console.log({ res });
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!data.id) {
          setData(defaultWarehouseModel);
          // setShowSucess(true);
        }
        setData((prevData) => ({
          ...prevData,
          ...res.data, // Update with the response data
        }));
        if (props.onClose) props.onClose(res.data);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setEditable(edit);
    if (id && isUpdatePage) {
      getData(id);
    }
  }, []);

  useEffect(() => {
    // Initialize form with existing data if editing
    if (edit && props.data) {
      setData(props.data);
    }
  }, [edit, props.data]);

  const getData = async (id: string) => {
    try {
      setLoading(true);
      const res = await fetchGet(`${url}/${id}`);
      if (res.success) {
        console.log(res.data);
        const updatedData = { ...res.data, type: res.data.type }; // Update type property
        setData(updatedData); // Update state with merged data
      } else {
        console.log("Invalid");
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 m-5 gap-2 select-none text-sm">
        <h1 className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
          Warehouse {id ? `Update: ${data.id}` : "create"}
        </h1>
        <div className="grid grid-cols-1 w-1/2 justify-center m-5 gap-2 select-none">
          <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white rounded-lg">
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="name">
                Warehouse Name*
              </label>
              <input
                placeholder="Enter Name"
                className="p-1 rounded focus:outline-none w-full border"
                id="name"
                name="name"
                value={data.name || ""}
                required
                type="text"
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>
            <div className="">
              {/* <h4 className="font-bold text-center mb-5">Address </h4> */}
              <div className="grid grid-cols-2 gap-5 items-end">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    Address Line 1
                  </label>
                  <textarea
                    placeholder="Enter address line 1"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="line1"
                    name="line1"
                    value={data.address?.line1 || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          line1: e.target.value,
                        },
                      }))
                    }
                  />
                </div>

                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line2">
                    Address Line 2
                  </label>
                  <textarea
                    placeholder="Enter address line 1"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="line2"
                    name="line2"
                    value={data.address?.line2 || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          line2: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-5 items-end">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="country">
                    Country*
                  </label>
                  <select
                    id="country"
                    name="country"
                    className="p-1 rounded focus:outline-none w-full border"
                    value={data.address?.country || ""}
                    onChange={handleCountryChange}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="state">
                    State*
                  </label>
                  <select
                    value={data.address?.state || ""}
                    className="p-1 rounded focus:outline-none w-full border"
                    id="state"
                    name="state"
                    onChange={handleStateChange}
                    disabled={!selectedCountry}
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-5 items-end">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="city">
                    City*
                  </label>
                  <select
                    className="p-1 rounded focus:outline-none w-full border"
                    id="city"
                    name="city"
                    value={data.address?.city || ""}
                    onChange={handleCityChange}
                    disabled={!selectedState}
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city.id} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="pincode">
                    Pincode*
                  </label>
                  <input
                    placeholder="Enter pincode"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="pincode"
                    name="pincode"
                    required
                    value={data.address?.pincode || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          pincode: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-5 items-end">
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="type">
                  Type*
                </label>
                <select
                  value={data.type}
                  onChange={(e) => {
                    handleOptionChange(
                      e.target.value as WarehouseModel["type"]
                    );
                    setData({
                      ...data,
                      type: e.target.value as WarehouseModel["type"],
                    });
                  }}
                  className="p-1 rounded focus:outline-none w-full border"
                >
                  <option value="">select</option>
                  {Object.values(WAREHOUSE_TYPE).map((f: string) => (
                    <option key={f} value={f}>
                      {f}
                    </option>
                  ))}
                </select>
              </div>
              {data.type === "internal" && (
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    User Name/SPOC *
                  </label>
                  <EmployeeSearchWidget
                    withBtn={false}
                    onSelect={(emp) => {
                      setData((pv) => {
                        return {
                          ...pv!,
                          spoc: {
                            ...pv.spoc,
                            uid: emp.uid,
                            contact_name:
                              emp.first_name + " " + (emp.last_name ?? ""),
                            contact_mobile: emp.mobile,
                            contact_email: emp.email,
                            department: emp.emp_profile?.department,
                          },
                        };
                      });
                    }}
                    emp={
                      data.spoc?.uid
                        ? {
                            uid: data.spoc.uid!,
                            name: data.spoc.contact_name,
                          }
                        : undefined
                    }
                  />
                </div>
              )}
              {data.type === "external" && (
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    User Name/SPOC *
                  </label>
                  <PartySearchField
                    show_add={true}
                    placeholder={data.spoc?.contact_name}
                    onSelect={(e) => {
                      setData((prevData) => ({
                        ...prevData,
                        spoc: {
                          ...prevData.spoc!,
                          contact_name: e.name,
                          contact_email: e.mail_detail.contact_email,
                          contact_mobile: e.mail_detail.contact_mobile,
                        },
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {/* {data.type === "external" && (
            <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
              <div className="">
                <h4 className="font-bold text-center mb-5">
                  SPOC Details to Track{" "}
                </h4>
                <div className="grid grid-cols-2 gap-5 items-end">
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      User Name
                    </label>
                    <PartySearchField
                      show_add={true}
                      placeholder={data.spoc?.contact_name}
                      onSelect={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_name: e.name,
                            contact_email: e.mail_detail.contact_email,
                            contact_mobile: e.mail_detail.contact_mobile,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Phone Number
                    </label>
                    <input
                      placeholder="phone number"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="transitPhoneNumber"
                      name="transitPhoneNumber"
                      value={data.spoc?.contact_mobile}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_mobile: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Email{" "}
                    </label>
                    <input
                      placeholder="email"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="email"
                      name="email"
                      value={data.spoc?.contact_email}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_email: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {/* Address Fields */}
        </div>
        <div className="flex justify-center gap-5 mt-5 mb-2">
          <button
            onClick={submit}
            type="button"
            className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
          >
            Submit
          </button>
          <button
            onClick={() => {
              navaigation.push(urls.procurement_masters_warehouse);
            }}
            type="button"
            className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
          >
            Quit
          </button>
        </div>
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default InventoryBasketCreateScreen;
