import { RouteParam } from "../../../routes";
import { ACTION, MODULE } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import GstCreateScreen from "../gst/gst_create_screen";
import GstDetailScreen from "../gst/gst_detail_screen";
import GstListScreen from "../gst/gst_list_screen";
import GstUpdateScreen from "../gst/gst_update_screen";

const gst_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_gst,
    component: GstListScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_gst + "/create",
    component: GstCreateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_gst + "/update/:id",
    component: GstUpdateScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.procurement_masters_gst + "/detail-screen/:id",
    component: GstDetailScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  //   {
  //     isPrivate: true,
  //     fullScreen: false,
  //     exact: true,
  //     path: urls.procurement_masters_party + "/create",
  //     component: PartyCreateScreen,
  //     module: MODULE.procurement_master,
  //     main_module: MODULE.procurement_panel,
  //     action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
  //     operator: "or",
  //   },
  //   {
  //     isPrivate: true,
  //     fullScreen: false,
  //     exact: true,
  //     path: urls.procurement_masters_party + "/update/:id",
  //     component: PartyCreateScreen,
  //     module: MODULE.procurement_master,
  //     main_module: MODULE.procurement_panel,
  //     action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
  //     operator: "or",
  //   },
];

export default gst_routes;
