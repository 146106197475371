import { BaseModel } from "../../../service/models/base";
import { AddressType } from "../../../utils/enums";
import { SpocModel } from "../../inventory/models/warehouse_model";
import { AttachmentModel } from "../../purchase_order/model/purchase_order_model";
export interface BankDetail {
  account_holder_name: string;
  bank_name: string;
  bank_address?: string;
  branch?: string;
  ac_no: string;
  ifsc?: string;
  swift_code?: string;
  micr_code?: string;
}

export interface AddressModel {
  id: string;
  type: string;
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}
export interface MailDetail {
  contact_name: string;
  contact_mobile: string;
  contact_email: string;
  contact_email_ceo_director?: string;
  contact_email_account_spoc?: string;
  contact_email_contact_person: string;
  designation?: string;
  name_ceo_director?: string;
  name_account_spoc?: string;
  website?: string;
  contact_mobile_contact_person: string;
  contact_detail_ceo_director?: string;
  contact_mobile_accounts_spoc?: string;
  alternative_mobile?: string;
  alternative_email?: string;
  address: AddressModel;
}

export type CountryType = {
  isoCode: string;
  name: string;
};

export type StateType = {
  isoCode: string;
  name: string;
};

export type CityType = {
  id: string;
  name: string;
};

export interface TaxDetailV2 {
  // type: string;
  pan: string;
  reg_type:
    | "Registered (Regular)"
    | "Registered (Composition)"
    | "Unregistered";
  gst_no: string[];
}

export interface TaxDetail {
  // type: string;
  pan: string;
  reg_type: "Unkown" | "Composition" | "Consumer" | "Regular" | "Unregistered";
  gst_no: string;
}
export const taxRegType = {
  composition: "Registered (Composition)",
  regular: "Registered (Regular)",
  unregistered: "Unregistered",
};

export const partyTypes = {
  buyer: "Buyer",
  seller: "Seller",
};

export const currencies = {
  rupee: {
    formal_name: "Rupee",
    short_name: "INR",
    symbol: "\u20b9",
  },
  usd: {
    formal_name: "US Dollar",
    short_name: "USD",
    symbol: "\u0024",
  },
};
export interface Currency {
  formal_name: string;
  short_name: string;
  symbol: string;
}

export interface MSMERegistration {
  msme_category?: string;
  msme_registration_number?: string;
}

export const PartyStatusMap = {
  APPROVED: "Approved",
  SAVED_DRAFT: "Saved Draft",
  UPDATE_PENDING: "Update Pending",
  APPROVE_PENDING: "Approve Pending",
  REJECTED: "Rejected",
} as const;

export type PartyStatus = (typeof PartyStatusMap)[keyof typeof PartyStatusMap];

export interface PartyFilter {
  id: string;
  all: boolean;
  name: string;
  type: string;
  gst_no: string;
  city: string;
  state: string;
  status: string;
  page: number;
  count: number;
}
export const PartyType = ["Service", "Product", "Both"];

export const defaultPartyFilter: PartyFilter = {
  id: "",
  all: false,
  name: "",
  type: "",
  gst_no: "",
  city: "",
  state: "",
  status: "", // Default status
  page: 1,
  count: 20,
};

export interface PartyModel extends BaseModel {
  name: string;
  type: string;
  // loc_type: string;
  // category: string;
  // sub_category: string;
  // other_category?: string;
  currency?: Currency;
  remarks?: string;
  mail_detail: MailDetail;
  msme_registration_applicable: boolean;
  msme_registration: MSMERegistration;
  bank_detail: BankDetail;
  tax_detail: TaxDetailV2;
  status?: PartyStatus;
  adhaar_number?: string;
  cin_llpin_number?: string;
  date_of_commencement?: string;
  credit_period_day: number;
  attachments: AttachmentModel[];
}

export const addressDefaultValue: AddressModel = {
  id: "",
  type: AddressType.OFFICE,
  line1: "",
  city: "",
  state: "",
  country: "",
  pincode: "",
};

export const taxDetail: TaxDetail = {
  pan: "",
  reg_type: "Unkown", // Assuming "Unkown" is a typo and should be "Unknown"
  gst_no: "",
};

export const default_mail_detail: MailDetail = {
  contact_name: "",
  contact_mobile: "",
  contact_email: "",
  address: addressDefaultValue,
  contact_email_contact_person: "",
  contact_mobile_contact_person: "",
};

const bank_detail: BankDetail = {
  ac_no: "",
  ifsc: "",
  bank_name: "",
  branch: "",
  account_holder_name: "",
};

const tax_detailV2: TaxDetailV2 = {
  // type: "",
  pan: "",
  reg_type: "Registered (Composition)",
  gst_no: [],
};

const tax_detail: TaxDetail = {
  // type: "",
  pan: "",
  reg_type: "Unkown",
  gst_no: "",
};
export const defaulPartyValue: PartyModel = {
  name: "",
  type: "",
  // loc_type: "Domestic",
  mail_detail: default_mail_detail,
  bank_detail: bank_detail,
  tax_detail: tax_detailV2,
  credit_period_day: 0,
  // category: "",
  // sub_category: "",
  attachments: [],
  msme_registration_applicable: false,
  msme_registration: {},
};
