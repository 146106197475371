import { mdiCheck, mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import { numberRegEx } from "../../../../../utils/constant";
import ItemSearchField from "../../../items/item_search_field";
import {
  itemMovementDefaultValue,
  ItemMovementModel,
  ItemStatus,
  LostItem,
} from "../../../models/inventory_voucher";
// import ProjectSearchField from "../../../../purchase_order/projects/project_master/project_search_field";
import WarehouseSearchField from "../../../warehouse/warehouse_search_field";
import { WAREHOUSE_TYPE } from "../../../models/warehouse_model";
import randomstring from "randomstring";
import moment from "moment";

export const generate_id = (
  length = 10,
  charset: "alphabetic" | "numeric" | "alphanumeric" = "alphanumeric"
) => {
  return randomstring.generate({
    length,
    charset,
  });
};

interface ItemRowProps {
  max_qty?: number;
  price?: number;
  index: number;
  item?: ItemMovementModel;
  onSubmit: (data: ItemMovementModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: ItemMovementModel) => void;
  disposalItems?: ItemMovementModel[];
  onDisposalItemsUpdate?: (disposalItems: ItemMovementModel[]) => void;
}

const ItemRowStatus: FunctionComponent<ItemRowProps> = (props) => {
  const [data, setData] = useState<ItemMovementModel>({
    ...itemMovementDefaultValue,
  });

  const { showToast } = useToast();
  const { edit, onSubmit, disposalItems } = props;
  const [editable, setEditable] = useState(false);
  const [lost, setLost] = useState<{
    lost: LostItem[];
    damaged: LostItem[];
    scrapped: LostItem[];
  }>({
    lost: [{ count: 0, id: undefined, status: ItemStatus.lost }],
    damaged: [{ count: 0, id: undefined, status: ItemStatus.damaged }],
    scrapped: [{ count: 0, id: undefined, status: ItemStatus.scrapped }],
  });

  useEffect(() => {
    setEditable(edit);
  }, [edit]);

  useEffect(() => {
    if (props.item) {
      setData((prevData) => ({
        ...prevData,
        ...props.item,
      }));

      // Set lost values from props.item if available
      if (props.item.lost) {
        const lostItems = props.item.lost;

        // Create an object to hold counts and IDs
        const lostValues = {
          lost: lostItems
            .filter((item: any) => item.status === ItemStatus.lost)
            .map((item: any) => ({
              count: item.count,
              id: item.id,
              status: item.status,
            })),
          damaged: lostItems
            .filter((item: any) => item.status === ItemStatus.damaged)
            .map((item: any) => ({
              count: item.count,
              id: item.id,
              status: item.status,
            })),
          scrapped: lostItems
            .filter((item: any) => item.status === ItemStatus.scrapped)
            .map((item: any) => ({
              count: item.count,
              id: item.id,
              status: item.status,
            })),
        };

        setLost(lostValues);
      }
    }
  }, [props.item]);

  const getProjectDisplayText = () => {
    const projects = data.projectFrom || [];
    if (projects.length === 0) return "";
    if (projects.length === 1) return projects[0];
    return `${projects[0]} + ${projects.length - 1} more`;
  };

  const sumLostUnits =
    disposalItems?.reduce((total, d) => {
      console.log(data.warehouse_id, d.warehouse_id);
      if (
        d.warehouse_id === data.warehouse_id &&
        d.item?.name === data.item?.name
      ) {
        console.log("dlost", d.lost_unit_no);
        return total + (d.lost_unit_no || 0);
      }
      return total;
    }, 0) ?? 0;

  const onDone = () => {
    const date = new Date();
    data.date = date;

    const totalLost =
      lost.lost[0].count + lost.damaged[0].count + lost.scrapped[0].count;
    if (totalLost > sumLostUnits) {
      showToast({
        type: ToastType.warning,
        text: "Please enter correct data. The lost sum should match.",
      });
      return;
    }

    data.lost = [
      {
        count: lost.lost[0]?.count || 0, // Default to 0 if lost.lost is empty
        id: lost.lost[0]?.id, // Set ID if exists, otherwise undefined
        status: ItemStatus.lost,
      },
      {
        count: lost.damaged[0]?.count || 0, // Default to 0 if damaged is empty
        id: lost.damaged[0]?.id, // Set ID if exists, otherwise undefined
        status: ItemStatus.damaged,
      },
      {
        count: lost.scrapped[0]?.count || 0, // Default to 0 if scrapped is empty
        id: lost.scrapped[0]?.id, // Set ID if exists, otherwise undefined
        status: ItemStatus.scrapped,
      },
    ];

    onSubmit(data);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          // onDone();
        }}
        className="bg-white p-1"
      >
        <div className="grid grid-cols-10 gap-1 items-center col-span-1">
          <div className="col-span-1">
            <input
              value={data?.date ? moment(data.date).format("YYYY-MM-DD") : ""}
              disabled
              type="date"
              className="text-center p-1 focus:outline-none border rounded w-full"
            />
          </div>
          <div className="col-span-1">
            <ItemSearchField
              disabled={!editable || props.disabled}
              value={data.item}
              onSelect={(d) => {
                setData((old) => ({ ...old, item: d }));
              }}
            />
          </div>
          <div className="">
            <WarehouseSearchField
              disabled
              value={data.warehouse}
              onSelect={(d) => {
                setData((old) => ({ ...old, warehouse: d }));
              }}
            />
          </div>

          <div className="col-span-1">
            <input
              value={sumLostUnits}
              onChange={(e) => {
                const val = e.target.value.trim();
                if (!val || numberRegEx.test(val)) {
                  const value = Number(val || 0);
                  setData((ol) => ({
                    ...ol,
                    unit_no: value,
                  }));
                }
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="text"
              className="text-center p-1 focus:outline-none border rounded w-full"
            />
          </div>
          <div className="col-span-1">
            <input
              value={getProjectDisplayText()}
              disabled={!editable || props.disabled}
              placeholder="project"
              type="text"
              className="text-center p-1 focus:outline-none border rounded w-full"
            />
          </div>

          <div className="col-span-1 flex items-center">
            <input
              value={lost?.lost[0]?.count} // Lost input
              onChange={(e) => {
                const value = Number(e.target.value) || 0;
                setLost((prev) => ({
                  ...prev,
                  lost: [{ ...prev.lost[0], count: value }],
                }));
              }}
              disabled={false}
              placeholder="Lost"
              type="number"
              className="text-right p-1 focus:outline-none border rounded w-full"
            />
          </div>

          <div className="col-span-1 flex items-center">
            <input
              value={lost?.damaged[0]?.count} // Damaged input
              onChange={(e) => {
                const value = Number(e.target.value) || 0;
                setLost((prev) => ({
                  ...prev,
                  damaged: [{ ...prev.damaged[0], count: value }],
                }));
              }}
              disabled={false}
              placeholder="Damaged"
              type="number"
              className="text-right p-1 focus:outline-none border rounded w-full"
            />
          </div>

          <div className="col-span-1 flex items-center">
            <input
              value={lost?.scrapped[0]?.count} // Scrapped input
              onChange={(e) => {
                const value = Number(e.target.value) || 0;
                setLost((prev) => ({
                  ...prev,
                  scrapped: [{ ...prev.scrapped[0], count: value }],
                }));
              }}
              disabled={false}
              placeholder="Scrapped"
              type="number"
              className="text-right p-1 focus:outline-none border rounded w-full"
            />
          </div>

          <div className="col-span-1">
            <input
              value={Math.round(data?.price)}
              onChange={(e) => {
                let v = 0;
                if (e.target.value && !isNaN(e.target.value as any)) {
                  v = Number(e.target.value);
                }
                setData((ol) => ({ ...ol, price: v }));
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="text"
              className="text-center p-1 focus:outline-none border rounded w-full"
            />
          </div>
          {/* <div className="col-span-1">
            <input
              value={
                data.lost_unit_no
                  ? data.lost_unit_no * data.price
                  : (data.unit_no - data?.received_unit_no) * data.price
              }
              disabled={true}
              placeholder=""
              type="text"
              className="text-right p-1 focus:outline-none border rounded w-full"
            />
          </div> */}

          <div className="flex gap-1 items-end justify-end">
            {props.item && !props.disabled && (
              <>
                <div className="flex justify-center gap-1 items-end">
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure?")) return;
                      onDone();
                      setEditable(false);
                    }}
                    className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiCheck} className="h-4 w-4" />
                  </div>
                </div>
                <div
                  onClick={() => {
                    if (!window.confirm("Are you sure?")) return;
                    if (props.onDelete) props.onDelete(data);
                  }}
                  className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                >
                  <Icon path={mdiDelete} className="h-4 w-4" />
                </div>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default ItemRowStatus;
