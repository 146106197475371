import {
  fetchGet,
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../service/api_client";
import { file_dir } from "../../../../utils/enums";
import {
  PaymentAttachmentModel,
  PaymentRequestModel,
  PreSignedUrlReturnModel,
} from "../../model/payment_request_model";
import { getFileType } from "../../model/purchase_order_model";
import { PaymentFilterModule } from "../../payment_request/payment_requests_list";

export const getProcurementAttachmentPRESignedURL = async (files: File[]) => {
  try {
    let pre_signed_urls: PreSignedUrlReturnModel[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const { name, type } = file;
      const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir?.pocuremnt_attachments,
      };
      const res = await fetchGet(get_signed_urls + toQueryString(query));
      if (!res) {
        throw new Error("Failed to get signed url");
      }
      const { signed_url, url } = res.data;
      const attachment: PaymentAttachmentModel = {
        id: "",
        type: getFileType(type),
        url,
        category: "",
      };
      pre_signed_urls.push([signed_url, file, attachment]);
    }
    return pre_signed_urls;
  } catch (error) {
    console.log("postProcurementAttachmentS3 ERROR", error);
    throw error;
  }
};

export const postProcurementAttachmentS3 = async (
  signed_url: string,
  file: File,
  attachment: PaymentAttachmentModel
) => {
  try {
    console.log("Logging type:", attachment.type);
    if (attachment.type === "PDF") {
      attachment.type = "application/pdf";
    }
    console.log("Logging type seafdshc:", attachment.type);
    const res = await fetch(signed_url, {
      method: "PUT",
      headers: {
        "Content-Type": attachment.type,
      },
      body: file,
    });
    console.log("postProcurementAttachmentS3", res)
    if (res.ok) {
      return true;
    }
    return false;
  } catch (error) {
    console.log("postProcurementAttachmentS3 ERROR", error);
    throw error;
  }
};

export const createPaymentRequestPayment = async (
  data: PaymentRequestModel
) => {
  let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request`;
  const res = await fetchPost(url, data);
  return res;
};

export const updatePaymentRequestApi = async (
  id: any, data: PaymentRequestModel
) => {
  let url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request/${id}`;
  const res = await fetchPut(url, data);
  return res;
};

export const getPaymentList = async (data: PaymentFilterModule) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request/get-payments`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const exportPaymentRequests = async (data: PaymentFilterModule) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL
      }/api/v3/purchase-order/advance_payment_request/export${toQueryString(data)}`;
    const res = await fetchGet(url, data);
    console.log("data for export:",res.data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const postImportPaymentList = async (data: PaymentRequestModel[]) => {
  try {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request/import`;
    const res = await fetchPost(url, data);
    return res;
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
