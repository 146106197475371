import { FunctionComponent, useState } from "react";

interface VendorSearchWidgetProps {
  onClear?: () => void;
  onSubmit: (search: string) => void;

  defaulValue?: string;
  placeholder?: string;
}

const VendorSearchField: FunctionComponent<VendorSearchWidgetProps> = ({
  placeholder,
  defaulValue,
  onSubmit,
  onClear,
}) => {
  const [search, setSearch] = useState(defaulValue ?? "");
  return (
    <form
      id="searchForm"
      name="searchForm"
      className=" flex items-center rounded border  justify-between "
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(search);
      }}
    >
      <input
        autoComplete="off"
        id="search"
        name="search"
        value={search}
        onChange={(e) => {
          if (e.target.value === "" && onClear) {
            onClear();
          }
          setSearch(e.target.value);
          onSubmit(e.target.value);
        }}
        className="text-sm px-3 py-2  h-8 rounded focus:outline-none w-full"
        type="text"
        placeholder={placeholder ?? "Search"}
      />
      <div className="flex ">
        {search && (
          <div className=" flex items-center  ">
            <button
              onClick={() => {
                setSearch("");
                if (onClear) onClear();
              }}
              form="searchForm"
              type="reset"
              className=" rounded-full hover:bg-gray-200  mx-1 p-1"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 "
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    </form>
  );
};

export default VendorSearchField;
