import moment from "moment";
import React, { useState } from "react";
import { FunctionComponent } from "react";
import { Mail, Phone } from "react-feather";
import { Tree, TreeNode } from "react-organizational-chart";

interface OrgTreeProps {
  orgData: any;
  handleNodeData: any;
}

const OrgTree: FunctionComponent<OrgTreeProps> = ({
  orgData,
  handleNodeData,
}) => {
  return (
    <Tree
      lineWidth={"2px"}
      nodePadding={"20px"}
      lineColor={"#666666"}
      lineBorderRadius={"10px"}
      lineHeight={"60px"}
      label={
        <div className=" bg-red-600 text-white inline-block py-2 px-6 rounded ">
          <div className="text-xl dark:text-white">{orgData.first_name}</div>
        </div>
      }
    >
      {orgData?.children?.length ? (
        <>
          {orgData.children.map((child: any) => (
            <OrgNode handleNodeData={handleNodeData} data={child} />
          ))}
        </>
      ) : (
        ""
      )}
    </Tree>
  );
};

export default OrgTree;

interface OrgNodeProps {
  data: any;
  handleNodeData: any;
}

const OrgNode: FunctionComponent<OrgNodeProps> = ({ data, handleNodeData }) => {
  return (
    <>
      {data.children?.length ? (
        <TreeNode
          label={
            <div className="py-2 px-5 rounded  flex flex-col items-center">
              {/* profile_image */}
              {data?.profile_image ? (
                <img
                  src={data?.profile_image}
                  alt=""
                  className="object-cover w-12 h-12 lg:w-20 lg:h-20 rounded-full cursor-pointer"
                  onClick={() => handleNodeData(data)}
                />
              ) : (
                <img
                  src={"/images/cat.jpeg"}
                  alt=""
                  className=" w-12 h-12 lg:w-20 lg:h-20 rounded-full object-cover cursor-pointer"
                  onClick={() => handleNodeData(data)}
                />
              )}
              <div className="dark:text-primaryColor whitespace-nowrap">
                {data?.first_name + " " + data?.last_name}
              </div>
              <div className="text-secondaryColor whitespace-nowrap">
                {data?.emp_profile?.designation}
              </div>
            </div>
          }
        >
          <>
            {data.children.map((child: any) => (
              <OrgNode handleNodeData={handleNodeData} data={child} />
            ))}
          </>
        </TreeNode>
      ) : (
        <TreeNode
          label={
            <div className="py-2 px-5  rounded flex flex-col items-center relative">
              {data?.profile_image ? (
                <img
                  src={data?.profile_image}
                  alt=""
                  className=" w-12 h-12 lg:w-20 lg:h-20 rounded-full cursor-pointer"
                  onClick={() => handleNodeData(data)}
                />
              ) : (
                <img
                  src={"/images/cat.jpeg"}
                  alt=""
                  className=" w-12 h-12 lg:w-20 lg:h-20 rounded-full object-cover cursor-pointer"
                  onClick={() => handleNodeData(data)}
                />
              )}
              <div className="dark:text-primaryColor whitespace-nowrap">
                {data?.first_name + " " + data?.last_name}
              </div>
              <div className="text-secondaryColor whitespace-nowrap">
                {data?.emp_profile?.designation}
              </div>
            </div>
          }
        />
      )}
    </>
  );
};
