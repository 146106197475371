import { BaseModel } from "../../../service/models/base";
import { AdditionalFund } from "./cost_center_model";


export interface Project extends BaseModel {
    name: string;
    description?: string;
    cost_center_category?: string;
    cost_center_id: string;
    batchName: string;
    batchSize: number;
    budget: number;
    spent: number;
    addAdditionalBudget?: number;
    duplicateAdditionalBudget?: number;
    totalAdditionalFunds: number;
    additionalFunds?: AdditionalFund[];
    totalBudget: number;
    projectStatus: string;
}

export const ProjectCloseStatus = {
    closed: "Closed",
    open: "Open",
}

export const projectDefaultValue: Project = {
    name: "",
    batchName: "",
    batchSize: 0,
    budget: 0,
    totalBudget: 0,
    cost_center_id: "",
    spent: 0,
    projectStatus: ProjectCloseStatus.open,
    totalAdditionalFunds: 0
}

export interface ProjectFilterData {
    name?: string;
    description?: string;
    projectStatus?: string;
    fromMonth?: Date;
    toMonth?: Date;
    search?: string;
    page: number;
    count: number;
    all?: any;
    id?: string;
}

