export const dataTypes = {
  null: "null",
  string: "string",
  text: "text",
  number: "number",
  date: "date",
  timestamp: "timestamp",
  object: "object",
  object_id: "object_id",
  boolean: "boolean",
  radio: "radio",
};

export const isNumber = (str: any) => {
  try {
    if (!isNaN(str)) return dataTypes.number;
  } catch (error) {
    return false;
  }
};
export const isBool = (str: string) => {
  try {
    const bool = str.toLowerCase();
    if (bool === "true" || bool === "false") {
      return dataTypes.boolean;
    }
  } catch (error) {
    return false;
  }
};
export const isDate = (str: string) => {
  try {
    var timestamp = Date.parse(str);
    if (!isNaN(timestamp)) {
      return dataTypes.date;
    }
  } catch (error) {
    return false;
  }
};
export const isObject = (str: string) => {
  try {
    const obj = JSON.parse(str);
    if (obj) {
      return dataTypes.object;
    }
  } catch (error) {
    return false;
  }
};
export const isObjectId = (str: string) => {
  try {
    let checkForValidMongoDbID = new RegExp("^[0-9a-fA-F]{24}$");
    if (checkForValidMongoDbID.test(str)) {
      return dataTypes.object_id;
    }
  } catch (error) {
    return false;
  }
};
export const isTimestamp = (str: string) => {
  try {
    const timestampPattern = /^[0-9]$/;
    if (timestampPattern.test(str)) {
      if (str.length === 13 || str.length === 10) {
        return dataTypes.timestamp;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
};
export const parseNumber = (str: any) => {
  if (isNumber(str)) return Number(str);
};
export const parseBool = (str: string) => {
  const bool = str.toLowerCase();
  return bool === "true";
};
export const parseDate = (str: string) => {
  var timestamp = Date.parse(str);
  return new Date(timestamp);
};
export const parseObject = (str: string) => {
  const obj = JSON.parse(str);
  return obj;
};

export const parseTimestamp = (str: string) => {
  const timestampPattern = /^[0-9]$/;
  if (timestampPattern.test(str)) {
    if (str.length === 13 || str.length === 10) {
      return Number(str);
    }
  }
  return false;
};

export function getDataType(str: string) {
  if (!str) {
    return dataTypes.null;
  }

  // Try to parse the string as different data types
  const timestamp = isTimestamp(str);
  if (timestamp) return timestamp;
  const num = isNumber(str);
  if (num) return num;
  const bool = isBool(str);
  if (bool) return bool;

  const date = isDate(str);
  if (date) {
    return date;
  }

  const obj = isObject(str);
  if (obj) {
    return obj;
  }

  return "string";
}
export function parseDataType(str: string, type?: string) {
  if (!str || str === dataTypes.null) {
    return null;
  }

  if (type) {
    try {
      if (type === dataTypes.string || type === dataTypes.text) return str;
      if (type === dataTypes.timestamp) return parseTimestamp(str);

      if (type === dataTypes.number) return parseNumber(str);

      if (type === dataTypes.boolean) return parseBool(str);

      if (type === dataTypes.date) return parseDate(str);

      if (type === dataTypes.object) return parseObject(str);
    } catch (error) {
      return str;
    }
  }
  // Try to parse the string as different data types
  const timestamp = isTimestamp(str);
  if (timestamp) return parseTimestamp(str);
  const num = isNumber(str);
  if (num) return parseNumber(str);
  const bool = isBool(str);
  if (bool) return parseBool(str);

  const date = isDate(str);
  if (date) return parseDate(str);

  const obj = isObject(str);
  if (obj) return parseObject(str);
  return str;
}

export const parseData = (input: any[]) => {
  const data: any[] = input.map((d) => {
    const t: any = {};
    const keys = Object.keys(d);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const split = key.split(".");
      split.reduce((obj, prop, index) => {
        if (index === split.length - 1) {
          obj[prop] = parseDataType(d[key]);
        } else {
          obj[prop] = obj[prop] || {};
        }
        return obj[prop];
      }, t);
    }
    return t;
  });
  return data;
};
