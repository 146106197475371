import { mdiArrowLeft, mdiDelete, mdiUpload, mdiUploadNetwork } from "@mdi/js";
import Icon from "@mdi/react";
import axios from "axios";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../../service/api_client";
import ImageTile from "../../../../ui/components/common/image_tile";
import FileDropZone from "../../../../ui/new_components/common/file_drop_zone";
import { FileType, file_dir } from "../../../../utils/enums";
import {
  AttachmentModel,
  attachment_category,
  getFileType,
  PurchaseOrderModel,
} from "../../model/purchase_order_model";
import { PaymentAttachmentModel } from "../../model/payment_request_model";

interface AttachmentSectionProps {
  attachments: AttachmentModel[];
  poNo?: PurchaseOrderModel;
  onSubmit?: (data: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => void;
  onDelete?: (data: AttachmentModel) => void;
  onSelect: (data: AttachmentModel) => void;
}

const AttachmentSection: FunctionComponent<AttachmentSectionProps> = (
  props
) => {
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [file, setFile] = useState<File>();
  const [fileBloUrl, setFileBlobUrl] = useState<string>();
  const [fileType, setFileType] = useState<string>();
  const [category, setCategory] = useState("");
  const [showUpload, setShowUpload] = useState(false);
  const [attachments, setAttachments] = useState(props.attachments);
  const [prAttachments, setPrAttachmnets] = useState<any>();

  useEffect(() => {
    if (!attachments || attachments.length === 0) {
      setShowUpload(true);
    }
  }, []);

  useEffect(() => {
    if (file) {
      const t = getFileType(file.type);
      setFileType(t);
      if ([FileType.IMAGE, FileType.PDF].includes(t)) {
        setFileBlobUrl(URL.createObjectURL(file));
      }
    }
  }, [file]);

  const onSelectAttachment = async (
    attachment: PaymentAttachmentModel
  ): Promise<void> => {
    try {
      // Fetch the file data from the URL
      const response = await fetch(attachment.url);
      if (!response.ok) {
        throw new Error("Failed to fetch the file");
      }

      // Create a blob from the response
      const blob = await response.blob();

      // Create a download link dynamically
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = attachment.url.split("/").pop() || "download.csv"; // Extract filename or set default
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up the object URL
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  useEffect(() => {
    if (props.poNo?.po_no) {
      GetPRAttachments();
    }
  }, [props.poNo?.po_no]); // Dependency array ensures it runs when `po_no` changes

  const GetPRAttachments = async () => {
    setLoading(true);
    try {
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request/po/${props.poNo?.po_no}`
      );
      if (res.success) {
        setPrAttachmnets(res.attachments?.attachments);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      console.error("Error fetching attachments:", error);
      showToast({
        type: ToastType.error,
        text: "An error occurred while fetching attachments.",
      });
    } finally {
      setLoading(false); // Ensure loading state is turned off
    }
  };

  const removeSelectedFile = () => {
    if (fileBloUrl) {
      URL.revokeObjectURL(fileBloUrl);
      setFileBlobUrl("");
      setFile(undefined);
    }
  };
  return (
    <>
      <div className="p-5 select-none">
        {!showUpload && (
          <div className="flex justify-end">
            <button
              onClick={() => {
                setShowUpload(true);
              }}
              className="rounded-lg border px-3 py-1 text-sm flex items-center justify-center gap-1 "
            >
              <Icon path={mdiUpload} size={0.8}></Icon> Upload
            </button>
          </div>
        )}
        {showUpload ? (
          <div className="">
            {attachments && attachments.length > 0 && (
              <div className="flex justify-end">
                <button
                  className="border rounded-md mb-2 text-sm px-3 py-1 flex justify-center items-center gap-1"
                  onClick={() => {
                    setShowUpload(false);
                  }}
                >
                  {" "}
                  <Icon
                    path={mdiArrowLeft}
                    size={0.8}
                    className="hover:scale-105 transform"
                  ></Icon>
                  Back
                </button>
              </div>
            )}

            <div className="flex flex-col text-sm mb-2">
              <label htmlFor="category">Category</label>
              <select
                className="rounded border px-3 py-1  cursor-pointer focus:outline-none"
                name=""
                id="category"
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option value="" hidden>
                  select ...
                </option>
                {Object.values(attachment_category).map((cat) => (
                  <option value={cat} key={cat}>
                    {cat}
                  </option>
                ))}
              </select>
            </div>

            {!file ? (
              <FileDropZone
                onDrop={function (files: File[]): void {
                  if (files[0]) setFile(files[0]);
                }}
              />
            ) : (
              <>
                <div className="rounded-lg border relative">
                  {fileType === FileType.IMAGE && (
                    <a target="_blank" href={fileBloUrl}>
                      <img src={fileBloUrl} alt="" />
                    </a>
                  )}
                  {fileType === FileType.PDF && (
                    <a target="_blank" href={fileBloUrl}>
                      <embed src={fileBloUrl} type="application/pdf"></embed>
                    </a>
                  )}

                  <button
                    onClick={removeSelectedFile}
                    className="absolute top-0 right-0 rounded-full p-1 flex justify-center items-center "
                  >
                    <Icon path={mdiDelete} size={1}></Icon>
                  </button>
                </div>
                <div className="flex justify-center my-5 ">
                  <button
                    onClick={() =>
                      props.onSubmit &&
                      props.onSubmit({
                        file,
                        category,
                        removeSelectedFile,
                        setShowUpload,
                      })
                    }
                    className="rounded-lg border px-3 py-1 text-sm flex items-center justify-center gap-1 "
                  >
                    <Icon path={mdiUpload} size={0.8}></Icon> Upload
                  </button>
                </div>
              </>
            )}
          </div>
        ) : (
          <>
            <div className="grid grid-cols-7 text-sm font-semibold px-2">
              <div className="col-span-2">File</div>
              <div className="col-span-2">category</div>
              <div className="col-span-2">created by</div>
            </div>
            <div
              className="flex flex-col gap-1 overflow-auto w-full  "
              style={{ height: "80vh" }}
            >
              {attachments &&
                attachments.length > 0 &&
                attachments.map((att, i) => (
                  <div className="grid grid-cols-7 rounded-lg border overflow-hidden justify-center items-center text-xs ">
                    <div
                      onClick={() => props?.onSelect(att)}
                      // target="_blank"
                      // href={att.url}
                      className="rounded-lg h-16 w-16  col-span-2 flex justify-center items-center  cursor-pointer "
                    >
                      {att.type === FileType.IMAGE ? (
                        <img src={att.url} alt="" />
                      ) : (
                        <div className="text-sm font-semibold">{att.type}</div>
                      )}
                    </div>

                    <div className=" col-span-2">{att.category}</div>
                    <div className="flex flex-col   col-span-2">
                      <div className="">{att.created_by_name}</div>
                      <div className="italic " style={{ fontSize: 10 }}>
                        {moment(att.created_at).format("lll")}
                      </div>
                    </div>

                    <div className=" flex justify-center">
                      <button
                        onClick={() => props.onDelete && props.onDelete(att)}
                        className=" rounded-full p-1 flex justify-center items-center "
                      >
                        <Icon
                          path={mdiDelete}
                          size={0.8}
                          className="hover:scale-105 transform"
                        ></Icon>
                      </button>
                    </div>
                  </div>
                  // <div className="rounded-lg border overflow-hidden relative">
                  //   {att.type === FileType.IMAGE && (
                  //     <a target="_blank" href={att.url}>
                  //       <img src={att.url} alt="" />
                  //     </a>
                  //   )}
                  //   {att.type === FileType.PDF && (
                  //     <a target="_blank" href={att.url}>
                  //       <embed src={att.url} type="application/pdf"></embed>
                  //     </a>
                  //   )}
                  //   <button
                  //     onClick={() => deleteFile(att)}
                  //     className="absolute top-0 right-0 rounded-full p-1 flex justify-center items-center "
                  //   >
                  //     <Icon
                  //       path={mdiDelete}
                  //       size={0.8}
                  //       className="hover:scale-105 transform"
                  //     ></Icon>
                  //   </button>
                  // </div>
                ))}
            </div>
          </>
        )}
        <div className="mt-5">
          <label className="text-lg font-bold mt-5 py-3">PR Attachments.</label>
        </div>
        <div className="grid grid-cols-4 text-sm font-semibold px-2">
          <div className="col-span-2">File</div>
          {/* <div className="col-span-2">category</div> */}
          <div className="col-span-2">created by</div>
        </div>

        <div
          className="flex flex-col gap-1 overflow-auto w-full"
          style={{ height: "80vh" }}
        >
          {prAttachments && prAttachments.length > 0 ? (
            prAttachments.map((att: any, i: any) => (
              <div
                key={i} // Add key for the list item for proper React rendering
                className="grid grid-cols-4 rounded-lg border overflow-hidden justify-center items-center text-xs"
              >
                <div
                  onClick={() => {
                    if (
                      att.type === "CSV"
                    ) {
                      // Call the onSelectAttachment function if it's a CSV file
                      onSelectAttachment(att);
                    } else {
                      // Otherwise, trigger the onSelect handler passed in props
                      props?.onSelect(att);
                    }
                  }}
                  className="rounded-lg h-16 w-16 col-span-2 flex justify-center items-center cursor-pointer"
                >
                  {att.type === FileType.IMAGE ? (
                    <img src={att.url} alt="Attachment preview" />
                  ) : att.type === "application/pdf" ? (
                    <div className="text-sm font-semibold">PDF</div>
                  ) : att.type === "text/csv" ||
                    att.type === "application/csv" ? (
                    <div className="text-sm font-semibold">CSV</div>
                  ) : (
                    <div className="text-sm font-semibold">{att.type}</div>
                  )}
                </div>

                {/* <div className="col-span-2">{att.category}</div> */}
                <div className="flex flex-col col-span-2">
                  <div>{att.created_by_name}</div>
                  <div className="italic" style={{ fontSize: 10 }}>
                    {moment(att.created_at).format("lll")}
                  </div>
                </div>

                {/* Optional: Add Delete button */}
                {/* <div className="flex justify-center">
          <button
            onClick={() => props.onDelete && props.onDelete(att)}
            className="rounded-full p-1 flex justify-center items-center"
          >
            <Icon
              path={mdiDelete}
              size={0.8}
              className="hover:scale-105 transform"
            />
          </button>
        </div> */}
              </div>
            ))
          ) : (
            <p className="font-bold text-green-500 text-sm">
              No PR attachments found
            </p>
          )}
        </div>
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default AttachmentSection;
