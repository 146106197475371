import {
  mdiRefresh,
  mdiPlus,
  mdiImport,
  mdiArrowBottomLeft,
  mdiClose,
  mdiCommentBookmark,
  mdiHistory,
  mdiTrashCan,
  mdiPlusBox,
  mdiCloseBox,
} from "@mdi/js";
import Icon from "@mdi/react";
import axios, { CancelTokenSource } from "axios";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useModal,
  ModalType,
} from "../../../context_providers/modal/modal_context";
import ModalWidget from "../../../context_providers/modal/modal_widget";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../service/api_client";
import { urls } from "../../../utils/urls";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../utils/enums";
import Pagination from "../../../ui/new_components/common/pagination";
import { debounce, update } from "lodash";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../ui/new_components/common/drawer_modal";
import {
  CostCenter,
  CostCenterFilterData,
  MasterCostCenterStatus,
} from "../models/cost_center_model";
import ImportWizard from "../items/components/import_comp";
import moment from "moment";

interface CostCenterScreenProps {}

const defaultFilter: CostCenterFilterData = {
  page: 1,
  count: 100,
};

const CostCenterListScreen: FunctionComponent<CostCenterScreenProps> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/cost_center`;
  const { showModal, onClose } = useModal();

  const [filter, setFilter] = useState<CostCenterFilterData>(defaultFilter);

  const { showToast } = useToast();
  const [checker, setChecker] = useState(false);
  const [maker, setMaker] = useState(false);
  const [selectedItem, setSelectedItem] = useState<CostCenter | null>(null);
  const [errorObjectList, setErrorObjectList] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<CostCenter[]>([]);

  // const [state, setState] = useState ({
  //   checker:
  // })

  const { user, isAuthorised } = useAuth();
  const fetchData = async (prop: CostCenterFilterData) => {
    setLoading(true);
    console.log("Props", prop);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        setCount(res.data.metadata.count);
        console.log("Res", res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
        setLoading(false);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
  };

  useEffect(() => {
    const maker_permission_result = isAuthorised({
      module: MODULE.cost_center_maker,
      action: ACTION.CREATE,
    });
    const checker_permission_result = isAuthorised({
      module: MODULE.cost_center_checker,
      action: ACTION.UPDATE,
    });
    setChecker(checker_permission_result);
    setMaker(maker_permission_result);
    console.log("Checker", checker_permission_result);
    console.log("Maker", maker_permission_result);
  }, [isAuthorised]); // Separate call for permission check

  const debouncedHandler = useCallback(
    debounce((prop: CostCenterFilterData) => {
      if (prop) fetchData(prop);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedHandler(filter);
  }, [page, filter, debouncedHandler]);

  const navaigation = useHistory();
  const onSelect = (d: CostCenter) => {
    if (
      checker &&
      (d.costCenterStatus === MasterCostCenterStatus.approved ||
        d.costCenterStatus === MasterCostCenterStatus.rejected ||
        d.costCenterStatus === MasterCostCenterStatus.savedDraft)
    ) {
      navaigation.push(
        urls.procurement_masters_cost_center + "/view-cost-center/" + d.id
      );
    } else if (
      maker &&
      d.costCenterStatus === MasterCostCenterStatus.savedDraft
    ) {
      navaigation.push(
        urls.procurement_masters_cost_center + "/update/" + d.id
      );
    } else if (
      maker &&
      (d.costCenterStatus === MasterCostCenterStatus.updatePending ||
        d.costCenterStatus === MasterCostCenterStatus.pendingForApproval)
    ) {
      navaigation.push(
        urls.procurement_masters_cost_center + "/view-cost-center/" + d!.id
      );
    } else {
      navaigation.push(
        urls.procurement_masters_cost_center + "/view-cost-center/" + d.id
      );
    }
  };

  const handleOnEdit = () => {
    if (selectedItems.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selectedItems.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      navaigation.push(
        urls.procurement_masters_cost_center + "/update/" + selectedItems[0]!.id
      );
    }
  };

  let cancelTokenSource: CancelTokenSource;
  const onImport = async (d: {
    data: CostCenter[];
    setCompleted: (loading: boolean) => void;
    setUpLoading: (loading: boolean) => void;
    setProgress: (loading: number) => void;
    // setCancelled: (d: boolean) => void;
  }) => {
    cancelTokenSource = axios.CancelToken.source();
    d.setUpLoading(true);
    const config = {
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (e: any) => {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        d.setProgress(percentCompleted);
        console.log("uploaded " + percentCompleted + " %");
      },
    };

    console.log(d.data);

    const form = new FormData();
    form.append("data", JSON.stringify(d.data));
    const res = await fetchPost(url + "/import", form, {}, config);
    if (res.success) {
      await fetchData({ page, count });

      showToast({ type: ToastType.success, text: res.message });
    } else {
      setShowImport(false);
      if (res.exisiting_items) {
        let existing_items: string[] = res.exisiting_items;
        setErrorObjectList([...errorObjectList, ...existing_items]);
        setShowImportErrors(true);
      }
      if (res.validation_errors.length > 0) {
        setErrorObjectList([...errorObjectList, ...res.validation_errors]);
        setShowImportErrors(true);
      }
      showToast({ type: ToastType.error, text: res.error });
    }
    d.setUpLoading(false);
  };
  const [showHistory, setShowHistory] = useState<any>();
  const [showImport, setShowImport] = useState(false);

  const [selectedItems, setSelectedItems] = useState<CostCenter[]>([]);

  const [showImportErrors, setShowImportErrors] = useState<boolean>(false);

  return (
    <>
      {/* {showHistory && (
          <DrawerModal
            location={DrawerModalLoaction.right}
            show={showHistory !== undefined}
            onClose={() => setShowHistory(false)}
            title="History"
          >
            <ItemActionHistory
              item_code={showHistory}
  
              // show={ShowHistory !== undefined}
              // onClose={(v) => setShowHistory(undefined)}
            />
          </DrawerModal>
        )} */}
      {showImport && (
        <ModalWidget
          data={{
            id: 1,
            title: "Import",
            type: ModalType.modal,

            onClose: () => {
              setShowImport(false);
            },
          }}
          onClose={function (): void {
            setShowImport(false);
          }}
        >
          <ImportWizard
            onClose={() => {
              setShowImport(false);
            }}
            onCancel={function () {
              if (cancelTokenSource) {
                console.log("Cancelled by user");
                cancelTokenSource.cancel("cancelled by user");
              }
            }}
            onSubmit={onImport}
            onError={function (error: string): void {
              showToast({ type: ToastType.error, text: error });
            }}
          />
        </ModalWidget>
      )}

      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-3xl text-gray-500 flex items-center gap-3">
              Cost Center Master
              <button
                title="Refresh"
                onClick={() => fetchData({ page, count })}
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex flex-wrap justify-start   text-sm items-center gap-4 ">
              {" "}
              {checker && (
                <div
                  onClick={() => {
                    setShowImport(true);
                  }}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                >
                  <Icon path={mdiArrowBottomLeft} size={0.8} />
                  Import
                </div>
              )}
              {maker && (
                <>
                  <div
                    onClick={() => {
                      navaigation.push(
                        urls.procurement_masters_cost_center + "/create"
                      );
                    }}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
                  >
                    <Icon path={mdiPlus} className="h-4 w-4"></Icon>Create
                  </div>
                </>
              )}
              {maker && (
                <>
                  <div
                    onClick={handleOnEdit}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
                  >
                    <Icon path={mdiPlus} className="h-4 w-4"></Icon>Update
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <div>
          {showImportErrors &&
            errorObjectList.length > 0 &&
            (() => {
              return (
                <div className="">
                  <div className="rounded-lg border border-red-800 m-2 px-4 py-3">
                    <div className="flex justify-between">
                      <div
                        onClick={() => {
                          setShowImportErrors(false);
                        }}
                        className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })()}
        </div>

        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs   py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={
                        selectedItems.length === data.length ? true : false
                      }
                      disabled={checker && !maker ? false : true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItems([...data]);
                        } else {
                          setSelectedItems([]);
                        }
                        console.log(e);
                      }}
                    />
                  </div>
                </th>
                {/* <th className="px-2 py-2">Actions</th> */}
                <th className="px-2 py-2 text-left">Cost Center ID</th>
                <th className="px-2 py-2 text-left">Name</th>
                <th className="px-2 py-2 text-left">From Month</th>
                <th className="px-2 py-2 text-left">To Month</th>
                <th className="px-2 py-2 text-left">Budget</th>
                <th className="px-2 py-2 text-left">Additional Funds</th>
                <th className="px-2 py-2 text-left">Total Budget</th>
                <th className="px-2 py-2 text-left">Spent</th>
                <th className="px-2 py-2 text-left">Balance</th>
                <th className="px-2 py-2 text-left">Last Approved Budget</th>
                <th className="px-2 py-2 text-left"> Approved Status</th>
                <th className="px-2 py-2 text-left">Cost Center Status</th>
                <th className="px-2 py-2 text-left">Created By</th>
                <th className="px-2 py-2 text-left">Last Approved By</th>
                <th className="px-2 py-2 text-left">Remarks</th>
              </tr>
            </thead>
            <tbody className="">
              {data.length > 0 ? (
                data.map((cost, i) => {
                  const isSelected = selectedItems.includes(cost);
                  return (
                    <tr className="border text-center " key={i}>
                      <td>
                        <div className="p-2">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={isSelected}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedItems((prev) => [...prev, cost]); // Add the item to selected items
                              } else {
                                setSelectedItems((prev) =>
                                  prev.filter(
                                    (selectedItem) => selectedItem !== cost
                                  )
                                ); // Remove the item from selected items
                              }
                            }}
                          />
                        </div>
                      </td>
                      {/* <td>
                          <div className="flex gap-2 justify-center">
                            {" "}
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                setShowHistory(cost.id);
                              }}
                            >
                              <Icon path={mdiHistory} size="16"></Icon>
                            </div>
                          </div>
                        </td> */}
                      <div
                        className="py-3 items-center cursor-pointer text-blue-600 hover:underline"
                        role="button"
                        onClick={() => onSelect(cost)}
                      >
                        <td className="px-3 py-2 text-left">{cost.id}</td>
                      </div>

                      <td className="px-3 py-2 text-left">{cost.name}</td>
                      <td className="px-6 py-2  text-left">
                        {cost?.fromMonth
                          ? moment(cost?.fromMonth).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td className="px-6 py-2 text-left">
                        {cost?.toMonth
                          ? moment(cost?.toMonth).format("DD-MM-YYYY")
                          : ""}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.budget ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.totalAdditionalFunds ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.totalBudget ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.spent ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost?.lastApprovedBudget - cost?.spent}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.lastApprovedBudget ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.approvalStatus ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.costCenterStatus ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.created_by_name ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.approved_by_name ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {cost.remarks ?? "--"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={count}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={
              (page) => setFilter((o) => ({ ...o, page }))
              // () => console.log("Pagination")
            }
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default CostCenterListScreen;
