import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  WAREHOUSE_TYPE,
  warehouseDefaultValue,
  WarehouseModel,
} from "../../models/warehouse_model";
import { urls } from "../../../../utils/urls";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet } from "../../../../service/api_client";
import EmployeeSearchWidget from "../../../../ui/new_components/emp_search_widget";
import PartySearchField from "../../../accounts/parties/party_search_field";
import LoadingWidget from "../../../../context_providers/modal/loader";

interface WarehouseViewScreenProps {
  edit: boolean;
  onClose: (id?: WarehouseModel) => void;
  data?: WarehouseModel;
}

const WarehouseViewScreen: FunctionComponent<WarehouseViewScreenProps> = (
  props
) => {
  const isViewPage = useRouteMatch(
    `${urls.procurement_masters_warehouse}/view/:id`
  );
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/warehouse_type`;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const { edit } = props;
  const [editable, setEditable] = useState(false);
  const [data, setData] = useState<WarehouseModel>(
    props.data || ({} as WarehouseModel)
  );
  const navaigation = useHistory();

  const handleOptionChange = (value: WarehouseModel["type"]) => {
    setData((pv) => ({ ...pv, type: value }));
  };

  useEffect(() => {
    setEditable(edit);
    if (id && isViewPage) {
      getData(id);
    }
  }, []);

  useEffect(() => {
    // Initialize form with existing data if editing
    if (edit && props.data) {
      setData(props.data);
    }
  }, [edit, props.data]);

  const getData = async (id: string) => {
    try {
      setLoading(true);
      const res = await fetchGet(`${url}/${id}`);
      if (res.success) {
        console.log("result:", res.data);
        const updatedData = { ...res.data, type: res.data.type }; // Update type property
        setData(updatedData); // Update state with merged data
      } else {
        console.log("Invalid");
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="grid grid-cols-1 m-5 gap-2 select-none text-sm">
        <h1 className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
          Warehouse {id ? `View: ${data.id}` : ""}
        </h1>
        <div className="grid grid-cols-1 w-1/2 justify-center m-5 gap-2 select-none">
          <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white rounded-lg">
            <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="name">
                Warehouse Name*
              </label>
              <input
                placeholder="Enter Name"
                disabled={true}
                className="p-1 rounded focus:outline-none w-full border"
                id="name"
                name="name"
                value={data.name || ""}
                required
                type="text"
                onChange={(e) => setData({ ...data, name: e.target.value })}
              />
            </div>

            {/* <div className="flex flex-col items-start text-sm w-full">
              <label className="font-semibold w-full" htmlFor="desc">
                Description
              </label>
              <textarea
                placeholder="Short description"
                className="p-1 rounded focus:outline-none w-full border"
                id="description"
                name="description"
                value={data.description || ""}
                onChange={(e) =>
                  setData((d) => ({ ...d, description: e.target.value }))
                }
              ></textarea>
            </div> */}
            <div className="">
              {/* <h4 className="font-bold text-center mb-5">Address </h4> */}
              <div className="grid grid-cols-2 gap-5 items-end">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    Address Line 1
                  </label>
                  <textarea
                    placeholder="Enter address line 1"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="line1"
                    name="line1"
                    disabled={true}
                    value={data.address?.line1 || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          line1: e.target.value,
                        },
                      }))
                    }
                  />
                </div>

                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line2">
                    Address Line 2
                  </label>
                  <textarea
                    placeholder="Enter address line 1"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="line2"
                    name="line2"
                    disabled={true}
                    value={data.address?.line2 || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          line2: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-5 items-end">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="city">
                    City*
                  </label>
                  <input
                    placeholder="Enter city"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="city"
                    name="city"
                    disabled={true}
                    required
                    value={data.address?.city || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          city: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="state">
                    State*
                  </label>
                  <input
                    placeholder="Enter state"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="state"
                    name="state"
                    required
                    disabled={true}
                    value={data.address?.state || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          state: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>

              <div className="grid grid-cols-2 gap-5 items-end">
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="country">
                    Country*
                  </label>
                  <input
                    placeholder="Enter country"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="country"
                    name="country"
                    disabled={true}
                    required
                    value={data.address?.country || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          country: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="pincode">
                    Pincode*
                  </label>
                  <input
                    placeholder="Enter pincode"
                    className="p-1 rounded focus:outline-none w-full border"
                    id="pincode"
                    name="pincode"
                    disabled={true}
                    required
                    value={data.address?.pincode || ""}
                    onChange={(e) =>
                      setData((prevData) => ({
                        ...prevData,
                        address: {
                          ...prevData.address!,
                          pincode: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-5 items-end">
              <div className="flex flex-col items-start text-sm w-full">
                <label className="font-semibold w-full" htmlFor="type">
                  Type*
                </label>
                <select
                  value={data.type}
                  disabled={true}
                  onChange={(e) => {
                    handleOptionChange(
                      e.target.value as WarehouseModel["type"]
                    );
                    setData({
                      ...data,
                      type: e.target.value as WarehouseModel["type"],
                    });
                  }}
                  className="p-1 rounded focus:outline-none w-full border"
                >
                  <option value="">select</option>
                  {Object.values(WAREHOUSE_TYPE).map((f: string) => (
                    <option key={f} value={f}>
                      {f}
                    </option>
                  ))}
                </select>
              </div>
              {data.type === "internal" && (
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    User Name/SPOC
                  </label>
                  <EmployeeSearchWidget
                    withBtn={false}
                    disabled={true}
                    onSelect={(emp) => {
                      setData((pv) => {
                        return {
                          ...pv!,
                          spoc: {
                            ...pv.spoc,
                            uid: emp.uid,
                            contact_name:
                              emp.first_name + " " + (emp.last_name ?? ""),
                            contact_mobile: emp.mobile,
                            contact_email: emp.email,
                            department: emp.emp_profile?.department,
                          },
                        };
                      });
                    }}
                    emp={
                      data.spoc?.uid
                        ? {
                            uid: data.spoc.uid!,
                            name: data.spoc.contact_name,
                          }
                        : undefined
                    }
                  />
                </div>
              )}
              {data.type === "external" && (
                <div className="flex flex-col items-start text-sm w-full">
                  <label className="font-semibold w-full" htmlFor="line1">
                    User Name
                  </label>
                  <PartySearchField
                    show_add={true}
                    placeholder={data.spoc?.contact_name}
                    disabled={true}
                    onSelect={(e) => {
                      setData((prevData) => ({
                        ...prevData,
                        spoc: {
                          ...prevData.spoc!,
                          contact_name: e.name,
                          contact_email: e.mail_detail.contact_email,
                          contact_mobile: e.mail_detail.contact_mobile,
                        },
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          {/* {data.type === "external" && (
            <div className="p-5 md:p-10 flex flex-col gap-5 shadow bg-white  rounded-lg">
              <div className="">
                <h4 className="font-bold text-center mb-5">
                  SPOC Details to Track{" "}
                </h4>
                <div className="grid grid-cols-2 gap-5 items-end">
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      User Name
                    </label>
                    <PartySearchField
                      show_add={true}
                      placeholder={data.spoc?.contact_name}
                      onSelect={(e) => {
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_name: e.name,
                            contact_email: e.mail_detail.contact_email,
                            contact_mobile: e.mail_detail.contact_mobile,
                          },
                        }));
                      }}
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Phone Number
                    </label>
                    <input
                      placeholder="phone number"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="transitPhoneNumber"
                      name="transitPhoneNumber"
                      value={data.spoc?.contact_mobile}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_mobile: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="flex flex-col items-start text-sm w-full">
                    <label className="font-semibold w-full" htmlFor="line1">
                      Email{" "}
                    </label>
                    <input
                      placeholder="email"
                      className="p-1 rounded focus:outline-none w-full border"
                      id="email"
                      name="email"
                      value={data.spoc?.contact_email}
                      onChange={(e) =>
                        setData((prevData) => ({
                          ...prevData,
                          spoc: {
                            ...prevData.spoc!,
                            contact_email: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}

          {/* Address Fields */}
        </div>
        <div className="flex justify-center gap-5 mt-5 mb-2">
          <button
            onClick={() => {
              navaigation.push(urls.procurement_masters_warehouse);
            }}
            type="button"
            className="rounded-md text-sm font-semibold bg-myPrimaryColor text-white px-3 py-1"
          >
            Quit
          </button>
        </div>
      </div>

      {loading && <LoadingWidget />}
    </>
  );
};

export default WarehouseViewScreen;
