import { parseDataType } from "./import_export_parser";

export const dataSanitize = (data: { [key: string]: any }) => {
  Object.keys(data).forEach((key) => {
    if (data[key] === "" || data[key] === " ") {
      delete data[key];
    }
  });
  return data;
};
export const formToObject = (form: FormData) => {
  let data: { [key: string]: any } = {};
  //  let i = 0;
  for (let [key, value] of form) {
    const t: any = value;
    if (t == "") {
      continue;
    }
    if (Array.isArray(t)) {
      data[key] = t.map((d) => parser(d));
    } else data[key] = parser(t);
  }
  return data;
};

const parser = (t: any) => {
  if (!isNaN(t)) {
    return parseInt(t);
  } else if (t === "true") {
    return true;
  } else if (t === "false") {
    return false;
  } else {
    return t;
  }
};
export const isValidJson = (str: string) => {
  try {
    if (!isNaN(str as any)) return false;
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const updateObject = (
  obj: any,
  keys: Array<string>,
  value: any,
  type?: string
): any => {
  value = parseDataType(value, type);
  console.log({keys, value, type });

  if (keys.length === 1) {
    return { ...obj, [keys[0]]: value };
  } else {
    const [currentKey, ...remainingKeys] = keys;
    return {
      ...obj,
      [currentKey]: updateObject(obj[currentKey], remainingKeys, value, type),
    };
  }
};
