import {
  mdiRefresh,
  mdiPlus,
  mdiExport,
  mdiHistory,
  mdiArrowBottomLeft,
  mdiTrashCan,
  mdiCloseBox,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ModalType,
  useModal,
} from "../../../context_providers/modal/modal_context";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../procurement/components/create_view/form_field";
import TableView from "../../../procurement/components/table_comp";
import {
  fetchDelete,
  fetchGet,
  getHeaders,
  toQueryString,
} from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { urls } from "../../../utils/urls";
import {
  defaultPartyFilter,
  PartyFilter,
  PartyModel,
  PartyStatus,
  PartyStatusMap,
  PartyType,
  StateType,
} from "../models/party_model";
import VendorCustomTableView from "./components/vendor_custom_tableview";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { ACTION, MODULE } from "../../../utils/enums";
import { dataTypes } from "../../../utils/import_export_parser";
import VendorSearchField from "./components/vendor_code_search_widget";
import { debounce } from "lodash";
import Pagination from "../../../ui/new_components/common/pagination";
import { State } from "country-state-city";

interface PartyListScreenProps {}

const columnsMap: { [k: string]: any } = {
  name: "Name",
  type: "Type",
  contact_name: "Contact Name",
  contact_mobile: "Contact Mobile",
  contact_email: "Contact Email",
  address_type: "Address Type",
  city: "City",
  state: "State",
  country: "Country",
  zip_code: "ZIP Code",
  full_address: "Full Address",
  bank_ac_no: "Bank A/C Number",
  bank_ifsc: "Bank IFSC",
  bank_name: "Bank Name",
  bank_branch: "Bank Branch",
  pan_no: "PAN Number",
  reg_type: "Registration Type",
  gst_no: "GST Number",
  attachments: "Attachments",
};

const PartyListScreen: FunctionComponent<PartyListScreenProps> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const { showModal } = useModal();

  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<PartyModel[]>([]);
  const [search, setSearch] = useState("");
  const [isMaker, setIsMaker] = useState<boolean>(false);
  const [isChecker, setIsChecker] = useState<boolean>(false);
  const { user, isAuthorised } = useAuth();
  const [filter, setFilter] = useState<PartyFilter>(defaultPartyFilter);
  const [states, setStates] = useState<StateType[]>([]);
  const [selectedItems, setSelectedItems] = useState<PartyModel[]>([]);

  const fetchData = async (prop: PartyFilter) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const exportData = async () => {
    setLoading(true);
    try {
      const res = await fetch(url + "/export", {
        method: "post",
        body: JSON.stringify({ fields: exportFields }),
        headers: { ...getHeaders(), "Content-Type": "application/json" },
        credentials: "include",
      });

      if (!res.ok) {
        return showToast({ type: ToastType.error, text: res.statusText });
      }

      const header = res.headers.get("Content-Disposition");
      const parts = header?.split(";");
      let downloadFilename = "";
      if (parts) {
        downloadFilename = parts[1].split("=")[1];
        downloadFilename = downloadFilename.substring(
          1,
          downloadFilename.length - 1
        );
      }
      const blob = await res.blob();

      const fileUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", downloadFilename ?? `export.csv`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
    }
    setLoading(false);
  };

  let exportFields: string[] = [];

  useEffect(() => {
    const checker_permission_result = isAuthorised({
      module: MODULE.master_vendor_checker,
      action: ACTION.READ,
    });
    const maker_permission_result = isAuthorised({
      module: MODULE.master_vendor_maker,
      action: ACTION.CREATE,
    });
    setIsChecker(checker_permission_result);
    setIsMaker(maker_permission_result);
    console.log("Checker", checker_permission_result);
    console.log("Maker", maker_permission_result);
  }, []);

  useEffect(() => {
    const allStates = State.getStatesOfCountry("IN");
    setStates(allStates);
  }, []);

  useEffect(() => {
    console.log("Filter", filter);
  }, [filter]);

  const debouncedHandler = useCallback(
    debounce((prop: PartyFilter) => {
      if (prop) fetchData(prop);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedHandler(filter);
  }, [page, filter, debouncedHandler]);

  const navaigation = useHistory();

  const onSelect = (d: PartyModel) => {
    if (isMaker) {
      if (
        d.status === PartyStatusMap.APPROVED ||
        d.status === PartyStatusMap.REJECTED ||
        d.status === PartyStatusMap.APPROVE_PENDING ||
        d.status === PartyStatusMap.UPDATE_PENDING
      ) {
        navaigation.push(
          urls.procurement_masters_party + "/view-details/" + d.id
        );
      } else if (d.status === PartyStatusMap.SAVED_DRAFT) {
        navaigation.push(urls.procurement_masters_party + "/create/" + d.id);
      } else
        navaigation.push(urls.procurement_masters_party + "/update/" + d.id);
    } else if (isChecker) {
      if (
        d.status === PartyStatusMap.SAVED_DRAFT ||
        d.status === PartyStatusMap.APPROVED ||
        d.status === PartyStatusMap.REJECTED
      ) {
        navaigation.push(
          urls.procurement_masters_party + "/view-details/" + d.id
        );
      } else {
        // Navigate to approve page
        navaigation.push(
          urls.procurement_masters_party + "/approve-reject/" + d.id
        );
      }
    }
  };

  function handleUpdate(): void {
    if (selectedItems.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selectedItems.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      if (selectedItems[0]?.status === PartyStatusMap.SAVED_DRAFT) {
        navaigation.push(
          urls.procurement_masters_party + "/create/" + selectedItems[0]!.id
        );
      } else if (selectedItems[0]?.status !== PartyStatusMap.APPROVED) {
        navaigation.push(
          urls.procurement_masters_party + "/update/" + selectedItems[0]!.id
        );
      } else {
        showToast({
          type: ToastType.error,
          text: "Approved Item cannot be updated",
        });
      }
    }
  }

  return (
    <>
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex flex-wrap justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500border-l-4 border-myPrimaryColor pl-2 flex items-center gap-3">
            Vendor
            <button title="Refresh" onClick={() => fetchData(filter)}>
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end text-sm items-center gap-2 m-2">
            {isMaker && (
              <>
                <div
                  onClick={() => {
                    navaigation.push(
                      urls.procurement_masters_party + "/create"
                    );
                  }}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                >
                  <Icon path={mdiPlus} className="h-4 w-4"></Icon>Add
                </div>
                <div
                  onClick={handleUpdate}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                >
                  <Icon path={mdiPlus} className="h-4 w-4"></Icon>Update
                </div>
              </>
            )}

            {/* <div
              onClick={() => {
                showModal({
                  title: "Select Fields for Export",
                  type: ModalType.modal,
                  container: () => {
                    return (
                      <div>
                        <div className="flex flex-col justify-center ml-1 mr-10">
                          {Object.keys(columnsMap).map((c) => {
                            return (
                              <div className="flex" key={c}>
                                <input
                                  type="checkbox"
                                  id={c}
                                  className="mx-2"
                                  onChange={() => {
                                    exportFields.push(c);
                                  }}
                                />
                                <label htmlFor={c}>{columnsMap[c]}</label>
                              </div>
                            );
                          })}
                        </div>
                        <div
                          onClick={() => {
                            exportData();
                          }}
                          className="flex mx-auto mt-4 justify-center w-1/2 gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                        >
                          <Icon path={mdiExport} className="h-4 w-4"></Icon>
                          Export
                        </div>
                      </div>
                    );
                  },
                });
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiExport} className="h-4 w-4"></Icon>Export
            </div> */}
          </div>
        </section>
        {/* Filter Section */}
        <section>
          <div className="grid grid-cols-2 gap-6 w-1/2 m-2">
            <div className="">
              <label htmlFor="app_status" className="">
                Vendor Code
              </label>
              {/* <input
                className="border rounded-md px-3 py-1"
                type="text"
                data-type={dataTypes.string}
                id="name"
                name="name"
                value={filter.id ?? ""}
                onChange={(e) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    id: e.target.value, // Updating the name property
                  }));
                }}
              /> */}
              <VendorSearchField
                defaulValue={filter.id ?? ""}
                onClear={() => {
                  setFilter((prevData) => ({
                    ...prevData,
                    id: "", // Directly updating item_code
                  }));
                }}
                onSubmit={(value: string) =>
                  setFilter((prevData) => ({
                    ...prevData,
                    id: value, // Directly updating item_code
                  }))
                }
              />
            </div>
            <div className="">
              <label htmlFor="app_status" className="">
                Vendor Name
              </label>
              {/* <ItemSearchField
                defaulValue={filter.item_name ?? ""}
                onClear={() => {
                  setFilter((prevData) => ({
                    ...prevData,
                    item_name: "", // Directly updating item name
                  }));
                }}
                onSubmit={(val) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    item_name: val, // Directly updating item name
                  }));
                }}
              /> */}
              {/* <input
                className="border rounded-md px-3 py-1"
                type="text"
                data-type={dataTypes.string}
                id="name"
                name="name"
                value={filter.name ?? ""}
                onChange={(e) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    name: e.target.value, // Updating the name property
                  }));
                }}
              /> */}
              <VendorSearchField
                defaulValue={filter.name ?? ""}
                onClear={() => {
                  setFilter((prevData) => ({
                    ...prevData,
                    name: "", // Directly updating item_code
                  }));
                }}
                onSubmit={(value: string) =>
                  setFilter((prevData) => ({
                    ...prevData,
                    name: value, // Directly updating item_code
                  }))
                }
              />
            </div>
            <div className="">
              <label htmlFor="item_type" className="">
                Vendor Type
              </label>
              <select
                className="w-full p-2 bg-white rounded focus:outline-none"
                name="item_type"
                id="item_type"
                value={filter.type}
                onChange={(e) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    type: e.target.value, // Directly updating type
                  }));
                }}
              >
                <option value="">select</option>
                {PartyType.map((f: string) => (
                  <option key={f} value={f}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
            <div className="">
              <label htmlFor="app_status" className="">
                GST No
              </label>
              {/* <input
                className="border rounded-md px-3 py-1"
                type="text"
                data-type={dataTypes.string}
                id="gst_no"
                name="gst_no"
                value={filter.gst_no ?? ""}
                onChange={(e) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    gst_no: e.target.value, // Updating the name property
                  }));
                }}
              /> */}
              <VendorSearchField
                defaulValue={filter.gst_no ?? ""}
                onClear={() => {
                  setFilter((prevData) => ({
                    ...prevData,
                    gst_no: "", // Directly updating item_code
                  }));
                }}
                onSubmit={(value: string) =>
                  setFilter((prevData) => ({
                    ...prevData,
                    gst_no: value, // Directly updating item_code
                  }))
                }
              />
            </div>

            <div>
              <label htmlFor="sub_category" className="">
                State
              </label>
              <select
                className="w-full p-2 bg-white rounded focus:outline-none"
                name="state"
                id="state"
                value={filter.state ?? ""}
                onChange={(e) => {
                  // Handle the change event
                  setFilter((prevData) => ({
                    ...prevData,
                    state: e.target.value, // Update the sub_category in state
                  }));
                }}
              >
                <option value="">Select State</option>
                {states.map((state) => (
                  <option key={state.isoCode} value={state.isoCode}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="">
              <label htmlFor="app_status" className="">
                City
              </label>
              {/* <input
                className="border rounded-md px-3 py-1"
                type="text"
                data-type={dataTypes.string}
                id="city"
                name="city"
                value={filter.city ?? ""}
                onChange={(e) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    city: e.target.value, // Updating the name property
                  }));
                }}
              /> */}
              <VendorSearchField
                defaulValue={filter.id ?? ""}
                onClear={() => {
                  setFilter((prevData) => ({
                    ...prevData,
                    city: "", // Directly updating item_code
                  }));
                }}
                onSubmit={(value: string) =>
                  setFilter((prevData) => ({
                    ...prevData,
                    city: value, // Directly updating item_code
                  }))
                }
              />
            </div>
            <div>
              <label htmlFor="app_status">Approval Status</label>
              <select
                className="w-full p-2 rounded focus:outline-none bg-white"
                name="app_status"
                id="app_status"
                value={filter.status ?? ""}
                onChange={(e) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    status: e.target.value as PartyStatus, // Correctly updating status
                  }));
                }}
              >
                <option value="">Select</option>
                {Object.entries(PartyStatusMap).map(([key, value]) => (
                  <option key={key} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </section>
        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs   py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={
                        // false
                        selectedItems.length === data.length ? true : false
                      }
                      disabled={isChecker && !isMaker ? false : true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItems([...data]);
                        } else {
                          setSelectedItems([]);
                        }
                        console.log(e);
                      }}
                    />
                  </div>
                </th>
                <th className="px-2 py-2">Actions</th>
                <th className="px-2 py-2 text-left">Vendor Code</th>
                <th className="px-2 py-2 text-left">Vendor Name</th>
                <th className="px-2 py-2 text-left">Type</th>
                <th className="px-2 py-2 text-left">MSME Registration</th>
                <th className="px-2 py-2 text-left">GST Reg type</th>
                <th className="px-2 py-2 text-left">City</th>
                <th className="px-2 py-2 text-left">State</th>
                <th className="px-2 py-2 text-left">Status</th>
              </tr>
            </thead>
            <tbody className="">
              {data.length > 0 ? (
                data.map((item, i) => {
                  const isSelected = selectedItems.includes(item);
                  return (
                    <>
                      <tr className="border text-center " key={i}>
                        <td>
                          <div className="p-2">
                            <input
                              type="checkbox"
                              name=""
                              id=""
                              checked={isSelected}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedItems((prev) => [...prev, item]); // Add the item to selected items
                                } else {
                                  setSelectedItems((prev) =>
                                    prev.filter(
                                      (selectedItem) => selectedItem !== item
                                    )
                                  ); // Remove the item from selected items
                                }
                              }}
                            />
                          </div>
                        </td>
                        <td>
                          {/* Actions */}
                          <div className="flex gap-2 justify-center">
                            {" "}
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                // setShowHistory(item.item_code);
                              }}
                            >
                              <Icon path={mdiHistory} size="16"></Icon>
                            </div>
                          </div>
                        </td>
                        {/* Item code */}
                        <div
                          className="py-3 items-center cursor-pointer text-blue-600 hover:underline"
                          role="button"
                          onClick={() => onSelect(item)}
                        >
                          <td className="px-3 py-2 text-left">{item.id}</td>
                        </div>
                        {/* Item Name */}
                        <td className="px-3 py-2 text-left">{item.name}</td>
                        <td
                          className="px-3 py-2  text-left"
                          // onClick={() => setUpdateData(d)}
                        >
                          {item.type ?? "--"}
                        </td>
                        <td className="px-3 py-2 text-left">
                          {item.msme_registration?.msme_registration_number}
                        </td>
                        {/* TODO: MPN Field is there */}
                        <td className="px-3 py-2 text-left">
                          {item.tax_detail.reg_type ?? "--"}
                        </td>
                        <td className="px-3 py-2 text-left">
                          {item.mail_detail.address.city ?? "--"}
                        </td>
                        <td className="px-3 py-2 text-left">
                          {item.mail_detail.address.state ?? "--"}
                        </td>
                        <td className="px-3 py-2 text-left">
                          {item.status ?? "--"}
                        </td>
                      </tr>
                    </>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={filter.count}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={
              (page) => setFilter((o) => ({ ...o, page }))
              // () => console.log("Pagination")
            }
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default PartyListScreen;
