import { StaticContext } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import accounts_routes from "../erp/accounts/routes";
import procurement_inventory_routes from "../erp/inventory/routes";
import purchase_order_routes from "../erp/purchase_order/routers";
import procurement_routes from "../procurement/routes";
import academy_routes from "./academy_routes";
import access_routes from "./access_routes";
import api_integration_routes from "./api_routes";
import auth_routes from "./auth_routes";
import complaint_routes from "./complaint_routes";
import content_panel_routes from "./content_panel_routes";
import hrm_routes from "./hrm_routes";
import inventory_routes from "./inventory_routes";
import landing_page_routes from "./landing_route";
import operation_routes from "./operation_routes";
import order_routes from "./order_routes";
import payment_routes from "./payment_routes";
import master_data_page_routes from "./master_data_routes";
import kpi_routes from "./kpi_routes";
import purchase_order_report_routes from "../erp/purchase_order_report/routes";

export interface RouteParam {
  fullScreen: boolean;
  exact?: boolean;
  path: string;
  component?:
    | React.ComponentType<any>
    | React.ComponentType<RouteComponentProps<any, StaticContext, unknown>>
    | undefined;
  main_module?: string;
  module?: string;
  action?: string | string[];
  operator?: "or" | "and";
  isPrivate: boolean;
  routes?: RouteParam[];
}

const main_routes: RouteParam[] = [
  ...auth_routes,
  ...landing_page_routes,
  ...order_routes,
  ...access_routes,
  ...payment_routes,
  ...complaint_routes,
  ...inventory_routes,
  ...operation_routes,
  ...api_integration_routes,
  ...hrm_routes,
  ...academy_routes,
  ...content_panel_routes,
  ...accounts_routes,
  ...procurement_inventory_routes,

  ...purchase_order_routes,
  ...master_data_page_routes,
  ...kpi_routes,
  ...purchase_order_report_routes,
];

export default main_routes;
