import { useEffect, useState } from "react";
import RequiredMark from "../../../ui/new_components/common/required_mark";
import { dataTypes } from "../../../utils/import_export_parser";
import { defaultKazamGST, KazamGST } from "../models/gst_model";
import { updateObject } from "../../../utils/formDataToJson";
import moment from "moment";
import GstAttachmentSection from "./components/gst_attachment";
import {
  AttachmentModel,
  getFileType,
} from "../../purchase_order/model/purchase_order_model";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { generate_id } from "../../inventory/journals/destination_stock_voucher/components/item_row_status";
import { file_dir, FileType, indianStates } from "../../../utils/enums";
import {
  getGstAttachmentSignedURLAPI,
  GetGSTRepoByID,
  GSTCreateRepo,
  uploadGstAttachmentFiletoS3API,
} from "./repo/gst_repo";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import { urls } from "../../../utils/urls";

const GstDetailScreen = () => {
  const { id } = useParams<{ id: string }>();
  const detail_page = useRouteMatch(
    urls.procurement_masters_gst + "/detail-screen/:id"
  );
  const { showToast } = useToast();
  const [kazamGst, setKazamGst] = useState<KazamGST>(defaultKazamGST);
  const [loading, setLoading] = useState(false);
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const navigation = useHistory();

  useEffect(() => {
    console.log("GST Object", kazamGst);
  }, [kazamGst]);

  const getData = async (id: string) => {
    setLoading(true);
    const res = await GetGSTRepoByID(id);
    if (res.success) {
      console.log(res);
      setKazamGst(res.data);
      setLoading(false);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id) getData(id);
  }, []);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    const type = e.target.getAttribute("data-type");

    const keys = name.split(".");
    const updatedValue = updateObject(kazamGst, keys, value, type);
    setKazamGst(updatedValue);
  };

  return (
    <div>
      <form
        id="gst_create_form"
        onSubmit={() => {
          console.log("FOrm Submitted");
        }}
        action="submit"
        method="POST"
        className="grid gap-2 p-5 "
      >
        {" "}
        <h1 className="m-4 text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
          Kazam GST Details
        </h1>
        <fieldset disabled>
          <div className="bg-white rounded-md p-4 w-3/4 max-w-3xl">
            <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 ">
              <div className="grid gap-1">
                <label htmlFor="gst_number" className="text-sm">
                  GST Number <RequiredMark />
                </label>
                <input
                  required
                  className="border rounded-md px-3 py-1 w-full"
                  type="text"
                  data-type={dataTypes.string}
                  id="gst_number"
                  name="gst_number"
                  value={kazamGst.gst_number}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-1">
                <label htmlFor="name" className="text-sm">
                  Name <RequiredMark />
                </label>
                <input
                  required
                  className="border rounded-md px-3 py-1 w-full"
                  type="text"
                  data-type={dataTypes.string}
                  id="name"
                  name="name"
                  value={kazamGst.name}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-1">
                <label htmlFor="address.state" className="text-sm">
                  State <RequiredMark />
                </label>
                {/* <input
              required
              className="border rounded-md px-3 py-1 w-full"
              type="text"
              data-type={dataTypes.string}
              id="address.state"
              name="address.state"
              value={kazamGst.address.state}
              onChange={handleChange}
            /> */}
                <select
                  id="address.state"
                  name="address.state"
                  className="p-1 rounded focus:outline-none w-full border"
                  value={kazamGst.address.state || ""} // Make sure it defaults to empty string if undefined
                  data-type={dataTypes.string}
                  // onChange={(newValue) => {
                  //   const selectedStateValue = (
                  //     newValue.target as HTMLSelectElement
                  //   ).value;

                  //   setKazamGst((prev: any) => ({
                  //     ...prev,
                  //     address: {
                  //       ...prev.mail_detail.address,
                  //       state: selectedStateValue, // Update the country field in the vendor state
                  //     },
                  //   }));
                  // }}
                  onChange={handleChange}
                >
                  <option value="">Select State</option>
                  {indianStates.map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid gap-1">
                <label htmlFor="address.city" className="text-sm">
                  City <RequiredMark />
                </label>
                <input
                  required
                  className="border rounded-md px-3 py-1 w-full"
                  type="text"
                  data-type={dataTypes.string}
                  id="address.city"
                  name="address.city"
                  value={kazamGst.address.city}
                  onChange={handleChange}
                />
              </div>
              {/* Address */}
              <div className="grid grid-cols-1">
                <div className="grid gap-1">
                  <label htmlFor="address.line1" className="text-sm">
                    Address <RequiredMark />
                  </label>
                  <input
                    required
                    className="border rounded-md px-3 py-1 w-full"
                    type="text"
                    data-type={dataTypes.string}
                    id="address.line1"
                    name="address.line1"
                    value={kazamGst.address.line1}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid grid-cols-1">
                  <div className="grid gap-1">
                    <label htmlFor="address.line2" className="text-sm">
                      Line 1 <RequiredMark />
                    </label>
                    <input
                      required
                      className="border rounded-md px-3 py-1 w-full"
                      type="text"
                      data-type={dataTypes.string}
                      id="address.line2"
                      name="address.line2"
                      value={kazamGst.address.line2}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="grid gap-1">
                    <label htmlFor="address.line3" className="text-sm">
                      Line 2
                    </label>
                    <input
                      className="border rounded-md px-3 py-1 w-full"
                      type="text"
                      data-type={dataTypes.string}
                      id="address.line3"
                      name="address.line3"
                      value={kazamGst.address.line3}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-1 self-start">
                <label htmlFor="address.country" className="text-sm">
                  Country <RequiredMark />
                </label>
                <input
                  required
                  disabled
                  className="border rounded-md px-3 py-1 w-full"
                  type="text"
                  data-type={dataTypes.string}
                  id="address.country"
                  name="address.country"
                  value={kazamGst.address.country}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-1 self-start">
                <label htmlFor="address.pincode" className="text-sm">
                  Pincode <RequiredMark />
                </label>
                <input
                  required
                  className="border rounded-md px-3 py-1 w-full"
                  type="text"
                  data-type={dataTypes.string}
                  id="address.pincode"
                  name="address.pincode"
                  value={kazamGst.address.pincode}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-1 self-start">
                <label htmlFor="effective_date_of_reg" className="text-sm">
                  Effective date of registration <RequiredMark />
                </label>
                <input
                  required
                  className="border rounded-md px-3 py-1 w-full"
                  type="date"
                  id="effective_date_of_reg"
                  name="effective_date_of_reg"
                  value={moment(kazamGst.effective_date_of_reg).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={handleChange}
                />
              </div>
            </section>
            <div>
              <div className="m-2 space-y-4 max-h-96 overflow-y-auto">
                {kazamGst?.attachments?.map((fileObj) => (
                  <div
                    key={fileObj.id}
                    className="flex items-center justify-between p-3 border rounded-md"
                  >
                    <div className="flex items-center gap-3">
                      {getFileType(fileObj.type) === FileType.IMAGE ? (
                        <img
                          src={fileObj.url}
                          alt={""}
                          className="w-16 h-16 object-cover"
                        />
                      ) : (
                        <a
                          href={fileObj.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-sm"
                        >
                          {fileObj.url}
                        </a>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </fieldset>
        <div className="flex justify-end gap-2">
          <button
            onClick={() => {
              navigation.goBack();
            }}
            type="button"
            className="rounded-md text-sm font-semibold bg-white text-black px-3 py-1"
          >
            Quit
          </button>
        </div>
      </form>
      {loading && <LoadingWidget />}
    </div>
  );
};

export default GstDetailScreen;
