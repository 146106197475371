import axios from "axios";
import { toQueryString } from "../../../../service/api_client";
import { EVehicle } from "../ui/pages/vehicles/ev/interface/e_vehicle_interface";
import { fetchGet, fetchPost, fetchPut } from "./serviceClient";

export const postEVehicle = async (form: FormData) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ev/post-ev`;

  const res = await fetchPost(url, form, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const getEVAttachmentSignedURLAPI = async (query: {
  mimetype: string;
  extension: string | undefined;
  dir: string;
}) => {
  const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
  const res = await fetchGet(get_signed_urls + toQueryString(query));
  return res;
};

export const uploadEVFiletoS3API = async ({
  signed_url,
  file,
  type,
}: {
  signed_url: string;
  file: File;
  type: string;
}) => {
  const res = await axios.put(signed_url, file, {
    headers: {
      "Content-Type": type,
    },
  });
  return res;
};

export const putEVehicle = async (form: FormData) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ev/update-ev`;
  const res = await fetchPut(url, form, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};
export const putEVehicleStatus = async (data: Partial<EVehicle>) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ev/update-ev`;
  console.log(data);
  const res = await fetchPut(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res;
};

export const postBulkEVData = async (data: File) => {
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ev/upload-csv`;
  const formData = new FormData();
  formData.append("file", data);
  const res = await fetchPost(url, formData, {
    headers: {
      "Content-Type": "multipart/formdata",
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};

export const getEVDataRepo = async ({
  category,
  search,
  page,
  count,
}: {
  category: string;
  search?: string;
  page: number;
  count: number;
}) => {
  const query_string = toQueryString({
    category,
    search,
    page,
    count,
  });
  console.log("query", query_string);
  const token = localStorage.getItem("token");
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v2/ev/${query_string}`;
  const res = await fetchGet(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return res;
};
