import { RouteParam } from "../../../routes";
import { MODULE, ACTION } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import LedgerEntriesListScreen from "../account_booking/ledger_summery/ledger_entries";
import LedgerSummeryListScreen from "../account_booking/ledger_summery/ledger_summery";
import OutstandingPayableEntriesListScreen from "../account_booking/outstanding/payable/entries_list";
import OutstandingPayableLedgerListScreen from "../account_booking/outstanding/payable/ledgers_list";
import OutstandingReceivableEntriesListScreen from "../account_booking/outstanding/receivable/entries_list";
import OutstandingReceivableLedgerListScreen from "../account_booking/outstanding/receivable/ledgers_list";
import ProcurementLandingPage from "../landing_page";
import credit_note_routes from "./credit_note_routes";
import debit_note_routes from "./debit_note_routes";
import ledgers_routes from "./ledgers_routes";
import ledger_group_routes from "./ledger_groups";
import party_routes from "./party_routes";
import payment_voucher_routes from "./payment_voucher_routes";
import purchase_voucher_routes from "./purchase_voucher_routes";
import receipt_voucher_routes from "./receipt_voucher_routes";
import sales_voucher_routes from "./sales_voucher_routes";
import gst_routes from "./gst_routes";

const accounts_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: "/procurement",
    component: ProcurementLandingPage,
    module: MODULE.procurement_panel,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.account_ledger_summery,
    component: LedgerSummeryListScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.account_ledger_entries + "/:id",
    component: LedgerEntriesListScreen,
    module: MODULE.procurement_master,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.outstanding_payable,
    component: OutstandingPayableLedgerListScreen,
    module: MODULE.procurement_panel,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.outstanding_payable + "/:id",
    component: OutstandingPayableEntriesListScreen,
    module: MODULE.procurement_panel,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.outstanding_receivable,
    component: OutstandingReceivableLedgerListScreen,
    module: MODULE.procurement_panel,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.outstanding_receivable + "/:id",
    component: OutstandingReceivableEntriesListScreen,
    module: MODULE.procurement_panel,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },

  ...ledger_group_routes,
  ...ledgers_routes,
  ...party_routes,
  ...gst_routes,
  ...payment_voucher_routes,
  ...purchase_voucher_routes,
  ...sales_voucher_routes,
  ...receipt_voucher_routes,
  ...debit_note_routes,
  ...credit_note_routes,
];

export default accounts_routes;
