import { FunctionComponent, useEffect, useState } from "react";
import LoadingWidget from "../../../../context_providers/modal/loader";
import { useToast } from "../../../../context_providers/toast/toast_ctx";

import { FileType } from "../../../../utils/enums";
import {
  AttachmentModel,
  getFileType,
} from "../../../purchase_order/model/purchase_order_model";

interface GSTAttachmentModalSectionProps {
  attachments: AttachmentModel[];
  onSubmit: (data: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => void;
  onDelete: (data: AttachmentModel) => void;
  onSelect: (data: AttachmentModel) => void;
}

const GSTAttachmentModalSection: FunctionComponent<
  GSTAttachmentModalSectionProps
> = (props) => {
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [file, setFile] = useState<File>();
  const [fileBloUrl, setFileBlobUrl] = useState<string>();
  const [fileType, setFileType] = useState<string>();
  const [showUpload, setShowUpload] = useState(false);
  const [attachments, setAttachments] = useState(props.attachments);

  useEffect(() => {
    if (!attachments || attachments.length === 0) {
      setShowUpload(true);
    }
  }, []);

  useEffect(() => {
    if (file) {
      const t = getFileType(file.type);
      setFileType(t);
      if ([FileType.IMAGE, FileType.PDF]) {
        setFileBlobUrl(URL.createObjectURL(file));
      }
    }
  }, [file]);
  const removeSelectedFile = () => {
    if (fileBloUrl) {
      URL.revokeObjectURL(fileBloUrl);
      setFileBlobUrl("");
      setFile(undefined);
    }
  };
  return (
    <>
      <div className="p-5 select-none">
        {attachments && attachments.length > 0 ? (
          <>
            <div className="grid grid-cols-7 text-sm font-semibold px-2">
              <div className="col-span-2">File</div>
              <div className="col-span-2">category</div>
            </div>
            <div
              className="flex flex-col gap-1 overflow-auto w-full  "
              style={{ height: "80vh" }}
            >
              {attachments &&
                attachments.length > 0 &&
                attachments.map((att, i) => (
                  <div className="grid grid-cols-7 rounded-lg border overflow-hidden justify-center items-center text-xs ">
                    <div
                      onClick={() => props.onSelect(att)}
                      // target="_blank"
                      // href={att.url}
                      className="rounded-lg h-16 w-16  col-span-2 flex justify-center items-center  cursor-pointer "
                    >
                      {att.type === FileType.IMAGE ? (
                        <img src={att.url} alt="" />
                      ) : (
                        <div className="text-sm font-semibold">{att.type}</div>
                      )}
                    </div>

                    <div className=" col-span-2">{att.category}</div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <div className="text-center text-gray-500 text-sm p-4">
            No attachments to show
          </div>
        )}
      </div>
      {loading && <LoadingWidget />}
    </>
  );
};

export default GSTAttachmentModalSection;
