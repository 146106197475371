import {
  mdiBank,
  mdiCurrencyRupee,
  mdiEmailFast,
  mdiLocationEnter,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../context_providers/toast/toast_ctx";
import { fetchDelete, fetchGet } from "../../../service/api_client";
import { ACTION, file_dir, MODULE } from "../../../utils/enums";
import { updateObject } from "../../../utils/formDataToJson";
import { urls } from "../../../utils/urls";

import {
  CityType,
  CountryType,
  PartyModel,
  PartyStatusMap,
  StateType,
  defaulPartyValue,
  taxRegType,
} from "../models/party_model";

import {
  AttachmentModel,
  getFileType,
} from "../../purchase_order/model/purchase_order_model";
import { dataTypes } from "../../../utils/import_export_parser";
import DoneWidget from "../../../ui/new_components/common/done";
import SingleAttachmentSection from "./components/single_attachmement";
import MultiAttachmentSection from "./components/multi_attachment";
import {
  getAttachmentSignedURLAPI,
  uploadFiletoS3API,
  VendorApproveRepo,
  VendorRejectRepo,
} from "./repo/parties_repo";
import { VendorAttachmentsTypes } from "./enums/vendor_enums";
import { useAuth } from "../../../context_providers/auth/authProvider";
import { generate_id } from "../../inventory/journals/destination_stock_voucher/components/item_row_status";
import { City, Country, State } from "country-state-city";

interface VendorApproveScreenProps {
  onClose: (data?: PartyModel) => void;
  data?: PartyModel;
}

const VendorApproveScreen: FunctionComponent<VendorApproveScreenProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const navigation = useHistory();
  const approve_page = useRouteMatch(
    urls.procurement_masters_party + "/approve-reject/:id"
  );
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [vendor, setVendor] = useState<PartyModel>({
    ...(props.data || defaulPartyValue),
  });

  const { user, isAuthorised } = useAuth();
  const [canEdit, setCanEdit] = useState<boolean>(false);
  const [isChecker, setIsChecker] = useState<boolean>(false);
  const [showSuccess, setShowSucess] = useState(false);
  const [creating, setCreating] = useState(false);
  const [attachmentKey, setAttachmentKey] = useState(Math.random() * 10000);
  const [newGstNo, setNewGstNo] = useState<string>();

  const [countries, setCountries] = useState<CountryType[]>([]);
  const [states, setStates] = useState<StateType[]>([]);
  const [cities, setCities] = useState<CityType[]>([]);

  // Selected values
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedState, setSelectedState] = useState<string>("");
  const [selectedCity, setSelectedCity] = useState<string>("");

  useEffect(() => {
    const allCountries = Country.getAllCountries();
    setCountries(allCountries);
  }, []);

  // Load states when a country is selected
  useEffect(() => {
    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
      const allStates = State.getStatesOfCountry(selectedCountry);
      setStates(allStates);
      setSelectedState(""); // Reset state and city when country changes
      setSelectedCity("");
    }
  }, [selectedCountry]);

  // Load cities when a state is selected
  useEffect(() => {
    if (selectedState) {
      const allCities = City.getCitiesOfState(selectedCountry, selectedState);
      console.log(allCities);
      // Ensure each city has an 'id' field (e.g., use the city's name or other unique value)
      const citiesWithId = allCities.map((city) => ({
        id: city.name, // Use 'name' or any other property to create an 'id'
        name: city.name,
      }));
      console.log("citiesWithId", citiesWithId);
      setCities(citiesWithId);
      setSelectedCity("");
    }
  }, [selectedState, selectedCountry]);

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    const type = e.target.getAttribute("data-type");
    const keys = name.split(".");
    const updatedValue = updateObject(vendor, keys, value, type);
    setVendor(updatedValue);
  };

  const handleAddGstNo = () => {
    if (newGstNo && !vendor.tax_detail.gst_no.includes(newGstNo)) {
      const updatedGstNoList = [...vendor.tax_detail.gst_no, newGstNo];
      setVendor({
        ...vendor,
        tax_detail: {
          ...vendor.tax_detail,
          gst_no: updatedGstNoList, // Add an empty string for a new input
        },
      });
      setNewGstNo(""); // Clear input after adding
    }
  };

  // Handles removing a GST number
  const removeGSTNumber = (index: number) => {
    const updatedGSTNumbers = vendor.tax_detail.gst_no.filter(
      (_, i) => i !== index
    );
    setVendor({
      ...vendor,
      tax_detail: {
        ...vendor.tax_detail,
        gst_no: updatedGSTNumbers,
      },
    });
  };

  const handleApprove = async (e: any) => {
    e.preventDefault();
    setCreating(true);

    try {
      const res = await VendorApproveRepo(vendor);
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!vendor.id) {
          setVendor(defaulPartyValue);
          // setShowSucess(true);
        }
        // setParty(res.data);
        setVendor((prevData) => ({
          ...prevData,
          ...res.data, // Update with the response data
        }));
        if (props.onClose) props.onClose(res.data);
        navigation.goBack();
      } else showToast({ type: ToastType.error, text: res.error });
    } catch (error) {
      console.log(error);
    }
    setCreating(false);
  };

  const handleReject = async (e: any) => {
    e.preventDefault();
    setCreating(true);

    try {
      const res = await VendorRejectRepo(vendor);
      if (res.success) {
        showToast({ type: ToastType.success, text: res.message });
        if (!vendor.id) {
          setVendor(defaulPartyValue);
          // setShowSucess(true);
        }
        // setParty(res.data);
        setVendor((prevData) => ({
          ...prevData,
          ...res.data, // Update with the response data
        }));
        if (props.onClose) props.onClose(res.data);
        navigation.goBack();
      } else showToast({ type: ToastType.error, text: res.error });
    } catch (error) {
      console.log(error);
    }
    setCreating(false);
  };

  useEffect(() => {
    let is_checker: Boolean = isAuthorised({
      module: MODULE.master_vendor_checker,
      action: ACTION.UPDATE,
    });
    if (is_checker) {
      setIsChecker(true);
      setCanEdit(true);
    } else {
      setIsChecker(false);
      setCanEdit(false);
    }

    console.log("Is Maker, canEdit", isChecker, canEdit);
  }, [user]);

  useEffect(() => {
    console.log("Attachments", vendor.attachments);
  }, [vendor]);

  useEffect(() => {
    if (id && approve_page) getData(id);
  }, []);

  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setVendor(res.data);
      setSelectedCountry(res.data.mail_detail.address.country);
      setSelectedState(res.data.mail_detail.address.state);
      setSelectedCity(res.data.mail_detail.address.city);
      if (res.data.status === PartyStatusMap.APPROVED) {
        setCanEdit(false);
      }
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const uploadFile = async ({
    file,
    category,
  }: {
    file: File;
    category: string;
  }) => {
    try {
      if (!file || !category) {
        showToast({
          type: ToastType.warning,
          text: "Please select file and category",
        });
        return;
      }
      setLoading(true);
      const { name, type } = file;
      // const create_url = `${url}/${vendor.id}/attachment`;

      const query = {
        mimetype: type,
        extension: name.split(".").pop(),
        dir: file_dir.party_attachments,
      };
      const res = await getAttachmentSignedURLAPI(query);
      if (res.success) {
        const { signed_url, url } = res.data;
        // const aws_res = await axios.put(signed_url, file, {
        //   headers: {
        //     "Content-Type": getFileType(type),
        //   },
        // });

        const aws_res = await uploadFiletoS3API({
          signed_url,
          file,
          type: getFileType(type),
        });

        const attachment: AttachmentModel = {
          id: generate_id(),
          category,
          type: getFileType(type),
          url,
        };
        showToast({
          type: ToastType.success,
          text: "Upload succesfull",
        });

        console.log(attachment);

        if (category === VendorAttachmentsTypes.msme) {
          setVendor((o) => {
            const updatedAttachments = (o.attachments || []).map((att) =>
              att.category === VendorAttachmentsTypes.msme ? attachment : att
            );

            // If no existing msme attachment, add the new one
            const hasMsme = updatedAttachments.some(
              (att) => att.category === VendorAttachmentsTypes.msme
            );
            if (!hasMsme) {
              updatedAttachments.push(attachment);
            }

            return {
              ...o,
              attachments: updatedAttachments,
            };
          });
        } else {
          setVendor((o) => ({
            ...o,
            attachments: [...(o.attachments || []), attachment],
          }));
        }
        setAttachmentKey(Math.random() * 1000);
        setLoading(false);
        return attachment;
      }

      setLoading(false);
    } catch (error: any) {
      showToast({
        type: ToastType.error,
        text: error.message,
      });
      setLoading(false);
    }
  };

  return (
    <>
      <section
        id="top_section"
        className="flex justify-between items-end my-1 "
      >
        <h1 className="m-4 border-l-4 border-myPrimaryColor pl-2 font-bold text-2xl text-gray-500border-l-4 flex items-center gap-3">
          Vendor Approve/Reject
        </h1>
      </section>

      <form
        id="party_create_form"
        onSubmit={handleApprove}
        className="grid gap-2 p-5 "
      >
        {/* {update_page && (
              <h1 className="  text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
                Party {id ? "update" : "create"}
              </h1>
            )} */}
        <fieldset disabled={!canEdit}>
          <section className="flex flex-col w-1/4 gap-3 m-2 rounded-md p-2">
            <div className="grid gap-1">
              <label htmlFor="name" className="text-sm">
                Vendor Name <RequiredMark />
              </label>
              <input
                required
                className="border rounded-md px-3 py-1 "
                type="text"
                data-type={dataTypes.string}
                id="name"
                name="name"
                value={vendor.name}
                onChange={handleChange}
              />
            </div>
            <div className="grid gap-1">
              <label htmlFor="type" className="text-sm">
                Vendor type <RequiredMark />
              </label>
              <select
                required
                className="border rounded-md px-3 py-1 capitalize"
                id="type"
                name="type"
                data-type={dataTypes.string}
                value={vendor.type}
                onChange={handleChange}
              >
                {" "}
                <option value=""> select</option>
                {["Service", "Product", "Both"].map((type: any) => (
                  <option value={type}>{type}</option>
                ))}
              </select>
            </div>
          </section>
          <section className="py-4">
            <h1 className="mb-2 font-semibold flex gap-2 items-center">
              <span className="text-blue-500">
                <Icon path={mdiEmailFast} size={0.8} />
              </span>
              Contact Details
            </h1>
            <div className="grid grid-flow-col grid-rows-1 sm:grid-rows-2 md:grid-rows-4 lg:grid-rows-4 gap-3 bg-white rounded-md p-2">
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_mobile" className="text-sm">
                  Contact No <RequiredMark />
                </label>
                <input
                  required
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_mobile"
                  name="mail_detail.contact_mobile"
                  value={vendor.mail_detail.contact_mobile}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_name" className="text-sm">
                  Contact Name <RequiredMark />
                </label>
                <input
                  required
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_name"
                  name="mail_detail.contact_name"
                  value={vendor.mail_detail.contact_name}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Email ID of Contact Person
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_email_contact_person"
                  name="mail_detail.contact_email_contact_person"
                  value={vendor.mail_detail.contact_email_contact_person}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Email ID of CEO/Director
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_email_ceo_director"
                  name="mail_detail.contact_email_ceo_director"
                  value={vendor.mail_detail.contact_email_ceo_director}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Email ID of Accounts SPOC
                </label>
                <input
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_email_account_spoc"
                  name="mail_detail.contact_email_account_spoc"
                  value={vendor.mail_detail.contact_email_account_spoc}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Email ID
                </label>
                <input
                  required
                  data-type={dataTypes.string}
                  type="email"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_email"
                  name="mail_detail.contact_email"
                  value={vendor.mail_detail.contact_email}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Designation
                </label>
                <input
                  required
                  data-type={dataTypes.string}
                  type="text"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.designation"
                  name="mail_detail.designation"
                  value={vendor.mail_detail.designation}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label
                  htmlFor="mail_detail.name_ceo_director"
                  className="text-sm"
                >
                  Name of CEO/Director
                </label>
                <input
                  data-type={dataTypes.string}
                  type="text"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.name_ceo_director"
                  name="mail_detail.name_ceo_director"
                  value={vendor.mail_detail.name_ceo_director}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Name of Accounts SPOC
                </label>
                <input
                  data-type={dataTypes.string}
                  type="text"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.name_account_spoc"
                  name="mail_detail.name_account_spoc"
                  value={vendor.mail_detail.name_account_spoc}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_email" className="text-sm">
                  Website
                </label>
                <input
                  data-type={dataTypes.string}
                  type="text"
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.website"
                  name="mail_detail.website"
                  value={vendor.mail_detail.website}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_mobile" className="text-sm">
                  Mobile No. of contact person <RequiredMark />
                </label>
                <input
                  required
                  type="number"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_mobile_contact_person"
                  name="mail_detail.contact_mobile_contact_person"
                  value={vendor.mail_detail.contact_mobile_contact_person}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_mobile" className="text-sm">
                  Contact Detail of CEO/Director <RequiredMark />
                </label>
                <input
                  type="text"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_detail_ceo_director"
                  name="mail_detail.contact_detail_ceo_director"
                  value={vendor.mail_detail.contact_detail_ceo_director}
                  onChange={handleChange}
                />
              </div>
              <div className="grid gap-0.5">
                <label htmlFor="mail_detail.contact_mobile" className="text-sm">
                  Mobile No. of Accounts SPOC <RequiredMark />
                </label>
                <input
                  type="number"
                  data-type={dataTypes.string}
                  className="border rounded-md px-3 py-1"
                  id="mail_detail.contact_mobile_accounts_spoc"
                  name="mail_detail.contact_mobile_accounts_spoc"
                  value={vendor.mail_detail.contact_mobile_accounts_spoc}
                  onChange={handleChange}
                />
              </div>
            </div>
          </section>

          <div className="grid grid-cols-1  md:grid-cols-2 gap-3">
            {/* Address Start */}
            <section className="py-4 bg-white rounded-md p-2 gap-2">
              <h1 className="mb-2 font-semibold flex gap-2 items-center">
                <span className="text-green-500">
                  <Icon path={mdiLocationEnter} size={0.7} />
                </span>
                Address Details
              </h1>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                <div>
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.line1"
                      className="text-sm"
                    >
                      Registered office address
                      <RequiredMark />
                    </label>
                    <input
                      required
                      type="text"
                      data-type={dataTypes.string}
                      className="border rounded-md px-3 py-1"
                      id="mail_detail.address.line1"
                      name="mail_detail.address.line1"
                      value={vendor.mail_detail.address.line1}
                      onChange={handleChange}
                      placeholder="Line 1"
                    />
                  </div>
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.line2"
                      className="text-sm"
                    >
                      Line 2
                    </label>
                    <input
                      type="text"
                      data-type={dataTypes.string}
                      className="border rounded-md px-3 py-1"
                      id="mail_detail.address.line2"
                      name="mail_detail.address.line2"
                      value={vendor.mail_detail.address.line2}
                      onChange={handleChange}
                      placeholder="Line 2"
                    />
                  </div>
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.line3"
                      className="text-sm"
                    >
                      Line 3
                    </label>
                    <input
                      type="text"
                      data-type={dataTypes.string}
                      className="border rounded-md px-3 py-1"
                      id="mail_detail.address.line3"
                      name="mail_detail.address.line3"
                      value={vendor.mail_detail.address.line3}
                      onChange={handleChange}
                      placeholder="Line 3"
                    />
                  </div>
                  <div className="grid gap-0.5">
                    <label className="font-semibold w-full" htmlFor="country">
                      Country
                      <RequiredMark />
                    </label>
                    <select
                      id="mail_detail.address.country"
                      name="mail_detail.address.country"
                      data-type={dataTypes.string}
                      className="p-1 rounded focus:outline-none w-full border"
                      value={vendor.mail_detail.address.country || ""} // Make sure it defaults to empty string if undefined
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setSelectedCountry(newValue);
                        handleChange(e);
                      }}
                    >
                      <option value="">Select Country</option>
                      {countries.map((country) => (
                        <option key={country.isoCode} value={country.isoCode}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div>
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.state"
                      className="text-sm"
                    >
                      State <RequiredMark />
                    </label>
                    <select
                      id="mail_detail.address.state"
                      name="mail_detail.address.state"
                      data-type={dataTypes.string}
                      className="p-1 rounded focus:outline-none w-full border"
                      value={vendor.mail_detail.address.state || ""} // Make sure it defaults to empty string if undefined
                      onChange={(e) => {
                        const newValue = e.target.value;
                        console.log("New Value", newValue);
                        setSelectedState(newValue);
                        handleChange(e);
                      }}
                    >
                      <option value="">Select State</option>
                      {states.map((state) => (
                        <option key={state.isoCode} value={state.isoCode}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="grid gap-1">
                    <label
                      htmlFor="mail_detail.address.city"
                      className="text-sm"
                    >
                      City <RequiredMark />
                    </label>
                    <select
                      id="mail_detail.address.city"
                      name="mail_detail.address.city"
                      data-type={dataTypes.string}
                      className="p-1 rounded focus:outline-none w-full border"
                      value={vendor.mail_detail.address.city || ""} // Make sure it defaults to empty string if undefined
                      onChange={(e) => {
                        const newValue = e.target.value;
                        setSelectedCity(newValue);
                        handleChange(e);
                      }}
                    >
                      <option value="">Select City</option>
                      {cities.map((city) => (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <div className="grid gap-1">
                      <label
                        htmlFor="mail_detail.address.pincode"
                        className="text-sm"
                      >
                        Postal code
                      </label>
                      <input
                        type="text"
                        data-type={dataTypes.string}
                        className="border rounded-md px-3 py-1"
                        id="mail_detail.address.pincode"
                        name="mail_detail.address.pincode"
                        value={vendor.mail_detail.address.pincode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* Address End */}

            {/* MSME Start */}
            <section className="py-4 bg-white rounded-md p-2 gap-2">
              <div>
                <h1 className="mb-2 font-semibold flex gap-2 items-center">
                  <span className="text-green-500">
                    <Icon path={mdiLocationEnter} size={0.7} />
                  </span>
                  MSME Registration
                  <div className="flex flex-row gap-6">
                    <div className="flex gap-3 items-center">
                      <input
                        type={dataTypes.radio}
                        data-type={dataTypes.boolean}
                        id="msme_registration_applicable"
                        name="msme_registration_applicable"
                        value={String(
                          vendor.msme_registration_applicable === false
                        )}
                        checked={vendor.msme_registration_applicable === true}
                        onChange={handleChange}
                        disabled={false}
                      />
                      <label htmlFor="msme_registration">Yes</label>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        type={dataTypes.radio}
                        data-type={dataTypes.boolean}
                        id="msme_registration_applicable"
                        name="msme_registration_applicable"
                        value={String(
                          vendor.msme_registration_applicable === false
                        )}
                        checked={vendor.msme_registration_applicable === false}
                        onChange={handleChange}
                        disabled={false}
                      />
                      <label htmlFor="msme_registration">No</label>
                    </div>
                  </div>
                </h1>

                <div>
                  {vendor.msme_registration_applicable && (
                    <>
                      <div>
                        <div className="grid gap-1 m-2 text-sm">
                          <label
                            className="w-full"
                            htmlFor="msme_registration_applicable"
                          >
                            MSME Category
                            <RequiredMark />
                          </label>
                          <select
                            value={vendor.msme_registration.msme_category}
                            disabled={false}
                            onChange={handleChange}
                            name="msme_registration.msme_category"
                            id="msme_registration.msme_category"
                            className="p-1 rounded focus:outline-none w-full border"
                          >
                            <option value="default">
                              Select a MSME category
                            </option>
                            {["Micro", "Small", "Medium"].map((type, i) => (
                              <option value={type} key={i}>
                                {type.toUpperCase()}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="grid gap-1 m-2">
                          <label
                            htmlFor="msme_registration.msme_registration_number"
                            className="text-sm"
                          >
                            MSME Registration number <RequiredMark />
                          </label>
                          <input
                            required
                            type="text"
                            data-type={dataTypes.string}
                            className="border rounded-md px-3 py-1"
                            id="msme_registration.msme_registration_number"
                            name="msme_registration.msme_registration_number"
                            value={
                              vendor.msme_registration.msme_registration_number
                            }
                            onChange={handleChange}
                          />
                        </div>
                        <div className="grid gap-1 m-2"></div>
                      </div>
                      <div>
                        <SingleAttachmentSection
                          attachments={vendor.attachments.filter(
                            (attachment) =>
                              attachment.category ===
                              VendorAttachmentsTypes.msme
                          )}
                          category={VendorAttachmentsTypes.msme}
                          onSubmit={function (data: {
                            file: File;
                            category: string;
                            removeSelectedFile: () => void;
                            setShowUpload: (v: boolean) => void;
                          }): void {
                            uploadFile({
                              file: data.file,
                              category: VendorAttachmentsTypes.msme,
                            });
                            throw new Error("Function not implemented.");
                          }}
                          onDelete={(data: AttachmentModel): void => {
                            console.log("OnDelete MSME invoked");
                            console.log(data);
                            setVendor((prevState) => ({
                              ...prevState,
                              attachments: prevState.attachments.filter(
                                (attachment) => attachment.url !== data.url
                              ),
                            }));

                            showToast({
                              type: ToastType.success,
                              text: "Attachment removed successfully",
                            });
                          }}
                          onSelect={function (data: AttachmentModel): void {
                            throw new Error("Function not implemented.");
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </section>
            {/* MSME end */}
          </div>

          <div className="grid grid-cols-1  md:grid-cols-2 gap-3  ">
            <section className="py-4">
              <h1 className="mb-2 font-semibold flex gap-2 items-center">
                <span className="text-green-500">
                  <Icon path={mdiBank} size={0.7} />
                </span>
                Bank Details
              </h1>
              <div className="grid grid-cols-1  md:grid-cols-2 gap-3 bg-white rounded-md p-2">
                <div className="grid gap-0.5">
                  <label
                    htmlFor="bank_detail.account_holder_name"
                    className="text-sm"
                  >
                    Account holder name.
                  </label>
                  <input
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.account_holder_name"
                    name="bank_detail.account_holder_name"
                    value={vendor.bank_detail.account_holder_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.ac_no" className="text-sm">
                    Account no.
                  </label>
                  <input
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.ac_no"
                    name="bank_detail.ac_no"
                    value={vendor.bank_detail.ac_no}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.bank_name" className="text-sm">
                    Bank Name
                  </label>
                  <input
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.bank_name"
                    name="bank_detail.bank_name"
                    value={vendor.bank_detail.bank_name}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.bank_address" className="text-sm">
                    Bank Address
                  </label>
                  <textarea
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.bank_address"
                    name="bank_detail.bank_address"
                    value={vendor.bank_detail.bank_address}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  {vendor.mail_detail.address.country === "India" ? (
                    <div className="grid gap-0.5">
                      <label htmlFor="bank_detail.ifsc" className="text-sm">
                        IFSC
                      </label>
                      <input
                        type="text"
                        data-type={dataTypes.string}
                        className="border rounded-md px-3 py-1"
                        id="bank_detail.ifsc"
                        name="bank_detail.ifsc"
                        value={vendor.bank_detail.ifsc}
                        onChange={handleChange}
                      />
                    </div>
                  ) : (
                    <div className="grid gap-0.5">
                      <label
                        htmlFor="bank_detail.swift_code"
                        className="text-sm"
                      >
                        Swift code
                      </label>
                      <input
                        type="text"
                        data-type={dataTypes.string}
                        className="border rounded-md px-3 py-1"
                        id="bank_detail.swift_code"
                        name="bank_detail.swift_code"
                        value={vendor.bank_detail.swift_code}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="bank_detail.bank_name" className="text-sm">
                    MICR Code
                  </label>
                  <input
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="bank_detail.micr_code"
                    name="bank_detail.micr_code"
                    value={vendor.bank_detail.micr_code}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </section>

            <section className="py-4">
              <h1 className="mb-2 font-semibold flex gap-2 items-center">
                <span className="text-green-500">
                  <Icon path={mdiCurrencyRupee} size={0.7} />
                </span>
                GST Details
              </h1>
              <div className="bg-white rounded-md p-2">
                <div className="gap-3 ">
                  <div className="grid gap-0.5">
                    <label htmlFor="tax_detail.reg_type" className="text-sm">
                      GST Registration Type
                    </label>
                    <select
                      id="tax_detail.reg_type"
                      name="tax_detail.reg_type"
                      className="border rounded-md px-3 py-1"
                      data-type={dataTypes.string}
                      value={vendor.tax_detail.reg_type}
                      onChange={handleChange}
                    >
                      {Object.values(taxRegType).map((type) => (
                        <option value={type}>{type}</option>
                      ))}
                    </select>
                  </div>
                  <div className="gap-1">
                    <label htmlFor="tax_detail.gst_no" className="text-sm">
                      GST Nos.
                    </label>
                    <div>
                      <div className="flex justify-between gap-2">
                        <input
                          type="text"
                          data-type={dataTypes.string}
                          id="tax_detail.gst_no"
                          name="tax_detail.gst_no"
                          className="border rounded-md px-3 py-1 flex-grow" // Add `flex-grow` to expand the input
                          value={newGstNo || ""}
                          onChange={(e) => {
                            console.log(e.target.value);
                            setNewGstNo(e.target.value);
                          }}
                        />
                        <button
                          type="button"
                          onClick={handleAddGstNo}
                          className="px-3 py-1 bg-blue-500 text-white rounded-md"
                        >
                          Add
                        </button>
                      </div>

                      <div className="mt-2">
                        {vendor.tax_detail.gst_no.length > 0 && (
                          <ul className="list-disc pl-5">
                            {vendor.tax_detail.gst_no.map((gstNo, index) => (
                              <div className="">
                                <li
                                  key={index}
                                  className="flex justify-between items-center"
                                >
                                  <span>{gstNo}</span>
                                  <button
                                    type="button"
                                    onClick={() => removeGSTNumber(index)}
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    Remove
                                  </button>
                                </li>
                              </div>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <MultiAttachmentSection
                    attachments={vendor.attachments.filter(
                      (attachment) =>
                        attachment.category === VendorAttachmentsTypes.gst
                    )}
                    onSubmit={async function (data: {
                      file: File;
                      category: string;
                    }): Promise<AttachmentModel> {
                      const attachment = await uploadFile({
                        file: data.file,
                        category: data.category,
                      });
                      if (!attachment) {
                        throw new Error("File upload failed");
                      }
                      return attachment;
                    }}
                    category={VendorAttachmentsTypes.gst}
                    onDelete={(data: AttachmentModel): void => {
                      console.log("OnDelete GST invoked");
                      setVendor((prevState) => ({
                        ...prevState,
                        attachments: prevState.attachments.filter(
                          (attachment) => attachment.id !== data.id
                        ),
                      }));

                      showToast({
                        type: ToastType.success,
                        text: "Attachment removed successfully",
                      });
                    }}
                  />
                </div>
              </div>
            </section>
          </div>

          <div className="grid grid-cols-1  md:grid-cols-2 gap-3  ">
            <section className="py-4 bg-white rounded-md p-2">
              <div className="grid grid-cols-2 gap-2">
                <div className="grid gap-0.5">
                  <label htmlFor="tax_detail.pan" className="text-sm">
                    PAN <RequiredMark />
                  </label>
                  <input
                    required
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="tax_detail.pan"
                    name="tax_detail.pan"
                    value={vendor.tax_detail.pan}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="tax_detail.pan" className="text-sm">
                    ADHAAR Number
                  </label>
                  <input
                    required
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="adhaar_number"
                    name="adhaar_number"
                    value={vendor.adhaar_number}
                    onChange={handleChange}
                  />
                </div>
                <div className="grid gap-0.5">
                  <label htmlFor="tax_detail.pan" className="text-sm">
                    CIN/LLPIN Number
                  </label>
                  <input
                    required
                    type="text"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="cin_llpin_number"
                    name="cin_llpin_number"
                    value={vendor.cin_llpin_number}
                    onChange={handleChange}
                  />
                </div>

                <div className="grid gap-0.5">
                  <label htmlFor="date_of_commencement" className="text-sm">
                    Date of Commencement of Business
                  </label>
                  <input
                    required
                    type="date" // Changed from "text" to "date"
                    data-type={dataTypes.string}
                    className="border rounded-md px-3 py-1"
                    id="date_of_commencement"
                    name="date_of_commencement"
                    value={vendor.date_of_commencement}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </section>

            <section className="py-4 bg-white rounded-md p-2">
              <MultiAttachmentSection
                attachments={vendor.attachments.filter(
                  (attachment) =>
                    attachment.category === VendorAttachmentsTypes.common
                )}
                onSubmit={async function (data: {
                  file: File;
                  category: string;
                }): Promise<AttachmentModel> {
                  await uploadFile(data);
                  return {
                    id: generate_id(),
                    category: data.category,
                    type: getFileType(data.file.type),
                    url: "", // You need to return the actual URL here
                  };
                }}
                category={VendorAttachmentsTypes.common}
                onDelete={(data: AttachmentModel): void => {
                  console.log("OnDelete GST invoked");
                  setVendor((prevState) => ({
                    ...prevState,
                    attachments: prevState.attachments.filter(
                      (attachment) => attachment.id !== data.id
                    ),
                  }));

                  showToast({
                    type: ToastType.success,
                    text: "Attachment removed successfully",
                  });
                }}
              />
            </section>
          </div>
          <div className="flex flex-row justify-end gap-2 m-2">
            <div className="flex gap-2">
              <button
                onClick={() => {
                  navigation.goBack();
                }}
                type="button"
                className="rounded-md text-sm font-semibold bg-white text-black px-3 py-1"
              >
                Quit
              </button>
              <button
                onClick={handleApprove}
                form="party_create_form"
                type="button"
                className="bg-myPrimaryColor text-white rounded-lg px-6 py-1.5 text-sm cursor-pointer duration-300"
              >
                Approve
              </button>
            </div>
            <div className="flex gap-2">
              <button
                onClick={handleReject}
                form="party_create_form"
                type="button"
                className="bg-red-400 text-white rounded-lg px-6 py-1.5 text-sm cursor-pointer duration-300"
              >
                Reject
              </button>
            </div>
          </div>
        </fieldset>
      </form>
      {creating && <LoadingWidget />}
    </>
  );
};

const RequiredMark = () => (
  <>
    <span className="text-red-500">*</span>
  </>
);

export default VendorApproveScreen;
