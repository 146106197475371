import {
  AttachmentModel,
  PurchaseOrderModel,
} from "../../purchase_order/model/purchase_order_model";

export interface POReportModel extends Omit<PurchaseOrderModel, "attachments"> {
  attachment: AttachmentModel;
}

export interface POReportFilterModel {
  page: number;
  count: number;
  all: boolean;
  fromDate: Date | null;
  toDate: Date | null;
}

export const POReportDefaultFilter = (): POReportFilterModel => {
  const now = new Date();
  // const { start_date, end_date } = GetStartAndEndDateOfMonth(now);
  const start_date = new Date(new Date(now).setDate(now.getDate() - 30));
  const end_date = now;
  return {
    page: 1,
    count: 50,
    all: false,
    fromDate: null,
    toDate: null,
  };
};
