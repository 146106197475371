import { mdiArrowLeft, mdiDelete, mdiUpload } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import LoadingWidget from "../../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import FileDropZone from "../../../../ui/new_components/common/file_drop_zone";
import { FileType, file_dir } from "../../../../utils/enums";
import {
  AttachmentModel,
  getFileType,
} from "../../../purchase_order/model/purchase_order_model";

interface SingleAttachmentSectionProps {
  attachments: AttachmentModel[];
  category: string;
  onSubmit: (data: {
    file: File;
    category: string;
    removeSelectedFile: () => void;
    setShowUpload: (v: boolean) => void;
  }) => void;
  onDelete: (data: AttachmentModel) => void;
  onSelect: (data: AttachmentModel) => void;
}

const SingleAttachmentSection: FunctionComponent<
  SingleAttachmentSectionProps
> = (props) => {
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [file, setFile] = useState<File | null>(null);
  const [showUpload, setShowUpload] = useState(props.attachments.length === 0);

  useEffect(() => {
    setShowUpload(props.attachments.length === 0);
  }, [props.attachments]);

  const removeSelectedFile = () => {
    setFile(null);
    setShowUpload(true);
  };

  return (
    <div className="p-5 select-none">
      {/* {!showUpload && (
        <div className="flex justify-end">
          <button
            onClick={() => setShowUpload(true)}
            className="rounded-lg border px-3 py-1 text-sm flex items-center gap-1"
          >
            <Icon path={mdiUpload} size={0.8} /> Upload
          </button>
        </div>
      )} */}
      {showUpload ? (
        <div className="p-5 relative">
          {loading && (
            <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
              <LoadingWidget />
            </div>
          )}
          <FileDropZone
            onDrop={(files: File[]) => {
              if (files[0]) {
                setFile(files[0]);
                setShowUpload(false);
                setLoading(true);
                props.onSubmit({
                  file: files[0],
                  category: props.category,
                  removeSelectedFile,
                  setShowUpload,
                });
                setTimeout(() => setLoading(false), 2000); // Simulating upload time
              }
            }}
          />
        </div>
      ) : (
        <div className="space-y-4 max-h-96 overflow-y-auto">
          {props.attachments
            .filter((att) => att.category === props.category)
            .map((att) => (
              <div
                key={att.id}
                className="flex items-center justify-between p-3 border rounded-md"
              >
                <div className="flex items-center gap-3">
                  {getFileType(att.type) === FileType.IMAGE ? (
                    <img
                      src={att.url}
                      alt={att.url}
                      className="w-16 h-16 object-cover"
                    />
                  ) : (
                    <a
                      href={att.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-sm"
                    >
                      {att.url}
                    </a>
                  )}
                </div>
                <button
                  onClick={() => {
                    props.onDelete(att);
                    setShowUpload(true);
                  }}
                  className="p-2 bg-red-500 text-white rounded-full"
                >
                  <Icon path={mdiDelete} size={0.8} />
                </button>
              </div>
            ))}
        </div>
      )}
      {loading && <LoadingWidget />}
    </div>
  );
};

export default SingleAttachmentSection;
