import { id } from "date-fns/locale";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LoadingWidget from "../../../../../context_providers/modal/loader";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  fetchGet,
  fetchPost,
  fetchPut,
} from "../../../../../service/api_client";
import { numberRegEx } from "../../../../../utils/constant";
import { UTCToLocalDate } from "../../../../../utils/date_util";
import LedgerSearchField from "../../../../accounts/ledgers/ledger_search_field";
import {
  PaymentEntryType,
  accountTxnType,
  LedgerEntryModel,
} from "../../../../accounts/models/common_model";
import { ledgerEntryDefaultValue } from "../../../../accounts/models/purchase_model";
import { SalesVoucherModel } from "../../../../accounts/models/sale_model ";
import ItemSearchField from "../../../items/item_search_field";
import {
  itemMovementDefaultValue,
  ItemMovementModel,
  stockManufacturingDefaultValue,
  StockManufacturingJournalModel,
} from "../../../models/inventory_voucher";
import { BomItem, ItemModel } from "../../../models/item_model";
import WarehouseSearchField from "../../../warehouse/warehouse_search_field";
import AdditionalCostRow from "./components/additional_cost_row";
import ComponentItemRow from "./components/bom_item_row";
import { WAREHOUSE_TYPE } from "../../../models/warehouse_model";

interface ManufacturingJournalProps {
  location?: any;
}

const ManufacturingJournal: FunctionComponent<ManufacturingJournalProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/journal/manufacturing`;
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const [data, setData] = useState<StockManufacturingJournalModel>(
    stockManufacturingDefaultValue
  );
  useEffect(() => {
    // setData(props.location.state || stockManufacturingDefaultValue);
    if (id) getVoucher(id);
  }, []);
  const getVoucher = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  const getBom = async (item: ItemModel) => {
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items/${item.id}/bom`;
    setLoading(true);
    const res = await fetchGet(url);
    if (res.success) {
      setData((o) => ({
        ...o,
        component_entries: [
          ...res.data.items.map((b: BomItem) => ({
            ...itemMovementDefaultValue,
            ...b,
            // item: b.item,
            // item_id: b.item_id,
            // unit_no: b.unit_no,
            // price: b.price,
            warehouse: o.product_item_entry.warehouse,
            warehouse_id: o.product_item_entry.warehouse_id,
          })),
        ],
        product_item_entry: {
          ...o.product_item_entry,
          item: item,
          item_id: item.id,
          unit_no: item.bom.unit_no,
        },
      }));
      setData((o) => ({
        ...o,
        product_item_entry: {
          ...o.product_item_entry,
          item: { ...o.product_item_entry.item!, bom: res.data },
        },
      }));
    } else {
      showToast({ type: ToastType.error, text: res.error });
      setData((o) => ({
        ...o,
        product_item_entry: {
          ...o.product_item_entry,
          item: {
            ...o.product_item_entry.item!,
            bom: { ...o.product_item_entry.item!.bom, items: [] },
          },
        },
      }));
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   if (data.product_item_entry.item_id)
  //     getBom(data.product_item_entry.item_id);
  // }, [data.product_item_entry.item]);
  useEffect(() => {
    setData((o) => ({
      ...o,
      component_cost: (o.component_entries || [])?.reduce(
        (acc, val) => acc + val.unit_no * val.price,
        0
      ),
    }));
  }, [data.component_entries]);
  useEffect(() => {
    setData((o) => ({
      ...o,
      additional_cost: (o.additional_cost_entries || [])?.reduce(
        (acc, val) => acc + val.amount,
        0
      ),
    }));
  }, [data.additional_cost_entries]);

  const submit = async () => {
    if (!data.product_item_entry?.item)
      return showToast({
        type: ToastType.error,
        text: "Please select product item !",
      });
    if (!data.product_item_entry.warehouse.id)
      return showToast({
        type: ToastType.error,
        text: "Please select warehouse !",
      });
    if (!data.product_item_entry.unit_no)
      return showToast({
        type: ToastType.error,
        text: "Product unit must be greater then 1",
      });
    if (!data.component_entries?.length)
      return showToast({
        type: ToastType.error,
        text: "Please add component consumption",
      });

    setLoading(true);
    data.total = data.component_cost + data.additional_cost;
    data.product_item_entry.price =
      data.total / data.product_item_entry.unit_no;
    const res = id
      ? await fetchPut(url + "/" + id, data)
      : await fetchPost(url, data);
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      // props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  // if (loading) return <LoadingWidget />;
  // console.log({ data });

  return (
    <>
      <div className=" mt-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Manufacturing journal
          </div>
          <div className="flex gap-2 items-center">
            <label htmlFor="" className="text-sm font-semibold text-gray-500">
              Date
            </label>{" "}
            <input
              type="date"
              className=" focus:outline-none focus:ring-2 ring-blue-400 rounded-md py-1 px-2 w-full text-sm border"
              value={data.date ? moment(data.date).format("YYYY-MM-DD") : ""}
              onChange={(e) => {
                const date = UTCToLocalDate(e.target.value)!;
                setData((o) => ({
                  ...o,
                  date: date,
                }));
              }}
            />
          </div>
        </div>
        <div className="bg-white rounded-lg px-3 py-5">
          <div className="grid grid-cols-3 gap-5 ">
            <div className="grid grid-cols-4 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Product
              </label>
              <div className="col-span-3">
                <ItemSearchField
                  placeholder="Manufacture product"
                  value={
                    data.product_item_entry.item?.id
                      ? data.product_item_entry.item
                      : undefined
                  }
                  onSelect={(d) => {
                    getBom(d);
                  }}
                />
              </div>
            </div>

            <div className="grid grid-cols-4 items-center ">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Warehouse
              </label>
              <div className="col-span-3">
                <WarehouseSearchField
                  placeholder="Warehouse"
                  value={data.product_item_entry.warehouse}
                  onSelect={(d) => {
                    setData((old) => {
                      return {
                        ...old,
                        product_item_entry: {
                          ...old.product_item_entry,
                          warehouse: d,
                          warehouse_id: d.id,
                        },
                        component_entries: [
                          ...(!old.product_item_entry.item?.bom
                            ? []
                            : old.product_item_entry.item.bom.items.map(
                                (b) => ({
                                  ...itemMovementDefaultValue,
                                  ...b,
                                  item: b.item,
                                  item_id: b.item_id,
                                  unit_no: b.unit_no,
                                  warehouse: d,
                                  warehouse_id: d.id,
                                })
                              )),
                        ],
                      };
                    });
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-4 items-center ">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Qunatity
              </label>

              <input
                value={data?.product_item_entry.unit_no}
                onChange={(e) => {
                  const val = e.target.value.trim();
                  if (!val || numberRegEx.test(val)) {
                    const value = Number(val || 0);
                    setData((old) => {
                      return {
                        ...old,
                        component_entries: (old.component_entries || []).map(
                          (v) => {
                            let unit_no = v.unit_no;
                            if (old.product_item_entry.item?.bom.items)
                              for (
                                let i = 0;
                                i <
                                old.product_item_entry.item?.bom.items.length;
                                i++
                              ) {
                                const item =
                                  old.product_item_entry.item?.bom.items[i];
                                if (item.item_id === v.item_id) {
                                  unit_no = item.unit_no * value;
                                  break;
                                }
                              }
                            return { ...v, unit_no };
                          }
                        ),
                        product_item_entry: {
                          ...old.product_item_entry,
                          unit_no: value,
                        },
                      };
                    });
                  }
                }}
                //   disabled={!editable || props.disabled}
                placeholder="Manufacture Qty"
                type="text"
                className="text-right  p-1 focus:outline-none border rounded col-span-3"
              />
            </div>
            <div className="grid grid-cols-4 items-center">
              <label htmlFor="" className="text-sm font-semibold text-gray-500">
                Bom
              </label>
              <div className=" p-1   border rounded col-span-3">
                {data.product_item_entry.item?.bom.name || "---"}
              </div>
            </div>
          </div>
        </div>

        <div className="my-5">
          <h3 className="text-sm font-bold">Components(consumption)</h3>
          <div className="bg-white rounded flex flex-col gap-1">
            <div className="grid grid-cols-7 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
              <div className="col-span-2">Particular</div>
              <div className="text-center">Qty</div>
              <div className="text-right">Rate</div>
              <div className="text-right">Amount {`\u20b9`}</div>
            </div>
            {data.component_entries?.map((comp) => {
              return (
                <ComponentItemRow
                  component={comp}
                  edit={false}
                  disabled={false}
                  key={comp.id}
                  onSubmit={function (data: ItemMovementModel): void {
                    setData((o) => ({
                      ...o,
                      component_entries: [
                        ...(o.component_entries || []).map((it) => {
                          if (it.id === data.id) return data;

                          return it;
                        }),
                      ],
                    }));
                  }}
                />
              );
            })}
            <ComponentItemRow
              key={(Math.random() * 1000).toString()}
              onSubmit={function (data: ItemMovementModel): void {
                data.id = (Math.random() * 1000).toString();
                setData((o) => ({
                  ...o,
                  component_entries: [...(o.component_entries || []), data],
                }));
              }}
              edit={true}
              disabled={false}
            />
          </div>
        </div>
        <div className="my-5">
          <h3 className="text-sm font-bold">Additional cost</h3>
          <div className="bg-white rounded flex flex-col gap-1">
            <div className="grid grid-cols-4 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t">
              <div className="col-span-2">Particular</div>

              <div className="text-center">Amount</div>
            </div>
            {data.additional_cost_entries?.map((item) => {
              return (
                <AdditionalCostRow
                  item={item}
                  edit={false}
                  disabled={false}
                  key={item.id}
                  onSubmit={function (data: LedgerEntryModel): void {
                    setData((o) => {
                      let additional_cost = o.additional_cost || 0;
                      return {
                        ...o,
                        additional_cost_entries: [
                          ...(o.additional_cost_entries || []).map((it) => {
                            if (it.id === data.id) {
                              additional_cost += data.amount - it.amount;
                              return data;
                            }

                            return it;
                          }),
                        ],
                        additional_cost,
                      };
                    });
                  }}
                />
              );
            })}
            <AdditionalCostRow
              key={(Math.random() * 1000).toString()}
              onSubmit={function (data: LedgerEntryModel): void {
                data.id = (Math.random() * 1000).toString();
                setData((o) => ({
                  ...o,
                  additional_cost: o.additional_cost + data.amount,
                  additional_cost_entries: [
                    ...(o.additional_cost_entries || []),
                    data,
                  ],
                }));
              }}
              edit={true}
              disabled={false}
            />
          </div>
        </div>

        <div className="">
          <div className="text-sm flex justify-end">
            <div className="flex flex-col gap-2">
              <div className=" flex gap-5 justify-between">
                <div className="">Components cost:</div>
                <div className="">{data.component_cost || 0}</div>
              </div>

              <div className=" flex gap-5 justify-between">
                <div className="">Additional cost:</div>
                <div className="">{data.additional_cost || 0}</div>
              </div>
              <div className=" flex gap-5 justify-between font-bold">
                <div className="">Total:</div>
                <div className="">
                  {data.component_cost + data.additional_cost || 0}
                </div>
              </div>
              <div className=" flex gap-5 justify-between font-bold">
                <div className="">Cost per unit:</div>
                <div className="">
                  {(data.component_cost + data.additional_cost) /
                    data.product_item_entry.unit_no || 0}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className=" flex justify-end px-6 py-2">
        <button
          onClick={submit}
          className="px-10 py-1 rounded-md bg-myPrimaryColor text-white text-sm "
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default ManufacturingJournal;
