import { FunctionComponent, useEffect, useState } from "react";
import { DEFAULT_EV_VEHICLE, EVehicle } from "../interface/e_vehicle_interface";
import EVAttachmentSection from "./ev_attachment_section";
import { useToast } from "../../../../../../../../context_providers/toast/toast_ctx";
import { updateObject } from "../../../../../../../../utils/formDataToJson";
import { dataTypes } from "../../../../../../../../utils/import_export_parser";

interface VehicleDetailSectionProps {
  createVehicle: (form: FormData) => void;
  data?: EVehicle;
}

const EVehicleDetailSection: FunctionComponent<VehicleDetailSectionProps> = (
  props
) => {
  const { showToast } = useToast();
  const [data, setData] = useState<EVehicle>(
    props.data ? { ...props.data } : DEFAULT_EV_VEHICLE
  );

  const [logoImage, setLogoImage] = useState<File | null>();
  const [displayImage, setDisplayImage] = useState<File | null>();

  const handleChange = (e: any) => {
    const { value, name } = e.target;
    const type = e.target.getAttribute("data-type");
    const keys = name.split(".");
    const updatedValue = updateObject(data, keys, value, type);
    setData(updatedValue);
  };

  useEffect(() => {
    console.log("Display Image", displayImage);
    console.log("Logo Image", logoImage);
  }, [displayImage, logoImage]);

  return (
    <div className="items-center rounded shadow  gap-3 p-5 m-5 bg-white">
      <form
        onSubmit={async (e) => {
          // e.preventDefault();
          // const d = customEVehicleFormToObject(
          //   new FormData(e.currentTarget)
          // ) as EVehicle;
          // d.battery_capacity = d.battery_capacity ?? "";
          // if (isNaN(d.battery_capacity)) {
          //   d.battery_capacity = "";
          // }
          // if (isNaN(d.charging_standard)) {
          //   d.charging_standard = "";
          // }
          // if (isNaN(d.connector_type)) {
          //   d.connector_type = "";
          // }
          // if (isNaN(d.range)) d.range = "";
          // console.log(data?._id);
          // if (props.data) {
          //   d.id = props.data._id;
          // }
          // delete data._id;
          // console.log("data", data);
          // props.createVehicle(data);
          e.preventDefault();
          const formData = new FormData();
          formData.append("data", JSON.stringify(data)); // Send data as string
          if (logoImage) formData.append("logo_image", logoImage);
          if (displayImage) formData.append("display_image", displayImage);

          props.createVehicle(formData);
        }}
      >
        {/* Brand */}
        <div className="grid grid-cols-2">
          <div>
            <div className="flex flex-col mb-4">
              <label htmlFor="brand" className="text-lg">
                Brand
              </label>
              <input
                id="brand"
                name="brand"
                type="text"
                data-type={dataTypes.string}
                defaultValue={props.data?.brand}
                onChange={handleChange}
                className="p-2 bg-gray-100 rounded focus:outline-none"
                placeholder="Enter the Brand name"
                required
              />
            </div>

            {/* Model */}
            <div className="flex flex-col mb-4">
              <label htmlFor="model" className="text-lg">
                Model
              </label>
              <input
                id="model"
                name="model"
                type="text"
                data-type={dataTypes.string}
                defaultValue={props.data?.model}
                onChange={handleChange}
                className="p-2 bg-gray-100 rounded focus:outline-none"
                placeholder="Enter the model name"
                required
              />
            </div>

            {/* Type */}
            <div className="flex flex-col mb-4">
              <label htmlFor="type" className="text-lg">
                Type
              </label>
              <select
                id="type"
                name="type"
                data-type={dataTypes.string}
                defaultValue={props.data?.type}
                onChange={handleChange}
                className="p-2 bg-gray-100 rounded focus:outline-none"
              >
                {["Scooter", "Bike", "Car", "Truck", "3 Wheeler", "Bus"].map(
                  (type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  )
                )}
              </select>
            </div>

            {/* Category */}
            <div className="flex flex-col mb-4">
              <label htmlFor="category" className="text-lg">
                Category
              </label>
              <select
                id="category"
                name="category"
                data-type={dataTypes.string}
                defaultValue={props.data?.category}
                onChange={handleChange}
                className="p-2 bg-gray-100 rounded focus:outline-none"
              >
                <option value="default">Select a Vehicle Category</option>
                {["2W", "3W", "4W", "Others"].map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </div>

            {/* Battery Capacity */}
            <div className="flex flex-col mb-4">
              <label htmlFor="battery_capacity" className="text-lg">
                Battery Capacity
              </label>
              <input
                id="battery_capacity"
                name="battery_capacity"
                type="text"
                data-type={dataTypes.string}
                defaultValue={props.data?.battery_capacity}
                onChange={handleChange}
                className="p-2 bg-gray-100 rounded focus:outline-none"
                placeholder="Enter the battery capacity"
              />
            </div>

            {/* Connector Type */}
            <div className="flex flex-col mb-4">
              <label htmlFor="connector_type" className="text-lg">
                Connector Type
              </label>
              <input
                id="connector_type"
                name="connector_type"
                type="text"
                data-type={dataTypes.string}
                defaultValue={props.data?.connector_type}
                onChange={handleChange}
                className="p-2 bg-gray-100 rounded focus:outline-none"
                placeholder="Enter the connector type"
              />
            </div>

            <div className="flex flex-col mb-4">
              <label htmlFor="connector_type2" className="text-lg">
                Connector Type 2
              </label>
              <input
                id="connector_type2"
                name="connector_type2"
                type="text"
                data-type={dataTypes.string}
                defaultValue={props.data?.connector_type2 || ""}
                onChange={handleChange}
                className="p-2 bg-gray-100 rounded focus:outline-none"
                placeholder="Enter the connector type 2"
              />
            </div>

            {/* Range */}
            <div className="flex flex-col mb-4">
              <label htmlFor="range" className="text-lg">
                Range
              </label>
              <input
                id="range"
                name="range"
                type="string"
                data-type={dataTypes.string}
                defaultValue={props.data?.range}
                onChange={handleChange}
                className="p-2 bg-gray-100 rounded focus:outline-none"
                placeholder="Enter the range"
              />
            </div>
          </div>
          <div className="flex flex-col items-center">
            <div className="w-full max-w-md">
              <h3 className="text-lg font-semibold text-center text-gray-800">
                Logo Upload
              </h3>
              <EVAttachmentSection
                attachment={data?.logo_url!}
                category={""}
                setFile={(file: File | null) => {
                  if (file) {
                    setLogoImage(file);
                  } else if (file === null) {
                    setLogoImage(null);
                  }
                }}
                file={logoImage ?? null}
                onDelete={() => {
                  let temp_data = data;
                  delete temp_data.logo_url;
                  setData(temp_data);
                }}
              />
            </div>

            <div className="w-full max-w-md">
              <h3 className="text-lg font-semibold text-center text-gray-800">
                Image Upload
              </h3>
              <EVAttachmentSection
                attachment={data?.image_url!}
                category={""}
                setFile={(file: File | null) => {
                  if (file) {
                    setDisplayImage(file);
                  } else if (file === null) {
                    setDisplayImage(null);
                  }
                }}
                file={displayImage ?? null}
                onDelete={() => {
                  let temp_data = data;
                  delete temp_data.image_url;
                  setData(temp_data);
                }}
              />
            </div>
          </div>
        </div>
        {/* Submit Button */}
        <div className="flex justify-center my-5">
          <button
            type="submit"
            className="px-5 py-2 mx-2 bg-red-400 text-white rounded-3xl hover:bg-red-800"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default EVehicleDetailSection;
