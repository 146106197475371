import axios from "axios";
import {
  fetchPost,
  fetchPut,
  toQueryString,
} from "../../../../service/api_client";
import { fetchGet } from "../../../../ui/pages/content_panel/services/serviceClient";
import { PartyModel } from "../../models/party_model";

export const getAttachmentSignedURLAPI = async (query: {
  mimetype: string;
  extension: string | undefined;
  dir: string;
}) => {
  const get_signed_urls = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/signed-url`;
  const res = await fetchGet(get_signed_urls + toQueryString(query));
  return res;
};

export const uploadFiletoS3API = async ({
  signed_url,
  file,
  type,
}: {
  signed_url: string;
  file: File;
  type: string;
}) => {
  const res = await axios.put(signed_url, file, {
    headers: {
      "Content-Type": type,
    },
  });
  return res;
};

export const VendorUpdateRepo = async (data: PartyModel) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const res = await fetchPut(`${url}/${data.id}`, data);
  return res;
};

export const VendorSaveDraftRepo = async (data: PartyModel) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const res = await fetchPost(url + "/save-draft", data);
  return res;
};

export const VendorCreateRepo = async (data: PartyModel) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party`;
  const res = await fetchPost(url + "/", data);
  return res;
};

export const VendorApproveRepo = async (data: PartyModel) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party/approve`;
  const res = await fetchPut(url + "/", data);
  return res;
};

export const VendorRejectRepo = async (data: PartyModel) => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/accounts/party/reject`;
  const res = await fetchPut(url + "/", data);
  return res;
};
