import { BaseModel } from "../../../service/models/base";

export interface PaymentRequestModel extends BaseModel {
  type: "ADVANCE" | "AGAINST INVOICE";
  po_no: string;
  due_date?: Date;
  percentage?: number;
  payment_amount: number;
  pi_no?: string;
  pi_date?: Date;
  pi_amount?: number;
  payment_mode?: string;
  attachments: PaymentAttachmentModel[];
  notes: string;
  pr_status: string;
  payment_status: string;
  tds_amount: number;
  tds_percent: number;
  amount_paid: number;
  approved_at?: Date;
  approved_by?: string;
  approved_by_name?: string;
  onhold_reason?: string;
  reject_reason?: string;
  paid_remarks?: string;
  failed_remarks?: string;
  spoc?: string;
  utr?: string;
  swift?: string;
  gst_no?: number;
  vendor?: string;
  po_id?: string;
  invoice_no?: string;
  invoice_date?: Date;
  invoice_amount?: number;
  category_of_expence?: string;
  no_of_intallations?: number;
  customer_location?: string;
  payment_date?: Date;
  po_approval?: string;
  project?: string;
  po_date?: Date;
}

export const payment_status = {
  pending: "Pending",
  paid: "Paid",
  failed: "Payment Failed",
};

export const payment_request_default: PaymentRequestModel = {
  po_no: "",
  type: "ADVANCE",
  payment_amount: 0,
  attachments: [],
  notes: "",
  pr_status: "",
  payment_status: payment_status.pending,
  tds_amount: 0,
  tds_percent: 0,
  amount_paid: 0
};

export const po_payments_req_status = {
  pending: "Pending for Approval", //when created PR
  approved: "Approved",
  onHold: "On Hold",
  rejected: "Rejected",
  updated: "Update Pending", //even though updated status id on Pending.
};

export const PRType = {
  advance:"ADVANCE",
  adainst_Invoice:"AGAINST INVOICE"
}




export const PaymentModeOptions = {
  bankTransfer: 'Bank Transfer',
  creditCard: "Credit Card",
  pettyCash: "Petty Cash",
  cheque: "Cheque"
}

export const CategoryOfExpenseOptions = {
  job_work: "Job Work",
  raw_material: "Raw Material",
  installation: "Installation",
  installation_and_earthing: "Installation and Earthing",
  installation_with_material: "Installation with material"
}

export type PreSignedUrlReturnModel = [string, File, PaymentAttachmentModel];


export interface PaymentAttachmentModel extends BaseModel {
  id: string;
  category: string;
  type: string;
  url: string;
}
