import { BaseModel } from "../../../service/models/base";
import { FileType, MODULE } from "../../../utils/enums";
import { LedgerEntryModel } from "../../accounts/models/common_model";
import {
  PartyModel,
  MailDetail,
  defaulPartyValue,
  default_mail_detail,
  Currency,
  currencies,
} from "../../accounts/models/party_model";
import { itemDefaultValue, ItemModel } from "../../inventory/models/item_model";
import {
  warehouseDefaultValue,
  WarehouseModel,
} from "../../inventory/models/warehouse_model";
export const attachment_category = {
  po: "PO",
  tax_invoice: "Tax invoice",
  bank_detail: "Bank detail",
  pan: "PAN",
  GST_certificate: "GST certificate",
  quotation: "Quotation",
  performa_invoice: "Performa Invoice",
  other: "Other",
};
export const getFileType = (mimetype: string) => {
  const type = mimetype.startsWith("image")
    ? FileType.IMAGE
    : mimetype.startsWith("video")
    ? FileType.VIDEO
    : mimetype === "application/pdf"
    ? FileType.PDF
    : mimetype === "text/csv"
    ? FileType.CSV
    : "unknown";
  return type;
};
export interface AttachmentModel extends BaseModel {
  id: string;
  category: string;
  type: string;
  url: string;
}
export interface PoItem {
  id?: any;
  item: ItemModel;
  bill_unit_no: number;
  unit_no: number;
  received_unit_no: number;
  returned_unit_no: number;
  price: number;
  // vendor_currency: Currency;
  // vendor_price: number;
}
export const defaultPoItemValue: PoItem = {
  item: itemDefaultValue,
  unit_no: 0,
  bill_unit_no: 0,
  received_unit_no: 0,
  returned_unit_no: 0,
  price: 0,
  // vendor_currency: {
  //   formal_name: "Rupee",
  //   short_name: "INR",
  //   symbol: "\u20b9",
  // },
  // vendor_price: 0,
};
export interface PurchaseOrderModel extends BaseModel {
  po_no: string;
  party: PartyModel;
  party_id: string;
  party_mail_detail: MailDetail;
  shipping_profile: MailDetail;
  shipping_method: string;
  ship_term: string;
  payment_term: string;
  date: Date;
  delivery_date: Date;
  valid_till_date?: Date;
  purchase_invoice_no: string;
  proforma_invoice_ref: string;
  items: PoItem[];
  foreclose_message?: string;
  taxes: LedgerEntryModel[];

  subtotal: number;
  tax: number;

  extra: number;
  discounts: LedgerEntryModel[];
  extras: LedgerEntryModel[];
  discount: number;
  total: number;
  notes: string;
  payment_status: string;
  status: string;
  attachments: AttachmentModel[];
  warehouse_id: string;
  warehouse: WarehouseModel;
  commets: PoComment[];
  payment_mode: string;
  project?: string;
  project_id: string;
  vendor_currency: Currency;
  vendor_currency_to_inr: number;
  approval_required: boolean;
  approval_status: string;
  approved_at?: Date;
  approved_by?: string;
  approved_by_name?: string;
  approval_level?: string;
  approvals?: ApprovalModel[];
  current_approval_level?: string;
  current_approval_required_count?: number;
  current_approval_count?: number;
  current_reject_count?: number;
  paid: number;
}
export interface ApprovalModel extends BaseModel {
  level: string;
  approval_status: string;
  approved_at?: Date;
  approved_by?: string;
  approved_by_name?: string;
  reason?: string;
}

// export interface ApprovalLevelModel {
//   level_0: "level_0";
//   level_1: "level_1";
//   level_2: "level_2";
//   level_3: "level_3";
// }
/**
 * level_0 greater then  0
 * level_1 greater then  5000
 * level_2 greater then  1 lakh
 * level_3 greater then  5 lakh
 */
export const approval_level = {
  level_0: "level_0",
  level_1: "level_1",
  level_2: "level_2",
  level_3: "level_3",
};

/**
 * key should match with approval_level values
 */
export const approval_threshold: {
  [k: string]: {
    threshold: {
      min: number | null;
      max: number | null;
    };
    approvals_required: {
      level: string;
      count: number;
    }[];
  };
} = {
  [approval_level.level_0]: {
    threshold: {
      min: null,
      max: 5000,
    },
    approvals_required: [],
  },
  [approval_level.level_1]: {
    threshold: {
      min: 5001,
      max: 100000,
    },
    approvals_required: [
      {
        level: approval_level.level_1,
        count: 1,
      },
    ],
  },
  [approval_level.level_2]: {
    threshold: {
      min: 100001,
      max: 500000,
    },
    approvals_required: [
      {
        level: approval_level.level_1,
        count: 1,
      },
      {
        level: approval_level.level_2,
        count: 1,
      },
    ],
  },
  [approval_level.level_3]: {
    threshold: {
      min: 500001,
      max: null,
    },
    approvals_required: [
      {
        level: approval_level.level_1,
        count: 1,
      },
      {
        level: approval_level.level_2,
        count: 1,
      },
      {
        level: approval_level.level_3,
        count: 1,
      },
    ],
  },
};

export const getAprrovalLevel = (total: number) => {
  const levels = Object.keys(approval_threshold);
  let f;
  for (let i = 0; i < levels.length; i++) {
    const level = levels[i];
    if (approval_threshold[level].threshold.min == null) {
      //min 0

      if (total <= approval_threshold[level].threshold.max!) {
        f = level;
      }
    } else if (approval_threshold[level].threshold.max == null) {
      // max is infinity
      if (total >= approval_threshold[level].threshold.min!) {
        f = level;
      }
    } else {
      if (
        total >= approval_threshold[level].threshold.min! &&
        total <= approval_threshold[level].threshold.max!
      ) {
        f = level;
      }
    }
    if (f) break;
  }

  return f ?? approval_level.level_0;
};

export const isPoApproved = ({
  level,
  approvals = [],
}: {
  level: string;
  approvals?: ApprovalModel[];
}) => {
  let counts: { [key: string]: number } = {};
  for (let i = 0; i < approvals.length; i++) {
    const approval = approvals[i];
    if (approval.approval_status === po_approval_status.approved) {
      if (!counts[approval.level]) {
        counts[approval.level] = 0;
      }
      counts[approval.level]++;
    }
  }

  for (
    let i = 0;
    i < approval_threshold[level].approvals_required.length;
    i++
  ) {
    const approval_required = approval_threshold[level].approvals_required[i];
    if (approval_required.count > counts[i]) {
      return false;
    }
  }

  return true;
};
export const getCurrentApprovalLevel = ({
  level,
  approvals = [],
}: {
  level: string;
  approvals?: ApprovalModel[];
}) => {
  let counts: { [key: string]: number } = {};
  for (let i = 0; i < approvals.length; i++) {
    const approval = approvals[i];
    if (approval.approval_status === po_approval_status.approved) {
      if (!counts[approval.level]) {
        counts[approval.level] = 0;
      }
      counts[approval.level]++;
    }
  }

  for (
    let i = 0;
    i < approval_threshold[level].approvals_required.length;
    i++
  ) {
    const approval_required = approval_threshold[level].approvals_required[i];
    if (approval_required.count > counts[i]) {
      return approval_required.level;
    }
  }

  return true;
};
export const getPoApprovedCounts = ({
  approvals = [],
}: {
  approvals?: ApprovalModel[];
}) => {
  let counts: { [key: string]: number } = {};
  for (let i = 0; i < approvals.length; i++) {
    const approval = approvals[i];
    if (approval.approval_status === po_approval_status.approved) {
      if (!counts[approval.level]) {
        counts[approval.level] = 0;
      }
      counts[approval.level]++;
    }
  }

  return counts;

  // for (
  //   let i = 0;
  //   i < approval_threshold[level].approvals_required.length;
  //   i++
  // ) {
  //   const approval_required = approval_threshold[level].approvals_required[i];
  //   if (approval_required.count > counts[i]) {
  //     return false;
  //   }
  // }

  // return true;
};

export const levelModule = {
  [approval_level.level_1]: MODULE.purchase_order_approval_L1,
  [approval_level.level_2]: MODULE.purchase_order_approval_L2,
  [approval_level.level_3]: MODULE.purchase_order_approval_L3,
};

export const ProjectlevelModule = {
  [approval_level.level_1]: MODULE.project_budget_approval_L1,
  [approval_level.level_2]: MODULE.project_budget_approval_L2,
  [approval_level.level_3]: MODULE.project_budget_approval_L3,
};

export interface PoComment extends BaseModel {
  comment: string;
}
export interface PoGeneratedModel extends PurchaseOrderModel {}

export const auto_approval_amount = 5000;
export const po_status = {
  hold: "Hold",
  pending: "Pending",
  accepted: "Accepted",
  cancelled: "Cancelled",
  rejected: "Rejected",
};
export const po_approval_status = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
};
export const po_default_value: PurchaseOrderModel = {
  po_no: "",
  party: defaulPartyValue,
  party_id: "",
  party_mail_detail: default_mail_detail,
  shipping_profile: default_mail_detail,
  shipping_method: "",
  ship_term: "",
  date: new Date(),
  delivery_date: new Date(),

  purchase_invoice_no: "",
  proforma_invoice_ref: "",
  items: [],
  //   taxes: [],
  subtotal: 0,
  //   discounts: [],
  //   extras: [],
  taxes: [],
  discounts: [],
  // tax: { amount: 0, desc: "" },
  // extra: { amount: 0, desc: "" },
  // discount: { amount: 0, desc: "" },
  tax: 0,
  extra: 0,
  discount: 0,
  total: 0,
  notes: "",
  payment_status: "",
  status: "",
  approval_required: true,
  approval_status: po_approval_status.pending,
  attachments: [],
  warehouse_id: "",
  warehouse: warehouseDefaultValue,
  extras: [],
  commets: [],
  payment_term: "",
  payment_mode: "",
  project_id: "",
  vendor_currency: currencies.rupee,
  vendor_currency_to_inr: 1,
  paid: 0,
};

export { FileType };
