import {
  mdiRefresh,
  mdiPlus,
  mdiImport,
  mdiArrowBottomLeft,
  mdiClose,
  mdiCommentBookmark,
  mdiHistory,
  mdiTrashCan,
  mdiPlusBox,
  mdiCloseBox,
} from "@mdi/js";
import Icon from "@mdi/react";
import axios, { CancelTokenSource } from "axios";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useModal,
  ModalType,
} from "../../../context_providers/modal/modal_context";
import ModalWidget from "../../../context_providers/modal/modal_widget";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { FieldType } from "../../../procurement/components/create_view/form_field";
import TableView from "../../../procurement/components/table_comp";
import {
  fetchDelete,
  fetchGet,
  fetchPost,
  toQueryString,
} from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { urls } from "../../../utils/urls";
import ItemGroupCreateScreen from "../item_group/item_group_create_screen";
import { ItemGroupModel } from "../models/item_group_model";
import {
  ItemFilterData,
  ItemModel,
  ItemModelV2,
  ItemType,
} from "../models/item_model";
import ImportWizard from "./components/import_comp";
import ExportWizard from "./components/import_comp";
import ItemCreateScreen from "./item_create/item_create_screen";
import ItemGroupSearchField from "../item_group/item_group_search_field";
import moment from "moment";
import {
  bulkApproveItemList,
  getItemsRepo,
} from "./repository/items_repository";
import { useAuth } from "../../../context_providers/auth/authProvider";
import {
  ACTION,
  MasterItemStatus,
  MasterItemStatusDict,
  MODULE,
} from "../../../utils/enums";
import Pagination from "../../../ui/new_components/common/pagination";
import { debounce, update } from "lodash";
import {
  ItemTypeList,
  ProductItemCreationSubCategoryElectricRawMaterials,
  ProductItemCreationSubCategoryOthers,
  ServiceItemCreationSubCategory,
} from "./enums/enums";
import ItemSearchField from "./components/ItemSearchField";
import DrawerModal, {
  DrawerModalLoaction,
} from "../../../ui/new_components/common/drawer_modal";
import ItemActionHistory from "./components/item_action_history";
import EmployeeSearchWidget from "../../../ui/new_components/emp_search_widget";
import { UserModel } from "../../../context_providers/auth/user_model";
import { error } from "console";

interface ItemListScreenProps {}

const defaultFilter: ItemFilterData = {
  page: 1,
  count: 100,
};

const ItemListScreen: FunctionComponent<ItemListScreenProps> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/items`;
  const { showModal, onClose } = useModal();

  const [filter, setFilter] = useState<ItemFilterData>(defaultFilter);

  const { showToast } = useToast();
  const [checker, setChecker] = useState(false);
  const [maker, setMaker] = useState(false);
  const [selectedType, setSelectedType] = useState<string>("");
  const [errorObjectList, setErrorObjectList] = useState<string[]>([]);
  const [existingItemList, setExistingItemList] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<ItemModelV2[]>([]);

  const [selectedEmp, setSelectedEmp] = useState<UserModel | null>();

  // const [state, setState] = useState ({
  //   checker:
  // })

  const { user, isAuthorised } = useAuth();
  const fetchData = async (prop: ItemFilterData) => {
    setLoading(true);
    console.log("Props", prop);
    try {
      const res = await getItemsRepo(prop);
      if (res.success) {
        setData(res.data.data);
        setCount(res.data.metadata.count);
        console.log("Res", res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
        setLoading(false);
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
  };

  // useEffect(() => {
  //   console.log("first useEffect");
  //   fetchData({ page, count, type: selectedType }); // Initial call
  // }, [page, selectedType]); // Empty dependency array ensures it runs on render

  useEffect(() => {
    const checker_permission_result = isAuthorised({
      module: MODULE.master_item_checker,
      action: ACTION.UPDATE,
    });
    const maker_permission_result = isAuthorised({
      module: MODULE.master_item_maker,
      action: ACTION.UPDATE,
    });
    setChecker(checker_permission_result);
    setMaker(maker_permission_result);
    console.log("Checker", checker_permission_result);
    console.log("Maker", maker_permission_result);
  }, [isAuthorised]); // Separate call for permission check

  const debouncedHandler = useCallback(
    debounce((prop: ItemFilterData) => {
      if (prop) fetchData(prop);
    }, 500),
    []
  );

  useEffect(() => {
    filter.type = selectedType;
    debouncedHandler(filter);
  }, [page, filter, selectedType, debouncedHandler]);

  useEffect(() => {
    console.log("Error Object List", errorObjectList);
  }, [errorObjectList]);

  const navaigation = useHistory();
  const onSelect = (d: ItemModelV2) => {
    if (
      checker &&
      (d.status === MasterItemStatus.Approved ||
        d.status === MasterItemStatus.Rejected ||
        d.status === MasterItemStatus.SavedDraft)
    ) {
      navaigation.push(urls.procurement_masters_item + "/view-item/" + d.id);
    } else if ((checker && !maker) || (checker && maker)) {
      navaigation.push(
        urls.procurement_masters_item + "/approve-reject-item/" + d.id
      );
    } else if (maker && d.status === MasterItemStatus.SavedDraft) {
      navaigation.push(urls.procurement_masters_item + "/update/" + d!.id);
    } else if (
      maker &&
      (d.status === MasterItemStatus.UpdatePending ||
        d.status === MasterItemStatus.PendingForApproval)
    ) {
      navaigation.push(urls.procurement_masters_item + "/view-item/" + d!.id);
    } else {
      navaigation.push(urls.procurement_masters_item + "/view-item/" + d.id);
    }
  };

  const handleOnEdit = () => {
    if (selectedItems.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selectedItems.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      if (selectedItems[0]?.status !== MasterItemStatus.Approved) {
        navaigation.push(
          urls.procurement_masters_item + "/update/" + selectedItems[0]!.id
        );
      } else {
        showToast({
          type: ToastType.error,
          text: "Approved Item cannot be deleted",
        });
      }
    }
  };

  const handleOnDelete = () => {
    // if (selectedItem) {
    //   navaigation.push(
    //     urls.procurement_masters_item + "/delete/" + selectedItem!.item_code
    //   );
    // } else {
    //   showToast({
    //     type: ToastType.error,
    //     text: "Please select an item to Delete",
    //   });
    // }
    if (selectedItems.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to delete",
      });
    } else if (selectedItems.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to delete",
      });
    } else {
      navaigation.push(
        urls.procurement_masters_item + "/delete/" + selectedItems[0]!.id
      );
    }
  };

  const handleBulkApproval = async () => {
    try {
      setLoading(true);
      const res = await bulkApproveItemList(selectedItems);
      if (res.success) {
        console.log(res.data);
        showToast({ type: ToastType.success, text: "Approved Succesfully" });
        fetchData(filter);
      } else {
        showToast({ type: ToastType.error, text: res.error });
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      showToast({ type: ToastType.error, text: error.message });
    }
  };

  const downloadExistingDevices = () => {
    const csvHeader = ["Type", "Item Name"];

    const formatCsvValue = (value: string) => `"${value.replace(/"/g, '""')}"`; // Escape quotes if needed

    const existingItems = existingItemList.map((item) => [
      "Existing Item",
      formatCsvValue(item),
    ]);
    const errorItems = errorObjectList.map((item) => [
      "Validation Error",
      formatCsvValue(item),
    ]);

    const csvContent = [
      csvHeader.map(formatCsvValue).join(","),
      ...existingItems.map((row) => row.join(",")),
      ...errorItems.map((row) => row.join(",")),
    ].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "item_list_errors.csv";
    link.click();
    URL.revokeObjectURL(url); // Clean up the URL object
  };

  let cancelTokenSource: CancelTokenSource;
  const onImport = async (d: {
    data: ItemModelV2[];
    setCompleted: (loading: boolean) => void;
    setUpLoading: (loading: boolean) => void;
    setProgress: (loading: number) => void;
    // setCancelled: (d: boolean) => void;
  }) => {
    cancelTokenSource = axios.CancelToken.source();
    d.setUpLoading(true);
    const config = {
      cancelToken: cancelTokenSource.token,
      onUploadProgress: (e: any) => {
        const percentCompleted = Math.round((e.loaded * 100) / e.total);
        d.setProgress(percentCompleted);
        console.log("uploaded " + percentCompleted + " %");
      },
    };

    const form = new FormData();
    form.append("data", JSON.stringify(d.data));
    const res = await fetchPost(url + "/import", form, {}, config);
    if (res.success) {
      await fetchData({ page, count });

      showToast({ type: ToastType.success, text: res.message });
    } else {
      setShowImport(false);
      if (res.exisiting_items.length > 0) {
        let existing_items: string[] = res.exisiting_items;
        setExistingItemList(existing_items);
        setShowImportErrors(true);
      }
      if (res.validation_errors.length > 0) {
        let validation_errors: string[] = res.validation_errors;
        setErrorObjectList(validation_errors);
        setShowImportErrors(true);
      }
      showToast({ type: ToastType.error, text: res.error });
    }
    d.setUpLoading(false);
  };
  const [showHistory, setShowHistory] = useState<any>();
  const [showImport, setShowImport] = useState(false);

  const [selectedItems, setSelectedItems] = useState<ItemModelV2[]>([]);

  const [showImportErrors, setShowImportErrors] = useState<boolean>(false);

  return (
    <>
      {showHistory && (
        <DrawerModal
          location={DrawerModalLoaction.right}
          show={showHistory !== undefined}
          onClose={() => setShowHistory(false)}
          title="History"
        >
          <ItemActionHistory
            item_code={showHistory}

            // show={ShowHistory !== undefined}
            // onClose={(v) => setShowHistory(undefined)}
          />
        </DrawerModal>
      )}
      {showImport && (
        <ModalWidget
          data={{
            id: 1,
            title: "Import",
            type: ModalType.modal,

            onClose: () => {
              setShowImport(false);
            },
          }}
          onClose={function (): void {
            setShowImport(false);
          }}
        >
          <ImportWizard
            onClose={() => {
              setShowImport(false);
            }}
            onCancel={function () {
              if (cancelTokenSource) {
                console.log("Cancelled by user");
                cancelTokenSource.cancel("cancelled by user");
              }
            }}
            onSubmit={onImport}
            onError={function (error: string): void {
              showToast({ type: ToastType.error, text: error });
            }}
          />
        </ModalWidget>
      )}

      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section id="top_section" className=" ">
          <div className="flex justify-between items-end my-1">
            <h1 className="font-bold text-3xl text-gray-500 flex items-center gap-3">
              Items
              <button
                title="Refresh"
                onClick={() => fetchData({ page, count })}
              >
                <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
              </button>
            </h1>
            <div className="flex flex-wrap justify-start   text-sm items-center gap-4 ">
              {" "}
              <div>Search Created By</div>
              <div className="rounded w-60 flex gap-1 bg-white">
                <EmployeeSearchWidget
                  withBtn={false}
                  onSelect={(em) => {
                    setFilter((prevData) => ({
                      ...prevData,
                      uid: em.uid, // Directly updating item_code
                    }));
                    setSelectedEmp(em);
                  }}
                  emp={{
                    uid: selectedEmp ? selectedEmp.uid : "",
                    name: selectedEmp
                      ? selectedEmp.first_name + " " + selectedEmp.last_name
                      : "",
                  }}
                  placeholder="Item creator"
                />
                <div
                  onClick={() => {
                    setFilter((prevData) => ({
                      ...prevData,
                      uid: "",
                    }));
                    setSelectedEmp(null);
                  }}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-red-400 text-white  transform"
                >
                  <Icon path={mdiCloseBox} size={0.8} />
                </div>
              </div>
              {maker && !checker && (
                <div
                  onClick={() => {
                    setShowImport(true);
                  }}
                  className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                >
                  <Icon path={mdiArrowBottomLeft} size={0.8} />
                  Import
                </div>
              )}
              {checker && (
                <>
                  {selectedItems.length > 0 && (
                    <div
                      onClick={handleBulkApproval}
                      className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
                    >
                      <Icon path={mdiPlusBox} className="h-4 w-4"></Icon>
                      Bulk Approve
                    </div>
                  )}
                  <div
                    onClick={handleOnDelete}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 bg-red-400 hover:scale-105 duration-200  text-white  transform"
                  >
                    <Icon path={mdiTrashCan} className="h-4 w-4"></Icon>Delete
                  </div>
                </>
              )}
              {(!checker || maker) && (
                <>
                  <div
                    onClick={handleOnEdit}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
                  >
                    <Icon path={mdiPlus} className="h-4 w-4"></Icon>Update
                  </div>
                  <div
                    onClick={() => {
                      navaigation.push(
                        urls.procurement_masters_item + "/create"
                      );
                    }}
                    className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200 text-white transform"
                  >
                    <Icon path={mdiPlus} className="h-4 w-4"></Icon>Create
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
        <div>
          {showImportErrors &&
            errorObjectList.length > 0 &&
            (() => {
              return (
                <div className="">
                  <div className="rounded-lg border border-red-800 m-2 px-4 py-3">
                    <div className="flex justify-between">
                      <h2 className="text-lg font-bold">
                        Some Items Already Exist or validation errors are there
                      </h2>
                      <div
                        onClick={() => {
                          setShowImportErrors(false);
                        }}
                        className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded"
                      >
                        <Icon path={mdiClose} className="h-4 w-4"></Icon>
                      </div>
                    </div>
                    <p className="mt-2">
                      The following Items already exist/validation errors are
                      there in the inventory. Please review the list.
                    </p>
                    {/* <ul className="list-disc ml-6 mt-2">
                  {existingDevicesList.map((device, index) => (
                    <li key={index}>
                      <strong>Device ID:</strong> {device.device_id},{" "}
                      <strong>Status:</strong> {device.status}
                    </li>
                  ))}
                </ul> */}
                    <button
                      onClick={downloadExistingDevices}
                      className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded"
                    >
                      Download Item IDs
                    </button>
                  </div>
                </div>
              );
            })()}
        </div>
        <section className="flex items-start justify-start m-4">
          <div className="grid grid-cols-2 gap-6 w-1/2">
            <div className="">
              <label htmlFor="app_status" className="">
                Item Code
              </label>
              <ItemSearchField
                defaulValue={filter.item_code ?? ""}
                onClear={() => {
                  setFilter((prevData) => ({
                    ...prevData,
                    item_code: "", // Directly updating item_code
                  }));
                }}
                onSubmit={(value: string) =>
                  setFilter((prevData) => ({
                    ...prevData,
                    item_code: value, // Directly updating item_code
                  }))
                }
              />
            </div>
            <div className="">
              <label htmlFor="app_status" className="">
                Item Name
              </label>
              <ItemSearchField
                defaulValue={filter.item_name ?? ""}
                onClear={() => {
                  setFilter((prevData) => ({
                    ...prevData,
                    item_name: "", // Directly updating item name
                  }));
                }}
                onSubmit={(val) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    item_name: val, // Directly updating item name
                  }));
                }}
              />
            </div>
            <div className="">
              <label htmlFor="item_type" className="">
                Item Type
              </label>
              <select
                className="w-full p-2 bg-white rounded focus:outline-none"
                name="item_type"
                id="item_type"
                value={selectedType ?? ""}
                onChange={(e) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    sub_category: "",
                    category: "",
                    type: e.target.value, // Directly updating type
                  }));
                  setSelectedType(e.target.value);
                  setSelectedItems([]);
                }}
              >
                <option value="">select</option>
                {ItemTypeList.map((f: string) => (
                  <option key={f} value={f}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
            {selectedType === "Product" && (
              <div>
                <label htmlFor="category" className="">
                  Category
                </label>
                <select
                  className="w-full p-2 rounded bg-white focus:outline-none"
                  name="category"
                  id="category"
                  value={filter.category ?? ""}
                  onChange={(e) => {
                    console.log("category", e.target.value);
                    setFilter((prevData) => ({
                      ...prevData,
                      category: e.target.value, // Directly updating category
                    }));
                  }}
                >
                  <option value="">select</option>
                  {selectedType === "Product"
                    ? Object.entries([
                        "Electrical",
                        "Electronics",
                        "Others",
                      ]).map(([key, value]) => (
                        <option key={key} value={value}>
                          {value}
                        </option>
                      ))
                    : null}
                </select>
              </div>
            )}
            <div>
              <label htmlFor="app_status" className="">
                Approval Status
              </label>
              <select
                className="w-full p-2 rounded focus:outline-none bg-white"
                name="app_status"
                id="app_status"
                value={filter.status || ""}
                onChange={(e) => {
                  const selectedKey = e.target.value;
                  setFilter((prevData) => ({
                    ...prevData,
                    status: selectedKey, // Set the key directly
                  }));
                }}
              >
                <option value="">select</option>
                {Object.entries(MasterItemStatusDict).map(([key, value]) => (
                  <option key={key} value={value}>
                    {" "}
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="sub_category" className="">
                Sub-Category
              </label>
              <select
                className="w-full p-2 bg-white rounded focus:outline-none"
                name="sub_category"
                id="sub_category"
                value={filter.sub_category ?? ""}
                onChange={(e) => {
                  // Handle the change event
                  setFilter((prevData) => ({
                    ...prevData,
                    sub_category: e.target.value, // Update the sub_category in state
                  }));
                }}
              >
                <option value="">select</option>
                {/* Render different sub-category options based on filter.type and filter.category */}
                {filter.type === "Product" ? (
                  ProductItemCreationSubCategoryElectricRawMaterials.map(
                    (subCategory, index) => (
                      <option key={index} value={subCategory}>
                        {subCategory}
                      </option>
                    )
                  )
                ) : filter.type == "" ? (
                  <></>
                ) : (
                  ServiceItemCreationSubCategory.map((subCategory, index) => (
                    <option key={index} value={subCategory}>
                      {subCategory}
                    </option>
                  ))
                )}
              </select>
            </div>
          </div>
        </section>

        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs   py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      checked={
                        selectedItems.length === data.length ? true : false
                      }
                      disabled={checker && !maker ? false : true}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedItems([...data]);
                        } else {
                          setSelectedItems([]);
                        }
                        console.log(e);
                      }}
                    />
                  </div>
                </th>
                <th className="px-2 py-2">Actions</th>
                <th className="px-2 py-2 text-left">Item code</th>
                <th className="px-2 py-2 text-left">Item name</th>
                <th className="px-2 py-2 text-left">Description</th>
                <th className="px-2 py-2 text-left">Item type</th>
                <th className="px-2 py-2 text-left">Manufacturer</th>
                <th className="px-2 py-2 text-left">MPN</th>
                <th className="px-2 py-2 text-left">Category</th>
                <th className="px-2 py-2 text-left">Sub-category</th>
                <th className="px-2 py-2 text-left">Unit measure</th>
                <th>Created By</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="">
              {data.length > 0 ? (
                data.map((item, i) => {
                  const isSelected = selectedItems.includes(item);
                  return (
                    <tr className="border text-center " key={i}>
                      <td>
                        <div className="p-2">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={isSelected}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedItems((prev) => [...prev, item]); // Add the item to selected items
                              } else {
                                setSelectedItems((prev) =>
                                  prev.filter(
                                    (selectedItem) => selectedItem !== item
                                  )
                                ); // Remove the item from selected items
                              }
                            }}
                          />
                        </div>
                      </td>
                      <td>
                        <div className="flex gap-2 justify-center">
                          {" "}
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              setShowHistory(item.item_code);
                            }}
                          >
                            <Icon path={mdiHistory} size="16"></Icon>
                          </div>
                        </div>
                      </td>
                      <div
                        className="py-3 items-center cursor-pointer text-blue-600 hover:underline"
                        role="button"
                        onClick={() => onSelect(item)}
                      >
                        <td className="px-3 py-2 text-left">
                          {item.item_code}
                        </td>
                      </div>

                      <td className="px-3 py-2 text-left">{item.name}</td>
                      <td
                        className="px-3 py-2  text-left"
                        // onClick={() => setUpdateData(d)}
                      >
                        {item.description ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">{item.type}</td>
                      <td className="px-3 py-2 text-left">
                        {item.manufacturer ?? "--"}
                      </td>
                      {/* TODO: MPN Field is there */}
                      <td className="px-3 py-2 text-left">
                        {item.mpn ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {item.category ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {item.subcategory ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {/* {item?.unit?.formal_name !== null &&
                        item?.unit?.formal_name !== undefined
                          ? item?.unit?.formal_name
                          : "--"} */}
                        {item?.unit?.formal_name === null ||
                        item?.unit?.formal_name === undefined
                          ? "--"
                          : item?.unit?.formal_name}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {item.created_by_name ?? "--"}
                      </td>
                      <td className="px-3 py-2 text-left">
                        {item.status ?? "--"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={count}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={
              (page) => setFilter((o) => ({ ...o, page }))
              // () => console.log("Pagination")
            }
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default ItemListScreen;
