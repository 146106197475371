/* eslint-disable react-hooks/exhaustive-deps */
import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  payment_status,
  PaymentRequestModel,
  po_payments_req_status,
} from "../../model/payment_request_model";
import { urls } from "../../../../utils/urls";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import { fetchGet, fetchPut } from "../../../../service/api_client";
import LoadingWidget from "../../../../context_providers/modal/loader";
import moment from "moment";
import AttachmentViewer from "../../../../ui/new_components/common/attachment_viewer";
import { PurchaseOrderModel } from "../../model/purchase_order_model";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiHistory, mdiInformationOutline } from "@mdi/js";
import { currencyFormat } from "../../../../utils/orders_utils";
import { ProcurementProjectModel } from "../../project/model/project_model";
import PoItemRow from "../../purchase_order_create/components/item_row";
import ModalDialog from "../../../../ui/new_components/common/modal";
import ActionHistoryAdvance from "../components/payment_request_advance_history_logs";
import {
  ModalAlignment,
  ModalType,
  useModal,
} from "../../../../context_providers/modal/modal_context";
import { ACTION, MODULE } from "../../../../utils/enums";
import { useAuth } from "../../../../context_providers/auth/authProvider";

interface PaymentRequestInvoiceViewProps {
  edit: boolean;
  onClose: (id?: PaymentRequestModel) => void;
  data?: PaymentRequestModel;
}

const PaymentRequestInvoiceApprovalScreen: FunctionComponent<
  PaymentRequestInvoiceViewProps
> = (props) => {
  const isUpdatePage = useRouteMatch(
    `${urls.po_payment_request_approval}/approveInvoice/:id`
  );
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/advance_payment_request`;
  const { showToast } = useToast();
  const { user, isAuthorised } = useAuth();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { edit } = props;
  // const [editable, setEditable] = useState(false);
  const [project, setProject] = useState<ProcurementProjectModel>();
  const [purchaseOrderData, setPurchaseOrderData] =
    useState<PurchaseOrderModel>();
  const [data, setData] = useState<PaymentRequestModel>(
    props.data || ({} as PaymentRequestModel)
  );
  const [
    showInvoicePaymentRequestPaymentFailModal,
    setShowInvoicePaymentRequestPaymentFailModal,
  ] = useState(false);
  const [showPaymentRequestRepayModal, setShowPaymentRequestRepayModal] =
    useState(false);
  const [showPaymentRequestApprovalModal, setShowPaymentRequestApprovalModal] =
    useState(false);
  const [
    showInvoicePaymentRequestApprovalModal,
    setShowInvoicePaymentRequestApprovalModal,
  ] = useState(false);
  const [
    showInvoicePaymentRequestApprovalOnHoldModal,
    setShowInvoicePaymentRequestApprovalOnHoldModal,
  ] = useState(false);
  const [
    showInvoicePaymentRequestApprovalRejectModal,
    setShowInvoicePaymentRequestApprovalRejectModal,
  ] = useState(false);

  const existingItems = purchaseOrderData?.items || [];
  const { showModal } = useModal();

  useEffect(() => {
    // Initialize form with existing data if editing
    if (edit && props.data) {
      setData(props.data);
    }
  }, [edit, props.data]);

  useEffect(() => {
    // Fetch the data for the payment request (when editing or creating)
    if (id && isUpdatePage) {
      getData(id);
    }
  }, []);

  useEffect(() => {
    const fetchPurchaseOrder = async () => {
      if (data.po_no && !purchaseOrderData) {
        setLoading(true);
        await fetchPurchaseOrderByPoNo(data.po_no);
      }
    };

    fetchPurchaseOrder();
  }, [data.po_no, purchaseOrderData]);

  useEffect(() => {
    const fetchProjectData = async () => {
      if (purchaseOrderData?.project_id && !project) {
        setLoading(true);
        await getProjectData(purchaseOrderData.project_id);
      }
    };

    fetchProjectData();
  }, [purchaseOrderData?.project_id, project]);

  const showLog = () => {
    showModal({
      title: "Logs",
      type: ModalType.drawer,
      alignment: ModalAlignment.right,
      container: (id) => <ActionHistoryAdvance pr_id={data.id} data={data} />,
    });
  };

  const PaymentStatus = async (status: string) => {
    if (
      status === payment_status.paid &&
      (!data.utr || !data.payment_date || !data.amount_paid)
    ) {
      showToast({
        type: ToastType.error,
        text: "Need reqired fields.",
      });
      return;
    }
    if (status === payment_status.failed && !data.failed_remarks) {
      showToast({
        type: ToastType.error,
        text: "Need Payment Rejected Remarks.",
      });
      return;
    }
    if (
      status === payment_status.paid &&
      data.payment_amount < data.amount_paid + data.tds_amount
    ) {
      showToast({
        type: ToastType.error,
        text: "The paid amount and tds_amount sum should not exceed the payment amount.",
      });
      return;
    }
    if (status) {
      console.log("Sending status:", status); // Log the status being sent

      try {
        console.log(`Requesting URL: ${url}/${data.id}/payment_status`);

        const res = await fetchPut(`${url}/${data.id}/payment_status`, data);

        console.log("API response:", res); // Log the full response for debugging
        showToast({
          type: ToastType.success,
          text: "Payment Status Updated.",
        });
        history.push(urls.po_payment_request_approval);
        // Your existing success/error logic...
      } catch (error) {
        console.error("Error while updating payment status:", error);
        showToast({
          type: ToastType.error,
          text: "Failed to update status. Try again later.",
        });
      }
    }
  };

  const PRStatus = async (status: string) => {
    if (status === po_payments_req_status.onHold && !data.onhold_reason) {
      showToast({
        type: ToastType.error,
        text: "Need PR OnHold Reason.",
      });
      return;
    }
    if (status === po_payments_req_status.rejected && !data.reject_reason) {
      showToast({
        type: ToastType.error,
        text: "Need PR Rejected Reason.",
      });
      return;
    }

    if (status) {
      // console.log("Sending status:", status);
      try {
        console.log("PR status:", data.pr_status);
        const res = await fetchPut(`${url}/${data.id}/payment_approval`, data);

        console.log("API response:", res); // Log the full response for debugging
        showToast({
          type: ToastType.success,
          text: "PR Status Updated.",
        });
        history.push(urls.po_payment_request_approval);
        // Your existing success/error logic...
      } catch (error) {
        console.error("Error while updating payment status:", error);
        showToast({
          type: ToastType.error,
          text: "Failed to update status. Try again later.",
        });
      }
    }
  };

  const fetchPurchaseOrderByPoNo = async (
    poNo: string
  ): Promise<PurchaseOrderModel | null> => {
    try {
      const res = await fetchGet(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/purchase-order/${poNo}`
      );
      if (res.success) {
        setPurchaseOrderData(res.data);
        // Assuming res.data is a single PurchaseOrderModel
        console.log("purchaseOrderData", purchaseOrderData);
        return res.data as PurchaseOrderModel;
      } else {
        showToast({ type: ToastType.error, text: res.error });
        return null;
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: (error as Error).message });
      return null;
    }
  };

  const getProjectData = async (id: string) => {
    setLoading(true);

    const res = await fetchGet(
      `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project/${id}`
    );
    if (res.success) {
      setProject({ ...res.data });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const getData = async (id: string) => {
    try {
      setLoading(true);
      const res = await fetchGet(`${url}/${id}`);
      if (res.success) {
        const updatedData = { ...res.data, type: res.data.type }; // Update type property if necessary
        setData(updatedData); // Update state with merged data
      } else {
        console.log("Invalid");
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error) {
      showToast({ type: ToastType.error, text: "An error occurred" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {showPaymentRequestApprovalModal && (
        <ModalDialog
          show={showPaymentRequestApprovalModal}
          title={"Payment Request Approve"}
          onClose={function (): void {
            setShowPaymentRequestApprovalModal(false);
            setData({
              ...data,
              payment_status: payment_status.pending,
            });
          }}
        >
          <div className="bg-white h-auto p-6 rounded-md shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">
              Do you want to change Payment Status to Paid?
            </h2>

            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <label className="text-sm text-gray-500">Payment Date *</label>
                <input
                  type="date"
                  required
                  value={
                    data?.payment_date
                      ? moment(data?.payment_date).format("YYYY-MM-DD")
                      : ""
                  }
                  className="w-full px-3 py-2 border rounded-md"
                  onChange={(e) => {
                    const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                    const date = selectedDate
                      ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                      : undefined; // Reset if no date is selected

                    // Update the filter
                    setData((pv) => ({
                      ...pv,
                      payment_date: date!,
                    }));
                  }}
                />
              </div>
              {/* UTR input */}
              <div className="flex flex-row gap-2">
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-500">UTR *</label>
                  <input
                    value={data?.utr || ""}
                    required
                    onChange={(e) =>
                      setData((pv) => ({
                        ...pv,
                        utr: e.target.value,
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>

                {/* Swift input */}
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-500">Swift </label>
                  <input
                    type="text"
                    value={data.swift}
                    onChange={(e) =>
                      setData((pv) => ({
                        ...pv,
                        swift: e.target.value,
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-500">TDS Amount </label>
                  <input
                    type="number"
                    value={data.tds_amount}
                    onChange={(e) =>
                      setData((pv) => ({
                        ...pv,
                        tds_amount: Number(e.target.value),
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-500">TDS Percent </label>
                  <input
                    type="number"
                    value={data.tds_percent}
                    onChange={(e) =>
                      setData((pv) => ({
                        ...pv,
                        tds_percent: Number(e.target.value),
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm text-gray-500">Paid Amount *</label>
                <input
                  type="number"
                  value={data.amount_paid}
                  onChange={(e) =>
                    setData((pv) => ({
                      ...pv,
                      amount_paid: Number(e.target.value),
                    }))
                  }
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>

              {/* Remarks input */}
              <div className="flex flex-col gap-1">
                <label className="text-sm text-gray-500">Remarks</label>
                <textarea
                  value={data.paid_remarks}
                  onChange={(e) =>
                    setData((pv) => ({
                      ...pv,
                      paid_remarks: e.target.value,
                    }))
                  }
                  className="w-full px-3 py-2 border rounded-md"
                  rows={4}
                ></textarea>
              </div>
            </div>

            {/* Action buttons */}
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setShowPaymentRequestApprovalModal(false);
                  setData({
                    ...data,
                    payment_status: payment_status.pending,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                Cancel
              </button>
              <button
                onClick={() => PaymentStatus(payment_status.paid)}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Update
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      {showPaymentRequestRepayModal && (
        <ModalDialog
          show={showPaymentRequestRepayModal}
          title={"Payment Request Approve"}
          onClose={function (): void {
            setShowPaymentRequestRepayModal(false);
            setData({
              ...data,
              payment_status: payment_status.failed,
            });
          }}
        >
          <div className="bg-white h-auto p-6 rounded-md shadow-lg w-96">
            <h2 className="text-xl font-bold mb-4">
              Do you want to change Payment Status to Paid?
            </h2>

            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <label className="text-sm text-gray-500">Payment Date *</label>
                <input
                  type="date"
                  required
                  value={
                    data?.payment_date
                      ? moment(data?.payment_date).format("YYYY-MM-DD")
                      : ""
                  }
                  className="w-full px-3 py-2 border rounded-md"
                  onChange={(e) => {
                    const selectedDate = e.target.value; // Get the selected date string (YYYY-MM-DD)
                    const date = selectedDate
                      ? new Date(new Date(selectedDate).setHours(0, 0, 0, 0)) // Convert to a date object
                      : undefined; // Reset if no date is selected

                    // Update the filter
                    setData((pv) => ({
                      ...pv,
                      payment_date: date!,
                    }));
                  }}
                />
              </div>
              {/* UTR input */}
              <div className="flex flex-row gap-2">
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-500">UTR *</label>
                  <input
                    value={data?.utr || ""}
                    required
                    onChange={(e) =>
                      setData((pv) => ({
                        ...pv,
                        utr: e.target.value,
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>

                {/* Swift input */}
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-500">Swift </label>
                  <input
                    type="text"
                    value={data.swift}
                    onChange={(e) =>
                      setData((pv) => ({
                        ...pv,
                        swift: e.target.value,
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>
              </div>
              <div className="flex flex-row gap-2">
                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-500">TDS Amount </label>
                  <input
                    type="number"
                    value={data.tds_amount}
                    onChange={(e) =>
                      setData((pv) => ({
                        ...pv,
                        tds_amount: Number(e.target.value),
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label className="text-sm text-gray-500">TDS Percent </label>
                  <input
                    type="number"
                    value={data.tds_percent}
                    onChange={(e) =>
                      setData((pv) => ({
                        ...pv,
                        tds_percent: Number(e.target.value),
                      }))
                    }
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <label className="text-sm text-gray-500">Paid Amount *</label>
                <input
                  type="number"
                  value={data.amount_paid}
                  onChange={(e) =>
                    setData((pv) => ({
                      ...pv,
                      amount_paid: Number(e.target.value),
                    }))
                  }
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>

              {/* Remarks input */}
              <div className="flex flex-col gap-1">
                <label className="text-sm text-gray-500">Remarks </label>
                <textarea
                  value={data.paid_remarks}
                  onChange={(e) =>
                    setData((pv) => ({
                      ...pv,
                      paid_remarks: e.target.value,
                    }))
                  }
                  className="w-full px-3 py-2 border rounded-md"
                  rows={4}
                ></textarea>
              </div>
            </div>

            {/* Action buttons */}
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setShowPaymentRequestRepayModal(false);
                  setData({
                    ...data,
                    payment_status: payment_status.failed,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                Cancel
              </button>
              <button
                onClick={() => PaymentStatus(payment_status.paid)}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Update
              </button>
            </div>
          </div>
        </ModalDialog>
      )}

      {showInvoicePaymentRequestPaymentFailModal && (
        <ModalDialog
          show={showInvoicePaymentRequestPaymentFailModal}
          title={"Update Status"}
          onClose={() => {
            setShowInvoicePaymentRequestPaymentFailModal(false);
            setData({
              ...data,
              payment_status: payment_status.pending,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to change Payment Status to Payment Failed?
            </h2>
            <div className="flex flex-col gap-1">
              <label className="text-sm text-gray-500">Remarks *</label>
              <textarea
                value={data.failed_remarks}
                onChange={(e) =>
                  setData({ ...data, failed_remarks: e.target.value })
                }
                className="w-full px-3 py-2 border rounded-md"
                rows={4}
              ></textarea>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setShowInvoicePaymentRequestPaymentFailModal(false);
                  setData({
                    ...data,
                    payment_status: payment_status.pending,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => PaymentStatus(payment_status.failed)}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}

      {showInvoicePaymentRequestApprovalModal && (
        <ModalDialog
          show={showInvoicePaymentRequestApprovalModal}
          title={"Invoice Payment Request Approve"}
          onClose={() => {
            setShowInvoicePaymentRequestApprovalModal(false);
            setData({
              ...data,
              pr_status: po_payments_req_status.pending,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to change Payment Status to Approved?
            </h2>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setShowInvoicePaymentRequestApprovalModal(false);
                  setData({
                    ...data,
                    pr_status: po_payments_req_status.pending,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => {
                  PRStatus(po_payments_req_status.approved);
                }}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      {showInvoicePaymentRequestApprovalOnHoldModal && (
        <ModalDialog
          show={showInvoicePaymentRequestApprovalOnHoldModal}
          title={"Invoice Payment Request OnHold"}
          onClose={() => setShowInvoicePaymentRequestApprovalOnHoldModal(false)}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to change Payment Status to OnHold?
            </h2>
            <div className="flex flex-col gap-1">
              <label className="text-sm text-gray-500">Reason *</label>
              <textarea
                value={data.onhold_reason}
                onChange={(e) =>
                  setData({ ...data, onhold_reason: e.target.value })
                }
                className="w-full px-3 py-2 border rounded-md"
                rows={4}
              ></textarea>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setShowInvoicePaymentRequestApprovalOnHoldModal(false);
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => PRStatus(po_payments_req_status.onHold)}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      {showInvoicePaymentRequestApprovalRejectModal && (
        <ModalDialog
          show={showInvoicePaymentRequestApprovalRejectModal}
          title={"Invoice Payment Request Reject"}
          onClose={() => {
            setShowInvoicePaymentRequestApprovalRejectModal(false);
            setData({
              ...data,
              pr_status: po_payments_req_status.pending,
            });
          }}
        >
          <div className="bg-white p-6 rounded-md shadow-lg w-96">
            <h2 className="text-md font-semibold mb-4">
              Do you want to change Payment Status to Rejected?
            </h2>
            <div className="flex flex-col gap-1">
              <label className="text-sm text-gray-500">Reason *</label>
              <textarea
                value={data.reject_reason}
                onChange={(e) =>
                  setData({ ...data, reject_reason: e.target.value })
                }
                className="w-full px-3 py-2 border rounded-md"
                rows={4}
              ></textarea>
            </div>
            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  setShowInvoicePaymentRequestApprovalRejectModal(false);
                  setData({
                    ...data,
                    pr_status: po_payments_req_status.pending,
                  });
                }}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700"
              >
                No
              </button>
              <button
                onClick={() => PRStatus(po_payments_req_status.rejected)}
                className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </ModalDialog>
      )}
      {/* <form onSubmit={submit} className=" py-2 px-6"> */}
      <div className="flex items-center justify-between py-2 sticky top-0 bg-myBgcolor">
        <div className="flex flex-row">
          <button
            className="  flex  items-center p-2 mt-2 hover:text-gray-700  font-bold text-lg"
            onClick={() => {
              history.goBack();
            }}
          >
            <Icon path={mdiChevronLeft} className="h-6 w-6 font-bold"></Icon>{" "}
            Back
          </button>
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2 mt-4">
            Invoice Payment Request Approval{" "}
            <span className="text-sm">#{data.id}</span>
          </div>
        </div>
        {data.id && (
          <button
            title="Logs"
            // to={`${window.document.location.pathname}/log`}
            onClick={showLog}
            className="mx-2 rounded-full p-2 hover:scale-105 transform duration-150    bg-myPrimaryColor text-white   shadow cursor-pointer flex items-center justify-center"
          >
            <Icon path={mdiHistory} className="h-6 w-6  "></Icon>
          </button>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
        {/* PR Details Section */}
        <div className="flex flex-col gap-4 ml-5 bg-white rounded-md p-5">
          <h3 className="text-xl text-black font-semibold">PR Details </h3>
          <div className="flex flex-col sm:flex-row gap-4 justify-between">
            {" "}
            {/* Adjusted flex layout */}
            <div className="flex flex-col w-full sm:w-1/2">
              {" "}
              {/* Adjusted width for better responsiveness */}
              <label htmlFor="invoice_no">Invoice No.</label>
              <input
                type="string"
                disabled
                className="bg-white"
                id="invoice_no"
                name="invoice_no"
                value={data.invoice_no}
              />
            </div>
            <div className="flex flex-col w-full sm:w-1/2">
              <label htmlFor="payment_date">PO No.</label>
              <input
                className="text-blue-500 bg-white"
                type="string"
                id="po_no"
                name="po_no"
                disabled
                value={`#${data.po_no}`}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-between">
            <div className="flex flex-col w-full sm:w-1/2">
              <label htmlFor="pay_amount">Payment Amount</label>
              <input
                type="text"
                id="pay_amount"
                name="pay_amount"
                disabled
                className="bg-white text-green-500"
                value={data.payment_amount}
              />
            </div>
            <div className="flex flex-col w-full sm:w-1/2">
              <label htmlFor="invoice_amount">Invoice Amount</label>
              <input
                type="text"
                id="invoice_amount"
                name="invoice_amount"
                disabled
                className="bg-white text-green-500"
                value={data.invoice_amount}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-between">
            <div className="flex flex-col w-full sm:w-1/2">
              <label htmlFor="inv_date">Invoice date</label>
              <input
                type="date"
                id="inv_date"
                name="inv_date"
                className="bg-white"
                disabled
                readOnly
                value={moment(data?.invoice_date).format("YYYY-MM-DD") || "--"}
              />
            </div>
            <div className="flex flex-col w-full sm:w-1/2">
              <label htmlFor="category">Category of Expense</label>
              <input
                type="text"
                id="category"
                name="category"
                className="bg-white"
                disabled
                value={data.category_of_expence}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-between">
            <div className="flex flex-col w-full sm:w-1/2">
              <label htmlFor="created_on">Created On</label>
              <input
                type="date"
                id="created_date"
                name="created_date"
                className="bg-white"
                disabled
                readOnly
                value={moment(data?.created_at).format("YYYY-MM-DD") || "--"}
              />
            </div>

            <div className="flex flex-col w-full sm:w-1/2">
              <label htmlFor="installations">No. of Installations</label>
              <input
                type="text"
                id="installations"
                name="installations"
                className="bg-white"
                disabled
                readOnly
                value={data?.no_of_intallations}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row gap-4 justify-between">
            <div className="flex flex-col">
              <label htmlFor="requested_by">Requested By</label>
              <input
                type="text"
                id="requested_by"
                name="requested_by"
                className="bg-white"
                disabled
                value={data.created_by_name}
              />
            </div>
            <div className="flex flex-col w-full sm:w-1/2">
              <label htmlFor="location">Customer Location</label>
              <input
                type="text"
                id="location"
                name="location"
                className="bg-white"
                disabled
                value={data.customer_location}
              />
            </div>
          </div>
          <hr />
          <div className="flex flex-col">
            <label htmlFor="notes">Notes</label>
            <textarea
              id="notes"
              name="notes"
              className="bg-white border-2"
              value={data?.notes}
            />
          </div>
        </div>

        {/* Attachments Section */}
        <div className="flex flex-col gap-4 mr-5 bg-white rounded-md p-5">
          <h3 className="text-xl text-black font-semibold">
            Attachments({data?.attachments?.length})
          </h3>
          <div className="flex flex-col gap-4">
            {/* Scrollable container for the attachment viewer */}
            <div className="max-h-96 overflow-y-auto border-2 rounded-md">
              <AttachmentViewer attachments={data?.attachments} />
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4">
        <div className="flex flex-col gap-4 my-5 ml-5 bg-white rounded-md p-5">
          <div className="col-span-2 flex flex-col gap-2">
            {/* <div className="">
              <label htmlFor="" className="text-sm font-semibold  ">
                Project
              </label>{" "}
              <ProjectSearchField
                disabled
                placeholder="Select Project"
                value={project}
                onSelect={(d) => {
                  setProject(d);
                }}
              />
            </div> */}

            <div className="mt-2">
              <div className="grid grid-cols-4">
                <h5>Project name</h5>
                <p className="col-span-3">:{project?.name}</p>
              </div>
              <div className="grid grid-cols-4">
                <h5>Description</h5>
                <p className="col-span-3">:{project?.description}</p>
              </div>
              <hr />
              <div className="grid grid-cols-4">
                <h5>Amount :</h5>
                <p className="col-span-3  text-right">
                  {currencyFormat(project?.amount || 0)}
                </p>
              </div>
              <div className="grid grid-cols-4">
                <h5>Spent :</h5>
                <p className="col-span-3  text-right">
                  {currencyFormat(project?.spent || 0)}
                </p>
              </div>
              <hr />
              <div className="grid grid-cols-4">
                <h5>Balance : </h5>
                <p className="col-span-3 text-right">
                  {currencyFormat(
                    (project?.amount || 0) - (project?.spent || 0)
                  )}
                </p>
              </div>

              <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
                <Icon path={mdiInformationOutline} size={0.8}></Icon>Note : this
                balance is real-time ,Eg: if you create a po on x date and
                balance is y then if you open the same po on z date balance may
                be v.
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-col lg:flex-row justify-between lg:justify-between gap-4 my-5 mr-5 bg-white rounded-md p-5">
          <div className="flex flex-col gap-4">
            <div className="mt-5">
              <h3 className="text-xl text-black font-semibold">
                Vendor Details{" "}
              </h3>
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
              {" "}
              {/* Changes here */}
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <label htmlFor="vendor">Vendor</label>
                  <input
                    type="string"
                    disabled
                    className="bg-white"
                    id="vendor"
                    name="vendor"
                    value={purchaseOrderData?.party?.name}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="type">Type</label>
                  <input
                    className="text-blue-500 bg-white"
                    type="string"
                    id="type"
                    name="type"
                    disabled
                    value={purchaseOrderData?.party?.type}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-4 mt-5">
            <div>
              <h3 className="text-xl text-black font-semibold">
                Shipping Profile{" "}
              </h3>
            </div>
            <div className="flex flex-col sm:flex-row gap-4">
              {" "}
              {/* Changes here */}
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <label htmlFor="address">Address</label>
                  <input
                    type="string"
                    disabled
                    className="bg-white"
                    id="address"
                    name="address"
                    value={`${purchaseOrderData?.warehouse?.address?.city}, ${purchaseOrderData?.warehouse?.address?.pincode}`}
                  />
                </div>
                <div className="flex flex-col">
                  <label htmlFor="contact">Contact</label>
                  <input
                    className="text-blue-500 bg-white"
                    type="string"
                    id="contact"
                    name="contact"
                    disabled
                    value={purchaseOrderData?.warehouse?.spoc?.contact_mobile}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gap-4 m-5">
        <h3 className="text-sm font-bold">Items</h3>
        <div
          className="bg-white rounded flex flex-col gap-1 border   "
          // style={{ height: "75vh" }}
        >
          <div className="grid grid-cols-9 gap-5 p-1 bg-myPrimaryColor text-white text-sm font-semibold rounded-t  ">
            <div className="col-span-2">Particular</div>
            <div className="text-center">HSN/SAC</div>
            <div className="text-center">qty</div>
            <div className="text-center">Price</div>
            <div className="text-right">Amount</div>
            <div className="text-center" title="Received qty">
              Received qty
            </div>
            <div className="text-center" title="Returned qty">
              Returned qty
            </div>
            <div className="text-right"> </div>
          </div>
          <div className="flex flex-col gap-1 px-1  text-sm overflow-auto h-72">
            {purchaseOrderData?.items.map((item: any, i) => {
              return (
                <PoItemRow
                  currency={
                    purchaseOrderData?.vendor_currency || "DEFAULT_CURRENCY"
                  }
                  disabled
                  key={item.id}
                  data={item}
                  onSubmit={() => {}}
                  // onDelete={(d) => {
                  //   setPurchaseOrderData((o) => ({
                  //     ...o,
                  //     items: [...(o.items || [])].filter(
                  //       (i) => i.id !== d.id
                  //     ),
                  //   }));
                  // }}
                  // onSubmit={function (data: PoItem): void {
                  //   setData((o) => ({
                  //     ...o,
                  //     items: [
                  //       ...(o.items || []).map((it) => {
                  //         if (it.id === data.id) return data;
                  //         return it;
                  //       }),
                  //     ],
                  //   }));
                  // }}
                  edit={false}
                  existingItems={existingItems}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="gap-4 m-5 bg-white p-5">
        <div className="flex justify-between font-bold ">
          <p>Total</p>{" "}
          {console.log(
            "short hand",
            purchaseOrderData?.vendor_currency?.short_name
          )}
          <div className="">
            {/* {currencyFormat(purchaseOrderData?.total ?? 0, purchaseOrderData?.vendor_currency?.short_name ?? '')} */}
            {currencyFormat(
              purchaseOrderData?.total ?? 0,
              purchaseOrderData?.vendor_currency?.short_name || "INR"
            )}
          </div>
        </div>
        <hr />
        <div className="flex justify-between font-bold ">
          <p>Paid</p>{" "}
          <div className="">
            {currencyFormat(
              purchaseOrderData?.paid ?? 0,
              purchaseOrderData?.vendor_currency?.short_name || "INR"
            )}
          </div>
        </div>
        <hr />
        <div className="flex justify-between font-bold ">
          <p>Balance</p>{" "}
          {console.log("purchaseOrderData?.paid ", purchaseOrderData?.paid)}
          <div className="">
            {currencyFormat(
              (purchaseOrderData?.total ?? 0) - (purchaseOrderData?.paid || 0),
              purchaseOrderData?.vendor_currency.short_name || "INR"
            )}
          </div>
        </div>
      </div>
      {isAuthorised({
        module: MODULE.payment_request_approval,
        action: [ACTION.ALL],
      }) && (
        <>
          {data.payment_status !== payment_status.paid &&
            data.payment_status !== payment_status.failed && (
              <div className="sticky bottom-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-10 mr-5 border flex justify-end">
                {/* Payment Status Buttons (Shown when pr_status is "approved") */}
                {data.pr_status === po_payments_req_status.approved && (
                  <div className="flex gap-4">
                    <button
                      onClick={() => {
                        setShowPaymentRequestApprovalModal(true);
                        setData({
                          ...data,
                          payment_status: payment_status.paid,
                        });
                      }}
                      className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                    >
                      Paid
                    </button>
                    <button
                      onClick={() => {
                        setShowInvoicePaymentRequestPaymentFailModal(true);
                        setData({
                          ...data,
                          payment_status: payment_status.failed,
                        });
                      }}
                      className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                    >
                      Payment Failed
                    </button>
                  </div>
                )}

                {/* PR Status Buttons (Hidden when pr_status is "approved") */}
                {data.pr_status !== po_payments_req_status.approved &&
                  data.pr_status !== po_payments_req_status.rejected && (
                    <div className="flex gap-4">
                      <button
                        onClick={() => {
                          setShowInvoicePaymentRequestApprovalModal(true);
                          setData({
                            ...data,
                            pr_status: po_payments_req_status.approved,
                          });
                        }}
                        className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => {
                          setShowInvoicePaymentRequestApprovalOnHoldModal(true);
                          setData({
                            ...data,
                            pr_status: po_payments_req_status.onHold,
                          });
                        }}
                        className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600"
                      >
                        On Hold
                      </button>
                      <button
                        onClick={() => {
                          setShowInvoicePaymentRequestApprovalRejectModal(true);
                          setData({
                            ...data,
                            pr_status: po_payments_req_status.rejected,
                          });
                        }}
                        className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600"
                      >
                        Reject
                      </button>
                    </div>
                  )}
              </div>
            )}
          {data.payment_status === payment_status.failed && (
            <div className="sticky bottom-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-10 mr-5 border flex justify-end">
              {/* Payment Status Buttons (Shown when pr_status is "approved") */}
              {data.pr_status === po_payments_req_status.approved && (
                <div className="flex gap-4">
                  <button
                    onClick={() => {
                      setShowPaymentRequestRepayModal(true);
                      setData({
                        ...data,
                        payment_status: payment_status.paid,
                      });
                    }}
                    className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600"
                  >
                    RePay
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
      {loading && <LoadingWidget />}
      {/* Assuming you have a LoadingWidget component */}
      {/* </form> */}
    </>
  );
};

export default PaymentRequestInvoiceApprovalScreen;
