import { mdiCheck, mdiClose, mdiDelete, mdiPencil, mdiPlus } from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import {
  ToastType,
  useToast,
} from "../../../../../context_providers/toast/toast_ctx";

import { ItemMovementModel } from "../../../../../erp/inventory/models/inventory_voucher";
import WarehouseSearchField from "../../../../../erp/inventory/warehouse/warehouse_search_field";
import {
  WAREHOUSE_TYPE,
  WarehouseModel,
} from "../../../../../erp/inventory/models/warehouse_model";
import ItemSearchField from "../../../../../erp/inventory/items/item_search_field";
const defaultItem = () => {
  const x: ItemMovementModel = {
    date: new Date(),
    item_id: "",
    bill_unit_no: 0,
    unit_no: 0,
    price: 0,
    amount: 0,
    movement_type: "",
    voucher_type: "",
    voucher_id: "",
    voucher_no: "",
    received_unit_no: 0,
    lost_unit_no: 0,
    status: "",
    projectFrom: [],
    projectTo: {
      name: "",
      description: "",
      amount: 0,
      spent: 0,
      batch_name: "",
      batch_size: 0,
      category: "",
    },
    warehouse_id: "",
    warehouse: {
      name: "",
      // group_id: "",
      type: "internal",
      spoc: {
        uid: "",
        contact_name: "",
        contact_email: "",
        contact_mobile: "",
      },
      address: {
        id: "",
        type: "",
        line1: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      },
      // tax_detail: {
      //   pan: "",
      //   reg_type: "Unkown",
      //   gst_no: "",
      // },
    },
  };
  return x;
};
interface ExtraRowWidgetProps {
  extra?: ItemMovementModel;
  onSubmit: (data: ItemMovementModel) => void;
  edit: boolean;
  disabled: boolean;
  onDelete?: (i: ItemMovementModel) => void;
  // form_id?: string;
}

const ExtraRowWidget: FunctionComponent<ExtraRowWidgetProps> = (props) => {
  const { showToast } = useToast();
  const [showMore] = useState(false);
  const { edit, onSubmit } = props;
  const [extra, setExtra] = useState<ItemMovementModel>({
    ...(props.extra ?? defaultItem()),
  });
  const [editable, setEditable] = useState(false);
  useEffect(() => {
    setEditable(edit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onDone = () => {
    if (!extra.item_id || extra.unit_no < 1) {
      showToast({
        type: ToastType.warning,
        text: "Please select warehouse, item and qty",
      });
      return;
    }
    if (!extra.price) {
      extra.price = 0;
    }
    onSubmit(extra);
  };
  return (
    <>
      <form
        // id={props.form_id}
        onSubmit={(e) => {
          e.preventDefault();
          onDone();
        }}
        className="bg-white rounded-md shadow   "
      >
        <div className="grid grid-cols-9 gap-3 p-5">
          <div className="col-span-2">
            <WarehouseSearchField
              // type={WAREHOUSE_TYPE.internal}
              placeholder="select Warehouse"
              disabled={!editable || props.disabled}
              value={extra.warehouse}
              onSelect={function (warehouse: WarehouseModel): void {
                setExtra((o) => ({
                  ...o,
                  warehouse_id: warehouse.id,
                  warehouse,
                }));
              }}
            />
          </div>
          <div className="col-span-2">
            <ItemSearchField
              placeholder="select Item"
              disabled={!editable || props.disabled}
              value={extra.item}
              onSelect={(item) => {
                setExtra((o) => ({
                  ...o,
                  item_id: item.id,
                  item,
                }));
              }}
            />
          </div>

          <input
            value={extra?.amount}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setExtra((ol) => ({ ...ol, amount: v }));
            }}
            disabled={!editable || props.disabled}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <input
            value={extra?.price}
            onChange={(e) => {
              let v = 0;
              if (e.target.value && !isNaN(e.target.value as any)) {
                v = Number(e.target.value);
              }
              setExtra((ol) => ({ ...ol, price: v }));
            }}
            disabled={!editable || props.disabled}
            placeholder=""
            type="number"
            className="text-right  p-1 rounded bg-gray-100 focus:outline-none"
          />
          <div className="grid grid-cols-4 items-center gap-1">
            <input
              value={extra?.unit_no}
              onChange={(e) => {
                let v = 0;
                if (e.target.value && !isNaN(e.target.value as any)) {
                  v = Number(e.target.value);
                }
                setExtra((ol) => ({ ...ol, unit_no: v }));
              }}
              disabled={!editable || props.disabled}
              placeholder=""
              type="number"
              className="text-right  p-1 rounded bg-gray-100 focus:outline-none col-span-3"
            />{" "}
            <p className="font-semibold">{extra.item?.unit.symbol ?? "--"}</p>
          </div>
          <div className="text-right  p-1 rounded bg-gray-100">
            {extra.unit_no * extra.price}
          </div>

          <div className="flex gap-2 items-center justify-end">
            {props.extra ? (
              !props.disabled && (
                <>
                  <div className="flex justify-center gap-2">
                    {editable ? (
                      <>
                        <div
                          onClick={() => {
                            if (!window.confirm("Are you sure !")) return;
                            onDone();
                            setEditable(false);
                          }}
                          className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiCheck} className="h-4 w-4"></Icon>
                        </div>
                        <div
                          onClick={() => {
                            setExtra(props.extra!);
                            setEditable(false);
                          }}
                          className="bg-red-100 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                        >
                          <Icon path={mdiClose} className="h-4 w-4"></Icon>
                        </div>
                      </>
                    ) : (
                      <div
                        onClick={() => setEditable(true)}
                        className="bg-green-100 p-1 rounded text-green-800 hover:scale-110 transform duration-200"
                      >
                        <Icon path={mdiPencil} className="h-4 w-4"></Icon>
                      </div>
                    )}
                  </div>
                  <div
                    onClick={() => {
                      if (!window.confirm("Are you sure !")) return;
                      if (props.onDelete) props.onDelete(extra);
                    }}
                    className="bg-red-200 p-1 rounded text-red-800 hover:scale-110 transform duration-200"
                  >
                    <Icon path={mdiDelete} className="h-4 w-4"></Icon>
                  </div>
                </>
              )
            ) : (
              <button
                // form="item_form"
                className="flex gap-1 text-white text-sm bg-myPrimaryColor rounded-full px-2 py-1 hover:scale-110 transform duration-300"
              >
                <Icon path={mdiPlus} className="h-5 w-5 "></Icon> Add
              </button>
            )}
            {/* {props.extra && (
              <div
                onClick={() => setShowMore((o) => !o)}
                className="p-1 bg-gray-100 rounded cursor-pointer hover:bg-gray-200"
              >
                <Icon
                  path={showMore ? mdiChevronUp : mdiChevronDown}
                  className="h-4 w-4"
                ></Icon>
              </div>
            )} */}
          </div>
        </div>{" "}
        {showMore && props.extra && (
          <div className="px-2 py-1">
            <hr className=" " />
            <div className="text-xs text-gray-400">
              <p>Id : {props.extra.id}</p>
            </div>
            <div className="flex   justify-between gap-2 my-1">
              <p className="text-xs ">
                Created by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.extra.created_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.extra.created_at
                      ? moment(props.extra.created_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
              <p className="text-xs ">
                Updated by :<br />
                <div className="flex gap-1">
                  <div className="">
                    <b>{props.extra.updated_by_name ?? "N/A"}</b>
                  </div>
                  <div className="">
                    {props.extra.updated_at
                      ? moment(props.extra.updated_at).format(
                          "DD MMM YYYY h:mm a"
                        )
                      : "--"}
                  </div>
                </div>
              </p>
            </div>
          </div>
        )}
      </form>

      {(extra as any).name && (
        <p>
          You can update from above controls <br />
          Old name = {(extra as any).name} | price = {(extra as any).amount}{" "}
        </p>
      )}
    </>
  );
};

export default ExtraRowWidget;
