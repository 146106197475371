import { mdiDelete } from "@mdi/js";
import Icon from "@mdi/react";
import {
  AttachmentModel,
  getFileType,
} from "../../../purchase_order/model/purchase_order_model";
import FileDropZone from "../../../../ui/new_components/common/file_drop_zone";
import { FunctionComponent, useEffect, useState } from "react";
import { FileType } from "../../../../utils/enums";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";

interface GstAttachmentSectionProps {
  attachments: AttachmentModel[];
  category: string;
  onSubmit: (data: {
    file: File;
    category: string;
  }) => Promise<AttachmentModel>;
  onDelete: (data: AttachmentModel) => void;
}

const GstAttachmentSection: FunctionComponent<GstAttachmentSectionProps> = (
  props
) => {
  const [files, setFiles] = useState<AttachmentModel[]>(props.attachments);
  const [uploading, setUploading] = useState(false);
  const { showToast } = useToast();
  useEffect(() => {
    console.log("Attachments", props.attachments);
    setFiles(props.attachments);
  }, [props.attachments]);

  const handleFileDrop = async (droppedFiles: File[]) => {
    setUploading(true);
    for (const file of droppedFiles) {
      try {
        const uploadedFile = await props.onSubmit({
          file,
          category: props.category,
        });
      } catch (error: any) {
        showToast({
          type: ToastType.error,
          text: error.message,
        });
      }
    }
    setUploading(false);
  };

  const removeFile = (file: AttachmentModel) => {
    props.onDelete(file);
    setFiles((prevFiles) => prevFiles.filter((f) => f.id !== file.id));
  };

  return (
    <div className="p-5">
      <div className="mb-4 relative">
        {uploading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
            Uploading...
          </div>
        )}
        <FileDropZone onDrop={handleFileDrop} />
      </div>

      <div className="space-y-4 max-h-96 overflow-y-auto">
        {files.map((fileObj) => (
          <div
            key={fileObj.id}
            className="flex items-center justify-between p-3 border rounded-md"
          >
            <div className="flex items-center gap-3">
              {getFileType(fileObj.type) === FileType.IMAGE ? (
                <img
                  src={fileObj.url}
                  alt={""}
                  className="w-16 h-16 object-cover"
                />
              ) : (
                <a
                  href={fileObj.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-sm"
                >
                  {fileObj.url}
                </a>
              )}
            </div>
            <button
              onClick={() => removeFile(fileObj)}
              className="p-2 bg-red-500 text-white rounded-full"
            >
              <Icon path={mdiDelete} size={0.8} />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GstAttachmentSection;
