import { mdiRefresh, mdiPlus, mdiNoteEdit, mdiArrowTopRight } from "@mdi/js";
import Icon from "@mdi/react";
// import { title } from "process";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import {
  fetchDelete,
  fetchGet,
  toQueryString,
} from "../../../service/api_client";
import SearchFieldWidget from "../../../ui/new_components/search_field";
import { urls } from "../../../utils/urls";
import {
  SpocModel,
  WAREHOUSE_TYPE,
  WarehouseModel,
} from "../models/warehouse_model";
import {
  GetWareHouseApi,
  GetWareHouseListApi,
  UpdateWareHouseApi,
} from "./components/warehouse_repo";
import Pagination from "../../../ui/new_components/common/pagination";
import { debounce } from "lodash";
import ModalDialog from "../../../ui/new_components/common/modal";
import WarehouseExport from "./components/WarehouseExport";
import moment from "moment";

interface InventoryBasketListScreenProps {}

export interface WarehouseFilterData {
  name?: string;
  type?: any;
  description?: string;
  city?: string;
  state?: string;
  spoc?: SpocModel;
  page: number;
  count: number;
  search?: string;
  all?: any;
}

const defaultFilter: WarehouseFilterData = {
  page: 1,
  count: 100,
};

const InventoryBasketListScreen: FunctionComponent<
  InventoryBasketListScreenProps
> = () => {
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/inventory/warehouse_type`;
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<WarehouseFilterData>(defaultFilter);
  const [selectedType, setSelectedType] = useState<string>("");
  const [updateDisabled, setUpdateDisabled] = useState(true);
  const [selected, setSelected] = useState<WarehouseModel[]>([]);
  const [showExport, setShowExport] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(50);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<WarehouseModel[]>([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const disable = selected.length === 0;

    setUpdateDisabled(disable);
  }, [selected]);

  const fetchData = async (prop: WarehouseFilterData) => {
    setLoading(true);
    try {
      const res = await fetchGet(url + toQueryString(prop));
      if (res.success) {
        setData(res.data.data);
        console.log("result:", res.data.data);
        if (res.data.metadata) {
          setTotal(res.data.metadata.total);
        }
      } else {
        showToast({ type: ToastType.error, text: res.error });
      }
    } catch (error: any) {
      showToast({ type: ToastType.error, text: error.message });
      //   return { success: false, error: error.message };
    }
    setLoading(false);
  };

  const debouncedHandler = useCallback(
    debounce((prop: WarehouseFilterData) => {
      if (prop) fetchData(prop);
    }, 500),
    []
  );

  useEffect(() => {
    filter.type = selectedType;
    debouncedHandler(filter);
  }, [page, filter, selectedType, debouncedHandler]);

  useEffect(() => {
    if (search) fetchData({ page, count, search });
  }, [search]);
  // return <LedgerCreateScreen />;
  const navaigation = useHistory();

  const handleOnEdit = () => {
    if (selected.length > 1) {
      showToast({
        type: ToastType.error,
        text: "Please select one item to edit",
      });
    } else if (selected.length === 0) {
      showToast({
        type: ToastType.error,
        text: "Please select an item to edit",
      });
    } else {
      navaigation.push(
        urls.procurement_masters_warehouse + "/update/" + selected[0]!.id
      );
    }
  };

  return (
    <>
      {showExport && (
        <ModalDialog
          show={showExport}
          title="Export"
          onClose={() => setShowExport(false)}
        >
          <WarehouseExport filter={filter} />
        </ModalDialog>
      )}
      <div
        id="page_container"
        className="flex flex-col justify-between h-full p-1"
      >
        <section
          id="top_section"
          className="flex justify-between items-end my-1 "
        >
          <h1 className="font-bold text-2xl text-gray-500 border-l-4 border-myPrimaryColor pl-2   flex items-center gap-3">
            Warehouse Master
            <button
              title="Refersh"
              onClick={() => fetchData({ page, count, search })}
            >
              <Icon path={mdiRefresh} className="h-6 w-6"></Icon>
            </button>
          </h1>
          <div className="flex justify-end   text-sm items-center gap-1 ">
            {/* <SearchFieldWidget
              defaulValue={search}
              onClear={() => {
                setSearch("");
                fetchData({ page: 1, count });
              }}
              onSubmit={(val) => {
                fetchData({ page: 1, count, search: val });
                setPage(1);
                setSearch("");

                // setTimeout(() => {
                //   setSearch(val);
                // }, 10);
              }}
            /> */}
            <SearchFieldWidget
              defaulValue={filter.search}
              onClear={() => {
                setFilter((o) => ({ ...o, search: "", page: 1 }));
              }}
              onSubmit={(val) => {
                setFilter((o) => ({
                  ...o,
                  search: val,
                  page: 1,
                }));
              }}
            />
            <div
              onClick={() => {
                navaigation.push(
                  urls.procurement_masters_warehouse + "/create"
                );
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Create
            </div>
            {/* <div
              onClick={() => {
                navaigation.push(
                  urls.procurement_masters_warehouse + "/create"
                );
              }}
              className="flex gap-1 items-center cursor-pointer rounded p-1 bg-myPrimaryColor hover:scale-105 duration-200  text-white  transform"
            >
              <Icon path={mdiPlus} className="h-4 w-4"></Icon>Export
            </div> */}
            <button
              onClick={() => {
                setShowExport(true);
              }}
              className="rounded-lg border px-2 py-1 hover:bg-gray-800 hover:text-white flex gap-2 items-center"
            >
              <Icon path={mdiArrowTopRight} className="h-4 w-4"></Icon>
              Export
            </button>
            <button
              disabled={updateDisabled}
              onClick={() => {
                handleOnEdit();
              }}
              className={`${
                updateDisabled
                  ? "cursor-not-allowed  text-gray-300"
                  : "hover:bg-black hover:text-white"
              } flex gap-2 items-center border rounded-md px-3 py-1`}
            >
              <Icon path={mdiNoteEdit} className="h-4 w-4 "></Icon>Update
            </button>
          </div>
        </section>
        <section className="flex items-start justify-start m-4">
          <div className="grid grid-cols-4 gap-6 w-1/2">
            <div className="w-full rounded focus:outline-none">
              <label htmlFor="app_status" className="">
                Warehouse Name
              </label>
              {/* <WarehouseSearchField
                placeholder="select Warehouse"
                // disabled={disabled}

                value={filter?.name}
                onSelect={function (warehouse: WarehouseModel): void {
                  setFilter((o) => ({
                    ...o,
                    warehouse_id: warehouse.id,
                    warehouse_name: warehouse.name,
                  }));
                }}
              /> */}
              <input
                type="text"
                id="warehouse"
                placeholder="warehouse"
                className="w-full p-1 bg-white rounded focus:outline-none"
                value={filter?.name || ""}
                onChange={(e) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="">
              <label htmlFor="item_type" className="">
                Warehouse Type
              </label>
              <select
                className="w-full p-1 bg-white rounded focus:outline-none"
                name="item_type"
                id="item_type"
                value={selectedType ?? ""}
                onChange={(e) => {
                  setFilter((prevData) => ({
                    ...prevData,
                    type: e.target.value, // Directly updating type
                  }));
                  setSelectedType(e.target.value);
                  setSelected([]);
                }}
              >
                <option value="">select</option>
                {Object.values(WAREHOUSE_TYPE).map((f: string) => (
                  <option key={f} value={f}>
                    {f}
                  </option>
                ))}
              </select>
            </div>
            <div className="">
              <label htmlFor="city" className="">
                City
              </label>
              <input
                type="text"
                id="city"
                placeholder="city"
                className="w-full p-1 bg-white rounded focus:outline-none"
                value={filter?.city || ""}
                onChange={(e) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    city: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="">
              <label htmlFor="state" className="">
                State
              </label>
              <input
                type="text"
                id="state"
                placeholder="state"
                className="w-full p-1 bg-white rounded focus:outline-none"
                value={filter?.state || ""}
                onChange={(e) => {
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    state: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </section>

        <div className="my-2 border overflow-auto" style={{ height: "80%" }}>
          <table className="w-full     text-xs divide-y divide-gray-200 relative ">
            <thead className="  text-xs   py-2 sticky -top-1 bg-white z-10">
              <tr className="border ">
                <th>
                  <div className="Select">
                    <input
                      type="checkbox"
                      name=""
                      checked={selected.length === data.length ? true : false}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelected([]);
                        } else {
                          setSelected([]);
                        }
                      }}
                    />
                  </div>
                </th>
                {/* <th className="px-2 py-2"></th> */}
                <th className="px-2 py-2">Warehouse Id</th>
                <th className="px-2 py-2">Created At</th>
                <th className="px-2 py-2">Warehouse name</th>
                <th className="px-2 py-2">Warehouse type</th>
                <th>Address Line1</th>
                <th>Address Line2</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>SPOC</th>
              </tr>
            </thead>
            <tbody className="">
              {data.length > 0 ? (
                data.map((d, i) => {
                  return (
                    <tr className="border text-center " key={i}>
                      <td>
                        <div className="">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            checked={
                              selected.find((v) => v.id === d.id) !== undefined
                            }
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelected((old) => [...old, d]);
                              } else {
                                setSelected((old) =>
                                  old.filter((w) => w.id !== d.id)
                                );
                              }
                            }}
                          />
                        </div>
                      </td>
                      {/* <td>
                        <div className="flex gap-2 justify-center">
                          {" "}
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowComment(d.id)}
                          >
                            <Icon path={mdiCommentBookmark} size="16"></Icon>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => setShowHistory(d.id)}
                          >
                            <Icon path={mdiHistory} size="16"></Icon>
                          </div>
                        </div>
                      </td> */}
                      {/* <td className="px-3 py-2">
                        {moment(d.created_at).format("DD MMM YYYY")}
                      </td> */}
                      <td className="px-3 py-2 text-blue-500">
                        <a
                          href={
                            urls.procurement_masters_warehouse + "/view/" + d.id
                          }
                        >
                          {d.id}
                        </a>
                      </td>
                      <td>{moment(d?.created_at).format("YYYY-MM-DD")}</td>
                      <td className="px-3 py-2">{d?.name ?? "--"}</td>
                      <td className="px-3 py-2">{d.type}</td>
                      <td className="px-3 py-2">{d.address?.line1 || "---"}</td>
                      <td className="px-3 py-2">{d.address?.line2 || "---"}</td>
                      <td className="px-3 py-2">{d.address?.city || "---"}</td>
                      <td className="px-3 py-2">{d.address?.state || "---"}</td>
                      <td className="px-3 py-2">
                        {d.address?.country || "---"}
                      </td>
                      <td className="px-3 py-2">
                        {d.spoc?.contact_name || "---"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={13}>
                    <div className="h-56 text-center p-5 my-20">
                      <h5 className="text-gray-400 text-2xl">No Data found!</h5>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="mt-3">
          <Pagination
            itemCount={data.length}
            page={filter.page}
            total={total}
            count={filter.count}
            onChange={(page) => setFilter((o) => ({ ...o, page }))}
          />
        </div>
      </div>

      {loading && <LoadingWidget></LoadingWidget>}
    </>
  );
};

export default InventoryBasketListScreen;
